import { UserInfoModel } from '@app/@models/userInfo.model';
import { AlertController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { UserInfoService } from '@app/@services/user-info.service';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CredentialsService } from './credentials.service';
import { map } from 'rxjs/operators';

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private loggedUserSubject: BehaviorSubject<UserInfoModel> = new BehaviorSubject<UserInfoModel>(null);
  public loggedUser$: Observable<UserInfoModel> = this.loggedUserSubject.asObservable();

  private userLoggedIn = new BehaviorSubject<boolean>(this.credentialsService.isAuthenticated());
  userLoggedIn$: Subscription;

  constructor(
    private credentialsService: CredentialsService,
    private http: HttpClient,
    private userInfoService: UserInfoService,
    private router: Router,
    private alertController: AlertController,
    private modalController: ModalController
  ) {}

  setUserLoggedIn(logged: boolean) {
    this.userLoggedIn.next(logged);
  }

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @token string The login token.
   * @return The user credentials.
   */
  login(context: LoginContext, uuId: string, challengeToken: string, ipAddress: string, deviceInfo: any) {
    // let serverAddress = 'https://api.realtime.services/checkPortalLoginAndGetToken';

    // if (location.origin.indexOf('localhost')) {
    //   // serverAddress = 'http://localhost:4501/checkPortalLoginAndGetToken';
    // }
    // const headers = new HttpHeaders().set('content-type', 'application/json').set('x-api-key', environment.apikey);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + btoa(context.username.trim() + ':' + context.password.trim()),
    });
    return this.http.post<any>(
      // environment.serverUrl + 'auth/login',  // added this for better login
      environment.serverUrl + 'auth/login',
      {
        // loginname: context.username,
        // password: context.password,
        uuId,
        challengeToken,

        deviceInfo,
        xResolution: window.innerWidth,
        yResolution: window.innerHeight,
        ipAddress,
      },
      { headers }
    );
  }

  clearSession() {
    this.credentialsService.clear();
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  async logout(autoLogout: boolean = false): Promise<void> {
    if (this.credentialsService.isAuthenticated()) {
      this.userInfoService.logLogout();
      this.credentialsService.clear();
      this.userLoggedIn.next(false);
      this.userInfoService.clearUserInfo();
      this.clearSession();
      this.loggedUserSubject.next(null);
      this.userInfoService.setSuperVisor(null);
      this.stopRefreshTokenTimer();

      // if the user logged themselves out set that the user logged themselves out flag
      if (autoLogout) {
        localStorage.removeItem('userLoggedOut');
      } else {
        localStorage.setItem('userLoggedOut', JSON.stringify(true));
      }
    }
    if (!this.router.url.includes('login')) {
      this.router.navigate(['/login'], { replaceUrl: true });
    }
  }

  async alertSessionExpired() {
    if (this.credentialsService.isAuthenticated()) {
      const alert = await this.alertController.create({
        header: 'You Have Been Logged Out',
        message: 'You have been logged out due to an expired session.',
        buttons: [
          {
            text: 'OK',
            cssClass: 'primary',
          },
        ],
      });

      await alert.present();
    }
  }

  // isLogedIn() {
  //   return this.credentialsService.isAuthenticated();
  // }

  getUserLoggedIn(): Observable<boolean> {
    // console.log('subscribed to getuserLoggedIn', this.userLoggedIn.asObservable());
    return this.userLoggedIn.asObservable();
  }

  refreshToken() {
    return;
    // console.log(this.isLogedIn());
    // try {
    //   return this.http
    //     .post<any>(environment.serverUrl + 'users/refreshToken', { username: this.userInfoService.userInfo.username })
    //     .pipe(
    //       map((res) => {
    //         this.userInfoService.setUserToken( res.token);
    //         localStorage.setItem('id_token', res.token);
    //       })
    //     );
    // } catch (err) {
    //   console.log(err);
    // }
  }

  // helper methods

  private refreshTokenTimeout: any;

  startRefreshTokenTimer() {
    // set a timeout to refresh the token a minute before it expires
    // const expires = new Date(exp * 1000);
    // const timeout = expires.getTime() - Date.now() - 60 * 1000;
    // console.log(this.refreshTokenTimeout);
    // this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), 19 * 60000);
  }

  stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout); // will do nothing if no timeout with id is present
  }
}
