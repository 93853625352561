import { IonicModule } from '@ionic/angular';
import { Component, EventEmitter, OnInit, Output, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '@app/@services/helper.service';
import { UserInfoService } from './../../@services/user-info.service';
import { CredentialsService } from '@app/auth';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.scss'],
  standalone: true,
  imports: [IonicModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PrivacypolicyComponent implements OnInit {
  @Output() changeView = new EventEmitter<string>();
  authenticated: boolean;
  constructor(
    private router: Router,
    private credentialsService: CredentialsService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    this.authenticated = this.credentialsService.isAuthenticated();
    this.helperService.setPageTitle('Privacy policy');
    this.helperService.setPageDescription('');
  }

  goBack() {
    if (this.authenticated) {
      this.changeView.emit('menu');
    } else {
      this.router.navigate(['/login']);
    }
  }
}
