import { DeliveryOptionComponent } from './../delivery-option/delivery-option.component';
import { ModalController } from '@ionic/angular';
import { EmailService } from '@app/@services/email.service';
import { UserInfoService } from '@app/@services/user-info.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  @Output() activePage = new EventEmitter<string>();
  @Output() setId = new EventEmitter<any>();
  myForm: UntypedFormGroup;
  eeSysId: number;
  loginname: string;

  findUserError = false;

  errorMessages = {
    email: [{ type: 'required', message: 'Email is required' }],
    ssn: [
      { type: 'required', message: 'Last 4 of social is required' },
      { type: 'minlength', message: 'Minimum of 4 digits is required' },
      { type: 'maxlength', message: 'Maximum of 4 digits is required' },
    ],
  };

  constructor(
    private fb: UntypedFormBuilder,
    private userInfoService: UserInfoService,
    private emailService: EmailService,
    private modalController: ModalController
  ) {
    this.myForm = fb.group({
      email: this.fb.control('', [Validators.required, Validators.minLength(4)]),
      ssn: this.fb.control('', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]),
    });
  }

  get email() {
    return this.myForm.get('email');
  }

  get ssn() {
    return this.myForm.get('ssn');
  }

  ngOnInit(): void {}

  async deliveryMethod(info: any) {
    const modal = await this.modalController.create({
      component: DeliveryOptionComponent,
      componentProps: {
        info,
      },
    });

    modal.onDidDismiss().then((res) => {
      if (res.data.eeSysId > 0) {
        info.forEach((obj: any) => {
          if (obj.eeSysId === res.data.eeSysId) {
            this.sendCode(obj, res.data.deliveryMethod);
          }
        });
      }
    });
    await modal.present();
  }

  async sendCode(info: any, deliveryMethod: string) {
    const code = await this.userInfoService.generateCode(info.eeSysId, info.loginname);
    if (deliveryMethod === 'e') {
      this.emailService.forgotPasswordEmail(info.email, '', code);
    } else {
      this.emailService.forgotPasswordText(('' + info.phone).replace(/\D/g, ''), code);
    }
    this.activePage.emit('code');
    this.setId.emit({ eeSysId: info.eeSysId, loginname: info.loginname });
  }

  onSubmit() {
    // console.log(this.myForm.valid);
    if (this.myForm.valid) {
      this.userInfoService.getWebLoginInfo(this.email.value, this.ssn.value, this.loginname).subscribe((data) => {
        // console.log({ 'get login info forgot password': data });
        if (!data || !data.length) {
          this.findUserError = true;
        } else {
          this.deliveryMethod(data);
          // this.emailService.forgotPasswordEmail(data[0].email, 'Kris', Math.floor(100000 + Math.random() * 900000));
          // this.activePage.emit('code');
        }
      });
    }
  }

  goBack() {
    this.activePage.emit('login');
  }
}
