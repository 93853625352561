<ion-header>
  <ion-toolbar color="primary">
    <ion-item color="primary">
      <ion-icon slot="end" name="close-circle-outline" (click)="dismissModal()"></ion-icon>
      <ion-label>Email {{ contactName }}</ion-label>
    </ion-item>
  </ion-toolbar>
</ion-header>
<ion-content>
  <form [formGroup]="myForm">
    <ion-grid>
      <ion-row color="primary" justify-content-center>
        <ion-col align-self-center>
          <div padding>
            <p>A copy of this email will be sent to {{ from }}</p>
            <ion-item>
              <ion-select
                formControlName="subjectOptions"
                (ionChange)="onOptionChange()"
                label="Subject:"
                label-placement="floating"
              >
                <ion-select-option *ngFor="let subject of subjectOptions" [value]="subject">
                  {{ subject }}
                </ion-select-option>
              </ion-select>
            </ion-item>

            <ion-item *ngIf="otherSubjecRequired">
              <ion-input
                formControlName="otherSubject"
                label="Enter your own subject:"
                label-placement="floating"
                maxlength="80"
              ></ion-input>
            </ion-item>
            <div class="message-error-count-container" *ngIf="otherSubjecRequired">
              <app-form-errors [errors]="errorMessages.otherSubject" [item]="otherSubject"></app-form-errors>
              <ion-text slot="end" class="character-count">
                <small>{{ otherSubject.value ? otherSubject.value.length : 0 }} out of 80</small>
              </ion-text>
            </div>

            <ion-item lines="none">
              <ion-select
                formControlName="emailTo"
                (ionChange)="updateContact()"
                multiple
                label="To:"
                label-placement="floating"
              >
                <ion-select-option *ngFor="let t of to" value="{{ t.value }}">
                  {{ t.value }} ({{ t.display }})
                </ion-select-option>
              </ion-select>
            </ion-item>
            <app-form-errors [errors]="errorMessages.emailTo" [item]="emailTo"></app-form-errors>
            <ion-item lines="none">
              <ion-textarea
                class="text-area"
                formControlName="message"
                label="Message:"
                label-placement="floating"
                maxlength="560"
              ></ion-textarea>
            </ion-item>

            <div class="message-error-count-container">
              <app-form-errors [errors]="errorMessages.message" [item]="message" lines="none"></app-form-errors>
              <ion-text slot="end" class="character-count">
                <small>{{ message.value ? message.value.length : 0 }} out of 560</small>
              </ion-text>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>
<ion-footer>
  <ion-button expand="full" (click)="onSubmit()" [disabled]="myForm.invalid">
    Send<span *ngIf="isSubmitting">ing</span> to {{ contactName }}
    <ion-spinner slot="start" *ngIf="isSubmitting"></ion-spinner>
    <ion-icon slot="start" *ngIf="!isSubmitting" name="mail"></ion-icon>
    <ion-ripple-effect></ion-ripple-effect>
  </ion-button>
</ion-footer>
