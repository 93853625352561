import { TimeoffService } from './../@services/timeoff.service';
import { EmployeesComponent } from '@components/employees/employees.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AlertController, Platform, ModalController } from '@ionic/angular';
import { AuthenticationService, CredentialsService } from '@app/auth';
import { UserInfoService } from './../@services/user-info.service';

import { UserInfoModel } from '@app/@models/userInfo.model';
import { HelperService } from '@app/@services/helper.service';
import { PtoApprovalComponent } from '@app/@components/pto-approval/pto-approval.component';

import { Device } from '@capacitor/device';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { WebSupervisorModel } from '@app/@models/webSupervisor';
import { ContactUsComponent } from '@app/@components/contact-us/contact-us.component';
import { ChangePasswordComponent } from '@app/profile/change-password/change-password.component';
import { VersionCheckService } from '@app/@services/version-check.service';
import { App } from '@capacitor/app';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit, OnDestroy {
  today = new Date(); // Get the current date. RSL-45
  currentMonth = this.today.getMonth(); // Get the current month (zero-indexed, so December is 11). RSL-45
  
  activeIndex = 0;
  activePageTitle = 'Feed';
  activePage: any;
  page = '';
  userInfo$: UserInfoModel = {} as UserInfoModel;
  supervisor: WebSupervisorModel = {} as WebSupervisorModel;
  userAccounts: UserInfoModel[];
  showNumbers: boolean;
  showMenu = {
    home: true,
    supervisor: false,
    profile: true,
    payroll: true,
    w4: true,
    taxStatements: true,
    expenses: true,
    timeoff: true,
    management: false,
    timeoffapprove: false,
    employees: false,
    timekeeping: true,
    calendar: true,
    admin: false,
    about: false,
    contact: true,
    shiftsInProgress: false,
  };
  showPayroll = false;
  numberOfPtoRequest = 0;
  updateAvailable = false;

  homeSection = [
    {
      title: 'Dashboard',
      url: '/dashboard',
      icon: 'home-outline',
      display: this.showMenu.home,
    },
    {
      title: 'Timekeeping Approvals',
      url: '/timekeeping-approvals',
      icon: 'home-outline',
      display: this.showMenu.supervisor,
    },
  ];

  payrollSection = [
    {
      title: 'Payroll',
      url: '/payroll',
      icon: 'cash-outline',
      display: this.showMenu.payroll,
    },
    {
      title: 'Tax Statements',
      url: '/tax-statements',
      icon: 'wallet-outline',
      display: this.showMenu.taxStatements,
    },
  ];

  expensesSection = [
    {
      title: 'Expenses',
      url: '/expenses',
      icon: 'cash-outline',
      display: this.showMenu.expenses,
    },
  ];

  timeSection = [
    {
      title: 'Time Off',
      url: '/timeoff',
      icon: 'airplane-outline',
      display: this.showMenu.timeoff,
    },
    {
      title: 'Timekeeping',
      url: '/timekeeping',
      icon: 'time-outline',
      display: this.showMenu.timekeeping,
    },
    {
      title: 'Calendar',
      url: '/calendar',
      icon: 'calendar-outline',
      display: this.showMenu.calendar,
    },
  ];

  manageEmployeesSection = [
    {
      title: 'Manage Employees',
      numberOfPtoRequest: this.numberOfPtoRequest,
      url: '/management',
      icon: 'people-outline',
      display: this.showMenu.management && this.showMenu.timeoff,
    },
  ];

  adminSection = [
    {
      title: 'Admin',
      url: '/admin',
      icon: 'shield-half-outline',
      display: this.showMenu.admin,
    },
  ];

  profileSection = [
    {
      title: 'Profile',
      url: '/profile',
      icon: 'person-outline',
      display: this.showMenu.profile,
    },
    {
      title: 'W-4',
      url: '/w4',
      icon: 'calculator',
      display: this.showMenu.w4,
    },
  ];

  shiftsInProgress = [
    {
      title: 'Shifts in Progress',
      url: '/shifts',
      icon: 'person-outline',
      display: this.showMenu.shiftsInProgress,
    },
  ];

  dashboardPage = this.homeSection.findIndex((e: any) => e.title === 'Dashboard');
  timekeepingApprovalsPage = this.homeSection.findIndex((e: any) => e.title === 'Timekeeping Approvals');
  payrollPage = this.payrollSection.findIndex((e: any) => e.title === 'Payroll');
  taxStatementsPage = this.payrollSection.findIndex((e: any) => e.title === 'Tax Statements');
  expensesPage = this.expensesSection.findIndex((e:any) => e.title === 'Expenses');
  timeOffPage = this.timeSection.findIndex((e: any) => e.title === 'Time Off');
  manageEmployeesPage = this.manageEmployeesSection.findIndex((e: any) => e.title === 'Manage Employees');
  timekeepingPage = this.timeSection.findIndex((e: any) => e.title === 'Timekeeping');
  adminPage = this.adminSection.findIndex((e: any) => e.title === 'Admin');
  w4Page = this.profileSection.findIndex((e: any) => e.title === 'W-4');
  shifts = this.shiftsInProgress.findIndex((e: any) => e.title === 'Shifts in Progress');

  private destroy$ = new Subject<void>();

  constructor(
    public userInfoService: UserInfoService,
    public helperService: HelperService,
    private router: Router,
    // private translateService: TranslateService,
    private platform: Platform,
    private alertController: AlertController,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private versionCheckService: VersionCheckService,
    private modalController: ModalController,
    private timeoffService: TimeoffService
  ) {}

  ngOnInit() {
    this.userInfoService
      .getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((u) => {
        if (u) {
          this.userInfo$ = u;
          this.setMenu();
        }
      });

    this.userInfoService.supervisor$.pipe(takeUntil(this.destroy$)).subscribe((sup) => {
      this.supervisor = sup;
    });

    this.versionCheckService
      .versionCheck()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {},
        error: () => {
          this.updateAvailable = true;
        },
      });

    this.userInfoService.checkW2Paperless();
    //presentYearEndW2ValidationAlert() handles the year on the message. Display in December and January. RSL-45
    if ((this.currentMonth === 11 || this.currentMonth === 0) && this.userInfo$.loginType.toLowerCase() === 'employee') {
      this.userInfoService.presentYearEndW2ValidationAlert();
    }

    const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
    if (isPushNotificationsAvailable) {
      this.registerFirebaseToken();
    }

    this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
      if (this.router.url === '/dashboard') {
        // Show Exit Alert!
        this.showExitConfirm();
        processNextHandler();
      } else {
        // Navigate to back page
        history.back();
      }
    });

    this.platform.backButton.subscribeWithPriority(5, () => {
      // console.log('Handler called to force close!');
      this.alertController
        .getTop()
        .then((r) => {
          if (r) {
            App.exitApp();
          }
        })
        .catch((e) => {});
    });

    this.setMenu();

    // Check to see if this account has linked logins
    this.userInfoService.getUserAccounts();

    // const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
    // if (isPushNotificationsAvailable) {
    //   this.initPushNotifications();
    // }

    this.logAnalytics();

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.logAnalytics();
        if (['/payroll', '/w4'].includes(val.url)) {
          this.showPayroll = true;
        } else {
          this.showPayroll = false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  update() {
    this.versionCheckService.openNewVersionBanner();
  }

  logAnalytics() {
    if (this.platform.is('cordova')) {
      // try {
      //   this.firebaseAnalytics
      //     .logEvent('page_view', { page: this.router.url.replace('/', '') })
      //     .then((res: any) => console.log(res))
      //     .catch((error: any) => console.error(error));
      // } catch (err) {
      //   //
      // }
    }
  }

  setMenu() {
    this.showMenu = {
      home: true,
      supervisor: false,
      profile: true,
      payroll: true,
      w4: true,
      taxStatements: true,
      expenses: true,
      timeoff: true,
      management: false,
      timeoffapprove: false,
      employees: false,
      timekeeping: true,
      calendar: true,
      admin: false,
      about: false,
      contact: true,
      shiftsInProgress: false,
    };
    // this.showMenu.timekeeping = this.userInfoService.userInfo.useTimeClock;
    this.showMenu.admin = this.userInfoService.userInfo.siteAdmin;

    this.timeSection[this.timekeepingPage].display = this.userInfoService.userInfo.useTimeClock;
    this.adminSection[this.adminPage].display = this.userInfoService.userInfo.siteAdmin;

    this.isSupervisor();
    // this.showMenu.timeoffapprove = this.userInfoService.userInfo.isManager > 0 ? true : false;
    this.showMenu.management = this.userInfoService.userInfo.isManager > 0 ? true : false;
    this.manageEmployeesSection[this.manageEmployeesPage].display =
      this.userInfoService.userInfo.isManager > 0 ? true : false;
    // this.showMenu.employees = this.userInfoService.userInfo.isManager > 0 ? true : false;

    this.showMenu.timeoff = this.userInfo$.companyUsesPto;
    this.timeSection[this.timeOffPage].display = this.userInfo$.companyUsesPto;

    if (this.userInfoService.userInfo.isManager) {
      this.timeoffService.getEmployeePtoRequest(0, 'P').subscribe((data) => {
        this.numberOfPtoRequest = data.length;
      });
    }

    // const currentUrl = this.router.url;
    // this.pages.every((item, index) => {
    //   this.pages[index].open = currentUrl.startsWith(item.url);

    //   if (item.children && !this.pages[index].open) {
    //     item.children.every((c) => {
    //       this.pages[index].open = currentUrl.startsWith(c.url);
    //       if (this.pages[index].open) {
    //         return false;
    //       }

    //       return true;
    //     });
    //   }

    //   if (this.pages[index].open) {
    //     return false;
    //   }

    //   return true;
    // });

    // console.log(this.pages);
  }

  isSupervisor() {
    this.showMenu.supervisor = this.supervisor?.sysId > 0 ? true : false;
    this.homeSection[this.timekeepingApprovalsPage].display = this.supervisor?.sysId > 0 ? true : false;

    this.showMenu.shiftsInProgress = this.supervisor?.sysId > 0 ? true : false;
    this.shiftsInProgress[this.shifts].display = this.supervisor?.sysId > 0 ? true : false;
    // console.log(this.userInfo$);
    if (this.userInfo$.eeSysId === 0) {
      this.showMenu.home = false;
      this.homeSection[this.dashboardPage].display = false;
      this.showMenu.payroll = false;
      this.payrollSection[this.payrollPage].display = false;
      this.payrollSection[this.taxStatementsPage].display = false;
      this.expensesSection[this.expensesPage].display = false;
      this.profileSection[this.w4Page].display = false;
      this.showMenu.taxStatements = false;
      this.showMenu.expenses = false;
      this.showMenu.w4 = false;

      this.showMenu.timeoff = false;
      this.timeSection[this.timeOffPage].display = false;

      this.showMenu.shiftsInProgress = false;
      this.timeSection[this.timeOffPage].display = false;
    }
  }

  /**
   * ShowProfileActions
   * This is the bottom pop up menu with actions
   */
  async showProfileActions() {
    let createdActionSheet: any;
    // const buttons: ActionSheetButton[] = [
    //   {
    //     text: this.translateService.instant('Profile'),
    //     icon: this.platform.is('ios') ? undefined : 'planet',
    //     role: 'destructive',
    //     handler: () => this.profile(),
    //   },
    //   {
    //     text: this.translateService.instant('Logout'),
    //     icon: this.platform.is('ios') ? undefined : 'log-out',
    //     role: 'destructive',
    //     handler: () => this.logout(),
    //   },
    //   {
    //     text: this.translateService.instant('Change language'),
    //     icon: this.platform.is('ios') ? undefined : 'globe',
    //     handler: async () => {
    //       // Wait for action sheet dismiss animation to finish, see "Dismissing And Async Navigation" section in:
    //       // http://ionicframework.com/docs/api/components/action-sheet/ActionSheetController/#advanced
    //       await createdActionSheet.dismiss();
    //       this.changeLanguage();
    //       return false;
    //     },
    //   },
    //   {
    //     text: this.translateService.instant('Cancel'),
    //     icon: this.platform.is('ios') ? undefined : 'close',
    //     role: 'cancel',
    //   },
    // ];

    // // On Cordova platform language is set to the device language
    // if (this.platform.is('cordova')) {
    //   buttons.splice(1, 1);
    // }

    // const actionSheetOptions: ActionSheetOptions = {
    //   header: this.username || undefined,
    //   buttons,
    // };

    // createdActionSheet = await this.actionSheetController.create(actionSheetOptions);
    // await createdActionSheet.present();
  }

  get username(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.username : null;
  }

  async contactUs() {
    const modal = await this.modalController.create({
      component: ContactUsComponent,
    });
    modal.onDidDismiss().then((res) => {
      // console.log('mail');
    });
    await modal.present();
  }

  async changePassword() {
    const passwordChange = await this.modalController.create({
      component: ChangePasswordComponent,
    });

    passwordChange.onDidDismiss().then((data) => {
      // console.log('email closed')
    });
    await passwordChange.present();
  }

  logout() {
    this.authenticationService.logout();
  }

  showExitConfirm() {
    this.alertController
      .create({
        header: 'App termination',
        message: 'Do you want to close the app and logout?',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Stay',
            role: 'cancel',
            handler: () => {
              // console.log('Application exit prevented!');
            },
          },
          {
            text: 'Exit',
            handler: () => {
              App.exitApp();
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }

  private profile() {
    this.router.navigate(['/profile'], { replaceUrl: true });
  }

  get isWeb(): boolean {
    return !this.platform.is('cordova');
  }

  private async changeLanguage() {
    // const alertController = await this.alertController.create({
    //   header: this.translateService.instant('Change language'),
    //   inputs: this.i18nService.supportedLanguages.map((language) => ({
    //     type: 'radio' as TextFieldTypes,
    //     name: language,
    //     label: language,
    //     value: language,
    //     checked: language === this.i18nService.language,
    //   })),
    //   buttons: [
    //     {
    //       text: this.translateService.instant('Cancel'),
    //       role: 'cancel',
    //     },
    //     {
    //       text: this.translateService.instant('Ok'),
    //       handler: (language) => {
    //         this.i18nService.language = language;
    //       },
    //     },
    //   ],
    // });
    // await alertController.present();
  }

  async ptoApproval() {
    const modal = await this.modalController.create({
      component: PtoApprovalComponent,
      componentProps: {
        managerView: true,
      },
    });
    modal.onDidDismiss().then((res) => {
      // console.log('mail');
    });
    await modal.present();
  }

  async employees() {
    const modal = await this.modalController.create({
      component: EmployeesComponent,
      componentProps: {
        managerId: this.userInfo$.isManager,
      },
    });
    modal.onDidDismiss().then((res) => {
      // console.log('mail');
    });
    await modal.present();
  }

  registerFirebaseToken() {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', async (token: Token) => {
      const deviceId = await Device.getId();
      const uuId = deviceId.identifier;
      this.userInfoService.linkFirebase(uuId, this.userInfo$.username, token).subscribe();
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {
      // console.log('Error on registration: ' + JSON.stringify(error));
    });
  }
}
