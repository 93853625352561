import { FormErrorsModule } from './../@components/form-errors/form-errors.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';

// import { I18nModule } from '@app/i18n';
import { AuthRoutingModule } from './auth-routing.module';
import { PasswordCodeComponent } from './password-code/password-code.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { DeliveryOptionComponent } from './delivery-option/delivery-option.component';
import { ForgotUsernameComponent } from './forgot-username/forgot-username.component';
import { MatRadioModule } from '@angular/material/radio';
import { LoginComponent } from './login/login.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // TranslateModule,
    IonicModule,
    // I18nModule,
    FormErrorsModule,
    MatCheckboxModule,
    MatRadioModule,
    AuthRoutingModule,
  ],
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    PasswordCodeComponent,
    PasswordChangeComponent,
    DeliveryOptionComponent,
    ForgotUsernameComponent,
  ],
})
export class AuthModule {}
