import { Injectable, OnDestroy } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, Subject, switchMap, take, takeUntil } from 'rxjs';

import { environment } from '@env/environment';
import { UserInfoService } from '@app/@services/user-info.service';
import { UserInfoModel } from '@app/@models/userInfo.model';

/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiPrefixInterceptor implements HttpInterceptor, OnDestroy {
  userInfo: any = {
    userSysId: 0,
    loginname: '',
    supervisorSysId: 0,
    employeeId: 0,
    companyId: '',
  };
  private destroy$ = new Subject<void>();

  constructor(protected userInfoService: UserInfoService) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.userInfoService
      .getUser()
      .pipe(takeUntil(this.destroy$))
      .pipe(
        take(1),
        switchMap((userInfo) => {
          this.userInfo = userInfo;
          this.userInfo.loginname = userInfo.username;
          request = this.addServerPrefix(request);

          if (this.isIpifyRequest(request)) {
            return next.handle(request);
          }

          const idToken = this.getIdToken();

          if (idToken) {
            request = this.handleAuthenticatedRequest(request, idToken, this.userInfo);
          } else {
            request = this.handleUnauthenticatedRequest(request, this.userInfo);
          }

          return next.handle(request);
        })
      );
  }

  private addServerPrefix(request: HttpRequest<any>): HttpRequest<any> {
    if (!/^(http|https):/i.test(request.url)) {
      return request.clone({ url: environment.serverUrl + request.url });
    }
    return request;
  }

  private isIpifyRequest(request: HttpRequest<any>): boolean {
    return request.url === 'https://api.ipify.org/?format=json';
  }

  private getIdToken(): string | null {
    return localStorage.getItem('id_token');
  }

  private handleAuthenticatedRequest(request: HttpRequest<any>, idToken: string, userInfo: any): HttpRequest<any> {
    if (request.headers.get('Accept')?.startsWith('multipart/form-data')) {
      return this.cloneRequestWithFormData(request, idToken, userInfo);
    }

    let body = { ...request.body, userInfo, version: environment.version };
    return this.cloneRequestWithHeaders(request, idToken, body);
  }

  private handleUnauthenticatedRequest(request: HttpRequest<any>, userInfo: any): HttpRequest<any> {
    let body = { ...request.body, userInfo, version: environment.version };
    return request.clone({
      headers: request.headers.set('x-api-key', environment.apikey).set('version', environment.version),
      body,
    });
  }

  private cloneRequestWithHeaders(request: HttpRequest<any>, idToken: string, body: any): HttpRequest<any> {
    const t = request.clone({
      headers: request.headers
        .set('Bearer', idToken)
        .set('Authorization', `Bearer ${idToken}`)
        .set('x-api-key', environment.apikey)
        .set('version', environment.version),
      body,
    });
    return t;
    return request.clone({
      headers: request.headers
        .set('Bearer', idToken)
        .set('Authorization', `Bearer ${idToken}`)
        .set('x-api-key', environment.apikey)
        .set('version', environment.version),
      body,
    });
  }

  private cloneRequestWithFormData(request: HttpRequest<any>, idToken: string, userInfo: any): HttpRequest<any> {
    const formData = new FormData();
    request.body.keys().forEach((key: string) => {
      formData.append(key, request.body.get(key));
    });
    formData.append('userSysId', userInfo.userSysId);
    formData.append('loginname', userInfo.loginname);
    // Append additional userInfo fields as needed

    return request.clone({
      headers: request.headers
        .set('Bearer', idToken)
        .set('x-api-key', environment.apikey)
        .set('version', environment.version),
      body: formData,
    });
  }
}
