import { PushNotificationService } from './../../@services/push-notification.service';
import { AfterViewInit, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { PtoRequestModel, setPtoRequestModelDefaults } from '@app/@models/pto-request.model';
import { EmailService } from '@app/@services/email.service';
import { HelperService } from '@app/@services/helper.service';
import { TimeoffService } from '@app/@services/timeoff.service';
import { UserInfoService } from '@app/@services/user-info.service';
import { AlertController, ModalController, IonSelect, IonicModule } from '@ionic/angular';
import { EmailComponent } from '../email/email.component';
import { PtoRequestComponent } from '../pto-request/pto-request.component';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { LoaderService } from '@app/@services/loader.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '@app/@shared';
import { EmailModule } from '../email/email.module';
import { YearSelectModule } from '../year-select/year-select.module';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { NameAndIdComponent } from '../name-and-id/name-and-id.component';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-pto-approval',
  templateUrl: './pto-approval.component.html',
  styleUrls: ['./pto-approval.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    SharedModule,
    EmailModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    YearSelectModule,
    NameAndIdComponent,
  ],
})
export class PtoApprovalComponent implements OnInit, AfterViewInit {
  @Input() managerView = false;
  @Input() isModal = true;

  title = 'Time Off Request Management';
  ptoRequest: PtoRequestModel[];
  filteredPtoRequest: PtoRequestModel[];
  selectedPtoRequest: PtoRequestModel;
  isLoading = false;
  isManager: boolean = false;
  hideEmployees = false;
  filterStatusString: string;
  year: number;
  hasManagerControl = false;
  allowApprove: boolean = false;
  allowDecline: boolean = false;

  requestId: number;
  requestStatus: string;

  dataSource = new MatTableDataSource<PtoRequestModel>();
  displayedColumns: string[] = ['select', 'name', 'policyName', 'status', 'hours', 'date', 'reason', 'actions'];
  sortOptions: { name: string; label: string }[] = [
    { name: 'lastName', label: 'Last Name' },
    { name: 'ptoPolicyName', label: 'Policy Name' },
    { name: 'status', label: 'Status' },
    { name: 'hoursRequested', label: 'Hours Requested' },
    { name: 'beginDate', label: 'Begin Date' },
  ];
  activeSort: string = 'beginDate';
  activeSortDirection: string = 'desc';
  selection = new SelectionModel<PtoRequestModel>(true, []);

  @ViewChild('manageEmployeeTable') manageEmployeeTable: any;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.manageEmployeeTable.dataSource.sort = sort;
    this.manageEmployeeTable.dataSource.sort.active = this.activeSort;
    this.manageEmployeeTable.dataSource.sort.start = 'desc';
  }

  ptoStatus: Map<string, string> = new Map([
    ['p', 'Awaiting Approval'],
    ['r', 'Declined'],
    ['anp', 'Approved Not Paid'],
    ['pr', 'Processing'],
    ['a', 'Paid'],
    ['all', 'All'],
  ]);

  ptoMonthPrior = new Date();
  monthsBack = 3;

  searchText: string = '';
  searchKeys = ['firstName', 'lastName', 'ptoStatus', 'beginDate', 'endDate'];

  constructor(
    public helperService: HelperService,
    private timeoffService: TimeoffService,
    private userInfoService: UserInfoService,
    private emailService: EmailService,
    private alertController: AlertController,
    private modalController: ModalController,
    private pushNotificationService: PushNotificationService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.ptoMonthPrior.setMonth(this.ptoMonthPrior.getMonth() - this.monthsBack);
    if (this.isModal) {
      this.isAModal();
    }

    this.isManager = this.userInfoService.userInfo.isManager > 0 ? true : false;
    // this will remove the checkboxes if not a manager
    if (!this.isManager) {
      this.displayedColumns.splice(0, 1);
    }

    let cond = sessionStorage.getItem('filterStatusString');
    this.filterStatusString = cond === null ? 'p' : cond;

    this.helperService.castActiveYear.subscribe((yr) => {
      this.year = yr;
      this.getPto();
    });
  }

  ngAfterViewInit(): void {
    this.sortData('beginDate');
  }

  setFilterPredicate() {
    this.dataSource.filterPredicate = (data, filter) => {
      const dataStr = Object.values(this.searchKeys)
        .reduce((currentTerm, key) => {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
  }

  applyFilter() {
    this.dataSource.filter = this.searchText.length ? this.searchText.trim().toLowerCase() : '';

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clearSearch() {
    this.searchText = '';
    this.applyFilter();
  }

  isAModal() {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, null);
  }

  checkManagerControls(managerId: number) {
    if (this.isManager && this.userInfoService.userInfo.isManager === managerId) {
      return true;
    }

    return false;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.handleEnableAction();
      return;
    }

    this.selection.select(...this.dataSource.data);
    this.handleEnableAction();
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PtoRequestModel): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.requestId}`;
  }

  handleRowSelection(row: PtoRequestModel) {
    this.selection.toggle(row);
    this.handleEnableAction();
  }

  handleEnableAction() {
    let rowsSelected = this.selection.selected.length;
    let hasApproved = this.selection.selected.map((request) => request.status.toLowerCase()).includes('a');
    let hasDeclined = this.selection.selected.map((request) => request.status.toLowerCase()).includes('r');
    let hasPaidOrProcessing = this.selection.selected
      .map((request) => request)
      .find((request) => request.status.toLowerCase() === 'a' && request.empHoursNumber > 0);

    if (rowsSelected) {
      if (hasPaidOrProcessing) {
        this.allowApprove = false;
        this.allowDecline = false;
        return;
      }

      if (hasApproved) {
        this.allowApprove = false;
      } else {
        this.allowApprove = true;
      }

      if (hasDeclined) {
        this.allowDecline = false;
      } else {
        this.allowDecline = true;
      }
    } else {
      this.allowApprove = false;
      this.allowDecline = false;
    }
  }

  sortData(column: string) {
    let sort = this.manageEmployeeTable.dataSource.sort;
    let direction = sort.direction ? sort.direction : 'asc';

    sort.sort({
      id: column,
      direction: direction === 'asc' ? 'desc' : 'asc',
      disableClear: true,
    });

    this.dataSource.filteredData = this.dataSource.data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      this.activeSortDirection = isAsc === true ? 'asc' : 'desc';
      switch (sort.active) {
        case 'lastName':
          return this.compare(a.lastName, b.lastName, isAsc);
        case 'ptoPolicyName':
          return this.compare(a.ptoPolicyName, b.ptoPolicyName, isAsc);
        case 'status':
          return this.compare(a.status, b.status, isAsc);
        case 'hoursRequested':
          return this.compare(a.hoursRequested, b.hoursRequested, isAsc);
        case 'beginDate':
          return this.compare(a.beginDate, b.beginDate, isAsc);
        default:
          return 0;
      }
    });

    this.activeSort = column;
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    if (a === b) return 0;
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  ngOnDestroy(): void {
    this.modalController.dismiss();
    if (window.history.state.modal) {
      history.back();
    }
  }

  @HostListener('window:popstate', ['$event'])
  dismissModal() {
    if (this.isModal) {
      this.modalController.dismiss();
    }
  }

  getPto() {
    if (this.managerView && this.isManager) {
      this.getEmployeePtoRequest();
    } else {
      this.getPtoRequest();
    }
  }

  getPtoRequest() {
    this.timeoffService.getPtoRequest(this.year).subscribe((data) => {
      this.ptoRequest = data;
      this.filteredPtoRequest = data;
      this.filterStatus(this.filterStatusString);
    });
  }

  getEmployeePtoRequest() {
    this.timeoffService.getEmployeePtoRequest(0, '', this.monthsBack).subscribe((data) => {
      // Remove user record if it exists
      // console.log(data);
      data = data.filter((r) => r.employeeId !== this.userInfoService.userInfo.employeeId);

      this.ptoRequest = data;
      this.filteredPtoRequest = data;
      this.filterStatus(this.filterStatusString);

      this.autoApproveDecline();
    });
  }

  changeMonthsBack(months: number) {
    this.selection.clear();
    this.handleEnableAction();
    this.clearSearch();
    this.monthsBack = months;
    this.ptoMonthPrior = new Date();
    this.ptoMonthPrior.setMonth(this.ptoMonthPrior.getMonth() - this.monthsBack);
    this.getEmployeePtoRequest();
  }

  autoApproveDecline() {
    if (this.requestId > 0) {
      this.ptoRequest.forEach((obj) => {
        if (obj.requestId === this.requestId) {
          // console.log(obj);
          if (obj.status !== 'P') {
            this.alert('This request has already been approved or declined');
          } else {
            if (this.requestStatus === 'A') {
              this.approveReject(obj, true);
              this.alert('Request has been approved');
            } else if (this.requestStatus === 'R') {
              this.approveReject(obj, false);
              this.alert('Request has been declined');
            }
          }
        }
      });
    }
  }

  filterStatus(v: string) {
    this.selection.clear();
    this.handleEnableAction();
    this.clearSearch();
    this.filterStatusString = v.toLowerCase();
    sessionStorage.setItem('filterStatusString', this.filterStatusString);
    // console.log(v);
    this.filterRequest();
  }

  editPto(p: PtoRequestModel) {
    // console.log("PtoRequest Status:" + p.status + "; p.empHoursNumber:" + p.empHoursNumber)
    if (p.status.toLowerCase() === 'a' && p.empHoursNumber > 0) {
      this.alert('This request has already been paid and can not be edited, please contact your manager.');
    } else if (p.empHoursNumber > 0) {
      this.alert('This request is pending payment and can not be edited.');
    } else if (!this.isManager && p.status.toLowerCase() === 'a' && p.empHoursNumber === 0) {
      this.alert('This request has already been approved and can not be edited, please contact your manager.');
    } else if (!this.isManager && p.status.toLowerCase() === 'r') {
      this.alert('This request has already been declined and can not be edited, please contact your manager.');
    } else {
      this.selectedPtoRequest = p;
      this.openPtoEdit();
    }
  }

  async openPtoEdit() {
    const ptoModal = await this.modalController.create({
      component: PtoRequestComponent,
      componentProps: {
        ptoRequest: this.selectedPtoRequest,
      },
    });

    ptoModal.onDidDismiss().then((data) => {
      this.getPto();
    });
    await ptoModal.present();
    //this.pageChange('requestTimeoff');
  }

  async alert(message: string) {
    let alert = await this.alertController.create({
      message,
      buttons: ['OK'],
    });

    await alert.present();
    return;
  }

  approveReject(r: PtoRequestModel, approve: boolean) {
    if (approve) {
      r.status = 'A';
    } else {
      r.status = 'R';
    }

    r.approvedBy = this.userInfoService.userInfo.username;
    // console.log(r);
    this.timeoffService.requestTimeOff(r).subscribe((res: any) => {
      // console.log(res);
      const index = this.filteredPtoRequest.map((r) => r.requestId).indexOf(r.requestId);
      this.selection.deselect(this.dataSource.data[index]);

      if (res.success) {
        this.timeoffService.getPTORequestEmployeeInfo(r.requestId).subscribe((d) => {
          this.emailService.ptoRequest(r, d[0]);
          this.pushNotificationService.timeoffRequestStatus(r);
        });

        // this.dataSource.data.splice(index, 1);
        // this.dataSource._updateChangeSubscription(); // <-- Refresh the datasource
      }

      if (this.isLoading) {
        this.loaderService.changeMessage(
          'please wait while we process your request ' + this.selection.selected.length + ' remaining'
        );
        if (this.selection.selected.length === 0) {
          this.isLoading = false;
          this.loaderService.hideLoader();
          this.filterRequest();
        }
      }
    });
  }

  filterRequest() {
    if (this.filterStatusString == 'all') {
      this.filteredPtoRequest = this.ptoRequest;
    } else {
      this.filteredPtoRequest = this.ptoRequest.filter((obj) => {
        let status = false;

        if (this.filterStatusString === 'p' && obj.status.toLowerCase() === 'p') {
          status = true;
        }

        if (this.filterStatusString === 'r' && obj.status.toLowerCase() === 'r') {
          status = true;
        }

        if (
          this.filterStatusString === 'anp' &&
          obj.status.toLowerCase() === 'a' &&
          obj.empHoursNumber == 0 &&
          obj.checkNumber === 0
        ) {
          status = true;
        }

        if (
          this.filterStatusString === 'pr' &&
          obj.status.toLowerCase() === 'a' &&
          obj.empHoursNumber > 0 &&
          obj.checkNumber === 0
        ) {
          status = true;
        }

        if (
          this.filterStatusString === 'a' &&
          obj.status.toLowerCase() === 'a' &&
          obj.empHoursNumber > 0 &&
          obj.checkNumber > 0
        ) {
          status = true;
        }

        return status;
      });
    }
    this.dataSource = new MatTableDataSource(this.filteredPtoRequest);
    this.setFilterPredicate();
    // console.log(this.dataSource.data);
  }

  convertStatus(r: PtoRequestModel, short: boolean = false) {
    return this.timeoffService.convertStatus(r, short);
  }

  async emailEmployee(r: PtoRequestModel) {
    //console.log(timeSheet);
    let to = Array(),
      from = this.userInfoService.userInfo.email,
      subject = 'Time Off Request';

    const empName = r.firstName + ' ' + r.lastName;
    to.push({ value: r.employeeEmail, display: empName });

    const emailModal = await this.modalController.create({
      component: EmailComponent,
      componentProps: {
        to,
        from,
        subject,
      },
    });

    emailModal.onDidDismiss().then((data) => {
      // console.log('email closed')
    });
    await emailModal.present();
  }

  async approveRejectAll(approve: boolean) {
    this.loaderService
      .showLoader('please wait while we process your request ' + this.selection.selected.length + ' remaining')
      .then(async () => {
        // this.processRequest();
        this.isLoading = true;
        for (let item of this.selection.selected) {
          // await this.sleep(500);

          this.approveReject(item, approve);
        }
      });
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async removePTORequest(r: PtoRequestModel) {
    let requestDates = (r.beginDate = r.endDate ? r.beginDate : r.beginDate + ' -' + r.endDate);
    let es = this.emailService;
    let alert = await this.alertController.create({
      header: 'Confirm Delete',
      message: `Are you sure you want to delete the time off request of ${r.hoursRequested} for ${requestDates}?`,
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Delete',
          cssClass: 'danger',
          handler: async () => {
            let contactInfo = await firstValueFrom(this.timeoffService.getPTORequestEmployeeInfo(r.requestId));

            r.employeeId = r.employeeId * -1; // Change this to negative number to tell the ptoRequest is a removal.

            this.timeoffService.removePTORequest(r).subscribe(() => {
              const index = this.dataSource.data.indexOf(r);
              this.dataSource.data.splice(index, 1);
              this.dataSource._updateChangeSubscription(); // <-- Refresh the datasource
              this.emailService.ptoRequest(r, contactInfo[0]);
            });
          },
        },
      ],
    });

    await alert.present();
  }
}
