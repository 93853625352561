import { UserInfoService } from '@app/@services/user-info.service';

import { AlertController } from '@ionic/angular';
import { Component, OnInit, HostListener, NgZone } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
// import { Title } from '@angular/platform-browser';
// import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

import { environment } from '@env/environment';
// import { Logger } from '@core';

import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';
import { AuthenticationService, CredentialsService } from './auth';
import { VersionCheckService } from './@services/version-check.service';
// import { TranslateService } from '@ngx-translate/core';
// import { I18nService } from '@app/i18n';

// const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  inActiveDate: Date;
  tabActive: boolean;

  // sets an idle timeout of 5 minutes and a timeout period of 30 seconds
  idleDuration: number = 300;
  // after 5 1/2 minutes of inactivity, the user will be considered timed out.
  timeoutDuration: number = 30;

  alert: any;
  alertHeader: string;
  alertMessage: string;
  idleAlert: boolean = false;

  i1: any;
  //  Random comment

  constructor(
    private router: Router,
    // private activatedRoute: ActivatedRoute,
    // private titleService: Title,
    // private translateService: TranslateService,
    // private i18nService: I18nService,
    private idle: Idle,
    private keepalive: Keepalive,
    private alertController: AlertController,
    private authService: AuthenticationService,
    private userInfoService: UserInfoService,
    // private modalController: ModalController,
    private zone: NgZone,
    private credentialService: CredentialsService,
    private versionCheckService: VersionCheckService
  ) {
    idle.setIdle(this.idleDuration);
    idle.setTimeout(this.timeoutDuration);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleStart.subscribe(() => {
      this.handleIdleStart();
    });

    idle.onIdleEnd.subscribe(() => {
      this.handleIdleEnd();
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.handleTimeoutWarning(countdown);
    });

    idle.onTimeout.subscribe(() => {
      this.handleTimeout();
    });

    // only allow for idle watch if the user is not on the login page
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.authService.getUserLoggedIn().subscribe((loggedIn) => {
          if (loggedIn) {
            // console.log('***** LOGGED IN *****');
            this.startIdleWatch();
          } else {
            // console.log('***** NOT LOGGED IN *****');
            this.idle.stop();
          }
        });
      }
    });

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.tabActive = true;
        // console.log('this.tabActive: ', this.tabActive);
      } else {
        this.tabActive = false;
        // console.log('this.tabActive: ', this.tabActive);
      }
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => {
      this.lastPing = new Date();
    });

    this.inActiveDate = new Date();
    this.appListener();
    this.tabActive = true;
    // translateService.setDefaultLang(environment.defaultLanguage);
    // translateService.use(environment.defaultLanguage);
  }

  idleListener() {}

  appListener() {
    // this will run when ever the app minimizes or opens back up
    App.addListener('appStateChange', ({ isActive }: { isActive: any }) => {
      // console.log('App state changed. Is active?', isActive);
      // set the time if the app goes into a minimized state
      if (!isActive) {
        // console.log('set an inactive timestamp');
        this.inActiveDate = new Date();
      }

      // if isactive the app is waking up
      if (isActive) {
        // console.log(this.inActiveDate);
        // check to make sure a minimized timestamp was set, if not log them out
        if (typeof this.inActiveDate !== 'undefined') {
          const timeDiff = (new Date().getTime() - this.inActiveDate.getTime()) / 1000;
          // console.log('timediff', timeDiff);
          // if the app has been minimized for over 3 mins (180s) log them out
          if (timeDiff >= 180) {
            // 3 minutes
            this.authService.logout(true);
            // this.router.navigate(['login']);
          } else {
            // console.log('let you continue');
          }
        } else {
          // the this.inActiveDate is defined
          // the this.inActiveDate is not defined
          // console.log('No longer have an inactive date, log them out');
          this.authService.logout(true);
        }
      }
    });

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://beerswift.com/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split('.com').pop();
        // console.log(slug);
        if (slug) {
          // console.log(slug.substring(slug.indexOf('/') + 1, slug.length));
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });

    App.addListener('appRestoredResult', (data: any) => {
      // console.log('Restored state:', data);
    });

    const checkAppLaunchUrl = async () => {
      const { url } = await App.getLaunchUrl();

      // alert('App opened with URL: ' + url);
    };

    App.addListener('backButton', () => {});
  }

  handleIdleStart() {
    this.idleState = 'idleStart';
    // console.log(this.idleState);
  }

  handleIdleEnd() {
    this.idleState = 'idleEnd';
    // console.log(this.idleState);
    this.startIdleWatch();
  }

  handleTimeoutWarning(countdown) {
    if (this.tabActive && !this.idleAlert) {
      // if the tab is active and the alert hasn't been presented yet, set the idle state and present the alert
      // if the user activates the tab mid-countdown, the alert will be presented with the current countdown value
      this.idleState = 'timeoutWarning';
      this.alertHeader = 'You have been idle';
      this.alertMessage = 'Your session will end in ' + countdown + ' seconds';
      this.alertMsg();
    } else if (!this.tabActive) {
      // if tab is inactive, clear any alert to prevent it from getting stuck
      this.clearIdleAlert();
    } else {
      // if the alert has been presented, update the countdown timer
      this.alert.message = 'Your session will end in ' + countdown + ' seconds';
    }
    // if (countdown) {
    //   console.log('countdown:', countdown);
    // }
  }

  handleTimeout() {
    this.clearIdleAlert();
    this.idleState = 'timeout';
    this.alertHeader = 'You Have Been Logged Out';
    this.alertMessage = 'Your session has been ended due to inactivity';
    this.alertTimedOut();
    this.authService.logout(true);
    // console.log(this.idleState);
  }

  startIdleWatch() {
    this.clearIdleAlert();
    this.idle.watch();
    this.idleState = 'Started';
    // console.log(this.idleState);
  }

  clearIdleAlert() {
    if (this.alert) {
      this.alertController.dismiss();
    }
    this.idleAlert = false;
  }

  ngOnInit() {
    // console.log('init app #####');
    // if the app is just booting always log them out and send them to login.
    if (Capacitor.getPlatform() !== 'web') {
      this.authService.logout(true);
    }

    // Setup logger
    if (environment.production) {
      // Logger.enableProductionMode();
    }

    // log.debug('init');

    const prefersDark = this.userInfoService.getPreferences('prefersDark');
    // console.log('prefersDark check', prefersDark);
    if (prefersDark) {
      document.body.classList.toggle('dark', prefersDark);
    }

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    // merge(this.translateService.onLangChange, onNavigationEnd)
    //   .pipe(
    //     map(() => {
    //       let route = this.activatedRoute;
    //       while (route.firstChild) {
    //         route = route.firstChild;
    //       }
    //       return route;
    //     }),
    //     filter((route) => route.outlet === 'primary'),
    //     switchMap((route) => route.data),
    //   )
    //   .subscribe((event) => {
    //     const title = event.title;
    //     if (title) {
    //       this.titleService.setTitle(this.translateService.instant(title));
    //     }
    //   });

    const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
    if (isPushNotificationsAvailable) {
      this.initPushNotifications();
    }
  }

  async alertTimedOut() {
    this.alert = await this.alertController.create({
      header: this.alertHeader,
      message: this.alertMessage,
      buttons: [
        {
          text: 'OK',
          cssClass: 'primary',
        },
      ],
    });
    await this.alert.present();
  }

  async alertMsg() {
    this.idleAlert = true;
    this.alert = await this.alertController.create({
      header: this.alertHeader,
      message: this.alertMessage,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Logout',
          cssClass: 'secondary',
          handler: () => {
            this.authService.logout(true);
          },
        },
        {
          text: 'Stay',
          cssClass: 'primary',
          handler: () => {
            this.startIdleWatch();
          },
        },
      ],
    });
    await this.alert.present();
  }

  async initPushNotifications() {
    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      // console.log('Push received: ' + JSON.stringify(notification));
      this.pushNotificationReceived(notification);
    });

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
      // console.log('Push action performed: ' + JSON.stringify(notification));
      this.pushNotificationReceived(notification.notification);
    });
  }

  private async pushNotificationReceived(notification: PushNotificationSchema) {
    let buttons = [{ text: 'Ok', handler: () => {} }];

    if (notification.data.requestId) {
      buttons = [{ text: 'Close', handler: () => {} }];
      buttons.push({
        text: 'Approve',
        handler: () => {
          this.router.navigate([`/timeoff/timeoffStatus/${notification.data.requestId}/A`]);
        },
      });
      buttons.push({
        text: 'Decline',
        handler: () => {
          this.router.navigate([`/timeoff/timeoffStatus/${notification.data.requestId}/R`]);
        },
      });
    } else {
      // console.log(notification.data);
    }

    let header = notification.data.title ? notification.data.title : notification.title;
    let message = notification.data.body ? notification.data.body : notification.body;

    if (notification.data.versionUpdate) {
      this.versionCheckService.openNewVersionBanner();
      // buttons = [{ text: 'Later', handler: () => {} }];
      // notification.title = 'New Version';
      // notification.body = 'There is a new version available for download!  Do you want to update to the latest version?';
      // header = 'New Version';
      // message = 'There is a new version available for download!  Do you want to update to the latest version?';
      // if (this.platform.is('android')) {
      //   buttons.push({
      //     text: 'Update',
      //     handler: () => {
      //       this.market.open('com.rts.realtime_mobile');
      //     },
      //   });
      // } else if (this.platform.is('ios')) {
      //   buttons.push({
      //     text: 'Update',
      //     handler: () => {
      //       this.market.open('com.realtimemobile');
      //     },
      //   });
      // }
    }
    // const alertController = await this.alertController.create({
    //   header,
    //   message,
    //   buttons,
    // });
    // await alertController.present();
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event: any) {
    // this.auth.logout();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: any) {
    //
  }
}
