<ion-header *ngIf="isModal">
  <ion-toolbar color="primary">
    <ion-item color="primary">
      <ion-icon slot="end" name="close-circle-outline" (click)="dismissModal()"></ion-icon>
      <ion-label>Employees</ion-label>
    </ion-item>
  </ion-toolbar>
</ion-header>

<div class="main-section__content full-screen">
  <div class="table-container">
    <div class="table-controls">
      <div class="table-controls__search">
        <mat-form-field rs-size="medium">
          <mat-label>Search</mat-label>
          <mat-icon matPrefix>search</mat-icon>
          <input matInput [(ngModel)]="searchText" (keyup)="applyFilter()" />
          <button
            mat-icon-button
            matSuffix
            type="button"
            class="clear"
            [ngClass]="{ visible: searchText.length > 0 }"
            (click)="clearSearch()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="table-controls__actions">
        <div class="table-controls__actions--left"></div>
        <div class="table-controls__actions--right">
          <button
            mat-button
            rs-size="medium"
            rs-emphasis="subtle"
            rs-type="leading-icon"
            rs-action="secondary"
            [matMenuTriggerFor]="sortActions"
          >
            <mat-icon>sort</mat-icon>
            Sort
          </button>
          <mat-menu #sortActions="matMenu">
            @for (option of sortOptions; track $index) {
            <button
              mat-menu-item
              (click)="sortData(option.name); $event.stopPropagation()"
              [ngClass]="{ active: activeSort === option.name }"
            >
              {{ option.label }}
              @if (activeSortDirection === 'asc') {
              <mat-icon [ngStyle]="{ visibility: activeSort === option.name ? 'visible' : 'hidden' }">
                arrow_upward
              </mat-icon>
              } @else {
              <mat-icon [ngStyle]="{ visibility: activeSort === option.name ? 'visible' : 'hidden' }">
                arrow_downward
              </mat-icon>
              }
            </button>
            }
          </mat-menu>
        </div>
      </div>
    </div>

    <!-- desktop -->
    <div class="table-wrapper ion-hide-md-down">
      <table
        #employeeTable
        mat-table
        matSort
        [matSortDisableClear]="true"
        [dataSource]="dataSource"
        class="table border-row border-column"
      >
        <!-- Position Column -->
        <ng-container matColumnDef="fullName">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let e">
            <app-name-and-id nameValue="{{ e.lastName }}, {{ e.firstName }}" [idValue]="e.employeeId"></app-name-and-id>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="policyName">
          <th mat-header-cell *matHeaderCellDef>Policy</th>
          <td mat-cell *matCellDef="let e">{{ e.policyName }}</td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="balance">
          <th mat-header-cell *matHeaderCellDef>Balance</th>
          <td mat-cell *matCellDef="let e">{{ e.balance | number : "1.2" }}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="approvedBalance">
          <th mat-header-cell *matHeaderCellDef>Approved</th>
          <td mat-cell *matCellDef="let e">{{ e.approvedBalance | number : "1.2" }}</td>
        </ng-container>

        <!-- pendingBalance Column -->
        <ng-container matColumnDef="pendingBalance">
          <th mat-header-cell *matHeaderCellDef>Pending</th>
          <td mat-cell *matCellDef="let e">{{ e.pendingBalance | number : "1.2" }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="5">No data matching the filter "{{ searchText }}"</td>
        </tr>
      </table>
    </div>

    <div class="table-wrapper ion-hide-md-up">
      <ul class="table-list" *ngIf="dataSource.filteredData.length">
        <ng-container *ngFor="let e of dataSource.filteredData">
          <li class="table-list__item">
            <div class="item-content">
              <div class="item-content__top">
                <app-name-and-id
                  nameValue="{{ e.lastName }}, {{ e.firstName }}"
                  [idValue]="e.employeeId"
                ></app-name-and-id>
              </div>
              <div class="item-content__bottom">
                <div class="item-content__bottom--group">
                  <div class="policy">
                    <div class="label">Policy</div>
                    <div class="data">{{ e.policyName }}</div>
                  </div>
                </div>
                <div class="item-content__bottom--group">
                  <div class="balance">
                    <div class="label">Balance</div>
                    <div class="data">{{ e.balance | number : "1.2" }}</div>
                  </div>
                  <div class="approved">
                    <div class="label">Approved</div>
                    <div class="data">{{ e.approvedBalance | number : "1.2" }}</div>
                  </div>
                  <div class="pending">
                    <div class="label">Pending</div>
                    <div class="data">{{ e.pendingBalance | number : "1.2" }}</div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>

<!-- <ion-content>
  <table mat-table [dataSource]="dataSource" class="table border-row border-column">
    <ng-container matColumnDef="fullName">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">{{ element.lastName }}, {{ element.firstName }}</td>
    </ng-container>

    <ng-container matColumnDef="policyName">
      <th mat-header-cell *matHeaderCellDef>Policy</th>
      <td mat-cell *matCellDef="let element">{{ element.policyName }}</td>
    </ng-container>

    <ng-container matColumnDef="balance">
      <th mat-header-cell *matHeaderCellDef>{{ abbreviateHeader("Balance") }}</th>
      <td mat-cell *matCellDef="let element">{{ element.balance | number : "1.2" }}</td>
    </ng-container>

    <ng-container matColumnDef="approvedBalance">
      <th mat-header-cell *matHeaderCellDef>{{ abbreviateHeader("Approved") }}</th>
      <td mat-cell *matCellDef="let element">{{ element.approvedBalance | number : "1.2" }}</td>
    </ng-container>

    <ng-container matColumnDef="pendingBalance">
      <th mat-header-cell *matHeaderCellDef>{{ abbreviateHeader("Pending") }}</th>
      <td mat-cell *matCellDef="let element">{{ element.pendingBalance | number : "1.2" }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="5">No data matching the filter "{{ filterValue }}"</td>
    </tr>
  </table>
</ion-content> -->
