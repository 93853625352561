<form [formGroup]="myForm" (ngSubmit)="onSubmit()">
  <div class="login__form--inputs">
    <div class="input-wrapper">
      <label for="new-password">New Password</label>
      <input
        id="new-password"
        [type]="hide ? 'password' : 'text'"
        inputmode="text"
        autocapitalize="off"
        autocomplete="new-password"
        autocorrect="off"
        aria-label="New Password"
        formControlName="password"
      />
      <div class="assistive-text">
        <div class="assistive-text--right">
          <span (click)="hide = !hide"> {{ hide ? "Show" : "Hide" }} Password</span>
        </div>
      </div>
    </div>

    <div class="validation-list">
      <ion-row
        class="validation-list {{ !password.value ? 'pending' : password.hasError(p.type) ? 'error' : 'success' }}"
        *ngFor="let p of errorMessages.password"
      >
        <ion-icon
          name="{{ !password.value ? 'radio-button-off' : password.hasError(p.type) ? 'close' : 'checkmark' }}"
        ></ion-icon>
        <ion-label>{{ p.message }}</ion-label>
      </ion-row>
    </div>

    <div class="input-wrapper">
      <label for="confirm-password">Confirm Password</label>
      <input
        id="confirm-password"
        [type]="cHide ? 'password' : 'text'"
        inputmode="text"
        autocapitalize="off"
        autocomplete="new-password"
        autocorrect="off"
        aria-label="Confirm Password"
        formControlName="cpassword"
      />
      <div class="assistive-text">
        <app-form-errors
          class="assistive-text--left"
          [errors]="errorMessages.cpassword"
          [item]="cpassword"
        ></app-form-errors>
        <div class="assistive-text--right">
          <span (click)="cHide = !cHide"> {{ cHide ? "Show" : "Hide" }} Password</span>
        </div>
      </div>
    </div>
  </div>

  <div class="login__form--actions">
    <ion-button [disabled]="!myForm.valid" type="submit" color="primary" size="medium" expand="block">
      Submit
    </ion-button>
    <ion-button class="back" size="small" fill="clear" color="dark" (click)="goBack()">Back to login</ion-button>
  </div>
</form>
