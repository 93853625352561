import { WebSupervisorModel } from './../@models/webSupervisor';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { UserInfoModel } from '../@models/userInfo.model';
import { environment } from '@env/environment';
import { map, catchError, shareReplay } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { Token } from '@capacitor/push-notifications';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService {
  result: any;
  public userInfo: UserInfoModel = {} as UserInfoModel;
  _userInfo = new BehaviorSubject<UserInfoModel>(this.userInfo);
  castUserInfo = this._userInfo.asObservable();

  private supervisorSubject: BehaviorSubject<WebSupervisorModel> = new BehaviorSubject(null);
  public supervisor$ = this.supervisorSubject.asObservable();

  constructor(private http: HttpClient, private alertController: AlertController) {}

  /**
   * updateEmployeeField. This is intended to change one field at a time
   * userId - Users login name
   * eeSysId - users realated sysid
   * token - session validating token
   * field - the field in the employee table to change
   * change - the value to change to
   */
  updateEmployeeField(
    companyId: string,
    employeeId: number,
    username: string,
    eeSysId: number,
    token: string,
    field: string,
    change: string,
    oldValue: string
  ): Observable<any> {
    console.log('user-info.service-updateEmployeeField-username: ' + username +' eesysId: ' + eeSysId +
    ' token: '+ token +' field: ' + field +' change: ' + change)

    const headers = new HttpHeaders().set('content-type', 'application/json').set('x-api-key', environment.apikey);
    return this.http
      .post<any>(
        environment.serverUrl + 'users/updateEmployeeField2',
        {
          companyId,
          employeeId,
          username,
          eeSysId,
          token,
          field,
          change,
          oldValue,
        },
        { headers }
      )
      .pipe(
        // tap(() => console.log("HTTP request executed")),
        map((res) => res),
        shareReplay()
      );
  }

  getUser(): Observable<UserInfoModel> {
    return this._userInfo;
  }

  // Any additional info needed needs to be the api portallogin function
  // in the api project
  getUserInfo(un: string, tk: string, info: object): Observable<UserInfoModel> {
    return of(this.userInfo);
  }

  saveUser(user: UserInfoModel) {
    this.userInfo = user;
    this._userInfo.next(user);
    this.saveUserInfo();
  }

  saveUserInfo() {
    const savedCredentials = sessionStorage.getItem('credentials') || localStorage.getItem('credentials');

    if (savedCredentials) {
      let credentials = JSON.parse(savedCredentials);
      credentials.userInfo = this.userInfo;
      sessionStorage.setItem('credentials', JSON.stringify(credentials));
    }
  }

  getUserAccounts(search: string = ''): Observable<UserInfoModel[]> {
    return this.http.post<UserInfoModel[]>(environment.serverUrl + 'users/getUserAccounts', { search });
  }

  verifySSN(eeSysId: number, ssn: number) {
    const headers = new HttpHeaders().set('content-type', 'application/json').set('x-api-key', environment.apikey);
    const token = this.userInfo.token;
    // console.log(action);

    try {
      return this.http
        .post<any>(
          environment.serverUrl + 'users/verifySSN',
          {
            eeSysId,
            ssn,
            token,
          },
          { headers }
        )
        .pipe(
          // tap(() => console.log("HTTP request executed")),
          map((res) => res),
          shareReplay()
        );
    } catch (err) {
      // console.log(err);
    }
  }

  setDefaultUser(eeSysId: number, email: string, ssn: number) {
    const headers = new HttpHeaders().set('content-type', 'application/json').set('x-api-key', environment.apikey);
    const token = this.userInfo.token;
    // console.log(action);

    try {
      return this.http
        .post<any>(
          environment.serverUrl + 'users/setDefaultUser',
          {
            eeSysId,
            ssn,
            email,
            token,
          },
          { headers }
        )
        .pipe(
          tap(() => console.log('HTTP set default user request executed')),
          map((res) => res),
          shareReplay()
        );
    } catch (err) {}
  }

  getWebLoginInfo(email: string, ssn: number, loginname: string) {
    // const headers = new HttpHeaders().set('content-type', 'application/json').set('x-api-key', environment.apikey);
    try {
      return this.http
        .post<any[]>(
          environment.serverUrl + 'users/getWebLoginInfo',
          {
            email,
            ssn,
            loginname,
          }
          // { headers }
        )
        .pipe(
          // tap(() => console.log("HTTP request executed")),
          map((res) => res),
          shareReplay()
        );
    } catch (err) {}
  }
  getusername(loginname: string) {
    const headers = new HttpHeaders().set('content-type', 'application/json').set('x-api-key', environment.apikey);
    try {
      return this.http
        .post<any[]>(
          environment.serverUrl + 'users/getusername',
          {
            loginname,
          },
          { headers }
        )
        .pipe(
          // tap(() => console.log("HTTP request executed")),
          map((res) => res),
          shareReplay()
        );
    } catch (err) {}
  }

  updatePortalPrivacy(sysId: number, portalPrivacy: boolean) {
    //const headers = new HttpHeaders().set('content-type', 'application/json').set('x-api-key', environment.apikey);
    //console.log(portalprivacy)
    try {
      return this.http.post<any>(environment.serverUrl + 'users/updatePortalPrivacy', {
        sysId,
        portalPrivacy,
      });
    } catch (err) {}
  }

  getPortalPolicy(loginname: string): Observable<any> {
    return this.http
      .post<any>(environment.serverUrl + 'users/getPortalPolicy', {
        loginname,
      })
      .pipe(
        map((res) => res[0] ?? {}), ////Nullish coalescing operator(??): if left operand is undefined/null, return right operand
        shareReplay()
      );
  }

  getSupervisorLogin(supervisorSysId: number, email: string = '') {
    return this.http.post<any>(environment.serverUrl + 'users/getSupervisorLogin', {
      supervisorSysId,
      email,
    });
  }

  linkSupervisor(loginname: string, supervisorSysId: number) {
    try {
      return this.http
        .post<any>(environment.serverUrl + 'users/linkSupervisor', {
          loginname,
          supervisorSysId,
        })
        .pipe(
          // tap(() => console.log("HTTP request executed")),
          map((res) => res),
          shareReplay()
        );
    } catch (err) {}
  }

  async generateCode(eeSysId: number, loginname: string): Promise<number> {
    const headers = new HttpHeaders().set('content-type', 'application/json').set('x-api-key', environment.apikey);
    const code = Math.floor(100000 + Math.random() * 900000);
    try {
      // console.log(code);
      this.http
        .post(
          environment.serverUrl + 'users/generateCode',
          {
            eeSysId,
            code,
            loginname,
          },
          { headers }
        )
        .subscribe(
          (response) => {},
          (error) => {}
        );

      return code;
    } catch (err) {
      return 0;
    }
  }

  verifyCode(eeSysId: number, code: number, loginname: string) {
    const headers = new HttpHeaders().set('content-type', 'application/json').set('x-api-key', environment.apikey);
    try {
      // console.log(code);
      return this.http
        .post<any>(
          environment.serverUrl + 'users/verifyCode',
          {
            eeSysId,
            code,
            loginname,
          },
          { headers }
        )
        .pipe(
          // tap(() => console.log("HTTP request executed")),
          map((res) => res),
          shareReplay()
        );
    } catch (err) {}
  }

  resetPassword(eeSysId: number, password: string, loginname: string) {
    try {
      return this.http.post(environment.serverUrl + 'users/resetPassword', {
        eeSysId,
        password,
        loginname,
      });
    } catch (err) {}
  }

  changePassword(eeSysId: number, password: string, loginname: string, opassword: string = '') {
    try {
      return this.http.post(environment.serverUrl + 'users/changePassword', {
        eeSysId,
        password,
        loginname,
        opassword,
      });
    } catch (err) {}
  }

  registerDevice(uuId: string, deviceInfo: any): Observable<any> {
    return this.http.post(environment.serverUrl + 'users/registerDevice', {
      uuId,
      deviceInfo,
    });
  }

  // linkDevice(uuId: string, challengeToken: string, hasRegisteredBiometrics: boolean): Observable<any> { /* disabled per US2105, US2106 */
  //   return this.http.post(environment.serverUrl + 'users/linkDevice', {
  //     uuId,
  //     challengeToken,
  //     hasRegisteredBiometrics,
  //   });
  // }

  linkFirebase(uuId: string, loginname: string, firebaseToken: Token): Observable<any> {
    return this.http.post(environment.serverUrl + 'users/linkFirebase', {
      uuId,
      loginname,
      firebaseToken,
    });
  }

  // TODO Angular17 see what comes back and see if it is user info model or an array
  switchAccounts(a: UserInfoModel): Observable<UserInfoModel> {
    return this.http.post<UserInfoModel>(environment.serverUrl + 'auth/switch-users', {
      loginname: a.username,
      sysId: a.sysId,
    });
  }

  setUserSupervisor(s: WebSupervisorModel) {
    this.userInfo.supervisorSysId = s.sysId;
    // TODO supervisorName does not exists
    // this.userInfo.supervisorName = s.supervisorName;
    this._userInfo.next(this.userInfo);
  }

  setUserVal(item: string, val: any) {
    this.userInfo[item] = val;
    this._userInfo.next(this.userInfo);
    this.saveUserInfo();
  }

  set userToken(val: string) {
    this.userInfo.token = val;
    this._userInfo.next(this.userInfo);
    this.saveUserInfo();
  }

  getUserVal(item: string) {
    // console.log(this.userInfo[item]);
  }

  setLogSysId(logSysId: number) {
    sessionStorage.setItem('logSysId', JSON.stringify(logSysId));
  }

  getLogSysId() {
    try {
      let logSysId = sessionStorage.getItem('logSysId');
      if (logSysId) {
        return JSON.parse(logSysId);
      }
      return 0;
    } catch (err) {
      return 0;
    }
  }

  saveCredentials() {
    const creds = {
      token: this.userInfo.token,
      username: this.userInfo.username,
      userInfo: this.userInfo,
    };
    // console.log(creds);
    const credentialsKey = 'credentials';
    const remember = localStorage.getItem('remember');
    // console.log({ 'check remember': remember });
    sessionStorage.setItem(credentialsKey, JSON.stringify(creds));
    if (remember) {
      localStorage.setItem(credentialsKey, JSON.stringify(creds));
    }
  }

  /**
   * This sets the front end userInfo
   */
  setUserInfo(s: any) {
    const rec = s.userInfo;

    let firstName = rec.firstName;
    if (firstName) {
      firstName = firstName.trim();
    }
    let lastName = rec.lastName;
    if (lastName) {
      lastName = lastName.trim();
    }

    this.userInfo.token = s.token;
    this.userInfo.sysId = rec.sysId;
    this.userInfo.username = rec.loginName;
    this.userInfo.loggedInTime = rec.loggedInTime;
    this.userInfo.companyId = rec.companyId;
    this.userInfo.eeSysId = rec.eeSysId;
    this.userInfo.employeeId = rec.employeeId;
    this.userInfo.firstName = rec.firstName;
    this.userInfo.lastName = rec.lastName;
    this.userInfo.fullName = rec.loginType == 'Supervisor' ? rec.supervisorName : firstName + ' ' + lastName;
    this.userInfo.email = rec.email;
    this.userInfo.phone = rec.phone;
    this.userInfo.cellPhone = rec.cellPhone;
    this.userInfo.contact = rec.contact;
    this.userInfo.contactPhone = rec.contactPhone;
    this.userInfo.address1 = rec.address1;
    this.userInfo.address2 = rec.address2;
    this.userInfo.city = rec.city;
    this.userInfo.state = rec.state;
    this.userInfo.zip = rec.zip;
    this.userInfo.managerFirstName = rec.managerFirstName;
    this.userInfo.managerLastName = rec.managerLastName;
    this.userInfo.managerEmail = rec.managerEmail;
    this.userInfo.managerId = rec.managerId;
    this.userInfo.companyName = rec.companyName;
    this.userInfo.companyAddress = rec.companyAddress;
    this.userInfo.companyCity = rec.companyCity;
    this.userInfo.companyState = rec.companyState;
    this.userInfo.companyZipCode = rec.companyZipCode;
    this.userInfo.emailPayroll = rec.emailPayroll;
    this.userInfo.emailHR = rec.emailHR;
    this.userInfo.branchName = rec.branchName;
    this.userInfo.branchAddress = rec.branchAddress;
    this.userInfo.branchCity = rec.branchCity;
    this.userInfo.branchState = rec.branchState;
    this.userInfo.branchZipCode = rec.branchZipCode;
    this.userInfo.branchPhone = rec.branchPhone;
    this.userInfo.branchFax = rec.branchFax;
    this.userInfo.branchEmail = rec.branchEmail;
    this.userInfo.siteAdmin = rec.siteAdmin;
    this.userInfo.canLoginAs = rec.canLoginAs;
    this.userInfo.companyUsesPto = rec.companyUsesPto;
    this.userInfo.companyUsesPtoRequestSystem = rec.companyUsesPtoRequestSystem;
    this.userInfo.dateLastlogin = rec.dateLastlogin;
    this.userInfo.loginType = rec.loginType;
    this.userInfo.supervisor = rec.supervisor;
    this.userInfo.useTimeClock = rec.useTimeClock;
    this.userInfo.loginOk = true;
    this.userInfo.hasW2s = true;
    this.userInfo.loginTime = '';
    this.userInfo.ipAddress = '';
    this.userInfo.machineName = '';
    this.userInfo.browser = '';
    this.userInfo.userAgent = '';
    this.userInfo.browserResolution = '';
    this.userInfo.xResolution = 0;
    this.userInfo.yresolution = 0;
    this.userInfo.phoneType = '';
    this.userInfo.isManager = rec.isManager;
    this.userInfo.supervisorSysId = rec.supervisorSysId;
    this.userInfo.portalPrivacy = rec.portalPrivacy;
    this.userInfo.relatedSysId = rec.relatedSysId;
    this.userInfo.supervisorCompanyId = rec.supervisorCompanyId;
    this._userInfo.next(this.userInfo);

    if (rec.loginType?.toLowerCase() == 'supervisor' || rec.supervisorSysId > 0) {
      let sup: WebSupervisorModel = {
        sysId: rec.supervisorSysId,
        companyId: rec.supervisorCompanyId,
        supervisorName: rec.supervisorName,
        email: rec.supervisorEmail,
        allowTimeModify: rec.allowTimeModify,
        allowSubmitOnBehalf: rec.allowSubmitOnBehalf,
        masterSupervisor: rec.masterSupervisor,
        notes: '',
        loginname: rec.linkedWebLogin.length ? rec.linkedWebLogin : rec.loginName,
      };
      // console.log(sup);
      this.setSuperVisor(sup);
    }

    return this.userInfo;
  }

  setSuperVisor(supervisor: WebSupervisorModel) {
    this.supervisorSubject.next(supervisor);
  }

  checkIfManager(username: string) {
    // const headers = new HttpHeaders().set('content-type', 'application/json').set('x-api-key', environment.apikey);
    return this.http
      .post(environment.serverUrl + 'users/isManager', {
        username,
      })
      .pipe(
        // tap(() => console.log("HTTP request executed")),
        map((res) => res),
        shareReplay()
      );
  }

  getMasterSupervisors(companyId: string): Observable<any> {
    return this.http.post(environment.serverUrl + 'users/supervisors/get-master', {
      companyId,
    });
  }

  async presentYearEndW2ValidationAlert() { //RSL-45
    // This message will be displayed to all employees in December and January.
    const today = new Date(); // Get the current date. RSL-45
    var currentMonth = today.getMonth(); // Get the current month (zero-indexed, so December is 11). RSL-45
    var year = today.getFullYear();
    if ( currentMonth === 0 ) { //January
      year = year - 1;
    }
    const alert = await this.alertController.create({
      header: `An Important Message Regarding Your ${year} W-2`,
      message: `
      <p>
        To ensure your ${year} W-2 has the correct information, please contact your staffing agency if your
        <strong>Name, Address, Social Security Number, or Tax State</strong>
         need to be updated.
      </p>
      `,
      backdropDismiss: false,
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          cssClass: 'primary',
        }
      ],
    });

    await alert.present();
  }

  checkW2Paperless() {
    if (this.userInfo.loginType?.toLowerCase() == 'supervisor') return;

    this.http
      .post(environment.serverUrl + 'users/settings/paperless/w2/check', { employeeSysId: this.userInfo.eeSysId })
      .subscribe({
        next: (data: any) => {
          if (!data) {
            this.presentW2PaperlessAlert();
          }
        },
        error: (err) => {},
      });
  }

  async presentW2PaperlessAlert() {
    const currentDate = new Date();
    const paperlessYear = currentDate.getFullYear() - 1;
    const currentYear = currentDate.getFullYear();

    const formattedDate = new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    }).format(new Date(currentYear, 0, 31));

    const alert = await this.alertController.create({
      header: `W-2 Paperless Enrollment`,
      message: `
      <p>
        Your ${paperlessYear} W-2 Statement will be available on the Employee Portal by ${formattedDate}.
      </p>
      <p>
        Choose paperless to <span class="text-underline">no longer</span> receive your W-2 in the mail.
        Regardless of your selection, your W-2 will always be available on the Employee Portal.
      </p>
      `,
      backdropDismiss: false,
      buttons: [
        {
          text: `Continue to Receive W-2s by Mail`,
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            // Handle the opt-out action
            this.w2PaperlessSave(false);
          },
        },
        {
          text: 'Go Paperless',
          cssClass: 'primary',
          handler: () => {
            // Handle the opt-out action
            this.presentW2PaperlessConfirm();
          },
        },
      ],
    });

    await alert.present();
  }

  w2PaperlessSave(paperless: boolean) {
    this.http
      .post(environment.serverUrl + 'users/settings/paperless/w2/save', {
        employee: {
          employeeId: this.userInfo.employeeId,
          employeeSysId: this.userInfo.eeSysId,
          paperless,
          firstName: this.userInfo.firstName,
          lastName: this.userInfo.lastName,
        },
      })
      .subscribe({
        next: (data: any) => {
          if (!data.paperless) {
            this.presentPaperlessW2AlertSaved(paperless);
          }
        },
        error: (err) => {},
      });
  }

  async presentW2PaperlessConfirm() {
    // const paperlessYear = new Date().getFullYear() - 1;
    const alert = await this.alertController.create({
      header: `W-2 Paperless Enrollment`,
      message: `
        You have elected to go paperless for your W-2 statements and will no longer receive them by mail.
        <br/>
        <br/>
        Are you sure you would like to continue?`,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.presentW2PaperlessAlert();
          },
        },
        {
          text: 'Yes, Go Paperless',
          cssClass: 'primary',
          handler: () => {
            // Handle the opt-out action
            this.w2PaperlessSave(true);
          },
        },
      ],
    });

    await alert.present();
  }

  async presentPaperlessW2AlertSaved(paperless: boolean) {
    // const paperlessYear = new Date().getFullYear() - 1;
    let message = paperless
      ? `Thank you for going paperless!`
      : `You have elected not to go paperless for your W-2 statements. You will continue to receive your W-2s by mail in addition to having access through the Employee Portal.
        <br/>
        <br/>
        Please verify that your address is correct in your Profile.
        `;

    const alert = await this.alertController.create({
      header: 'Paperless Preference Saved',
      message: message,
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          cssClass: 'primary',
        },
      ],
    });

    await alert.present();
  }

  logoff() {
    // TODO set all userInfo to blank.
    location.reload();
  }

  logLogin(ipAddress: string, deviceInfo: {}) {
    // TODO Update sessionToken and lastLogin here.
    this.userInfo.loginOk = true;
    // const headers = new HttpHeaders().set('content-type', 'application/json').set('x-api-key', environment.apikey);
    // const token = this.userInfo.token;

    try {
      return this.http.post<any>(
        environment.serverUrl + 'users/logLogin',
        {
          loginname: this.userInfo.username,
          deviceInfo,
          xResolution: window.innerWidth,
          yResolution: window.innerHeight,
          ipAddress,
          // token,
        }
        // { headers }
      );
    } catch (err) {}
  }

  logLogout(): void {
    let logSysId = this.getLogSysId();
    if (logSysId > 0) {
      this.http.post<any>(environment.serverUrl + 'auth/logout', {
        logSysId,
      });
    }
  }

  clearUserInfo() {
    this.userInfo = {} as UserInfoModel;
  }

  setPreferences(item: string, value: any) {
    localStorage.setItem(item, JSON.stringify(value));
  }

  getPreferences(item: string) {
    const pref = localStorage.getItem(item);
    if (pref) {
      return JSON.parse(pref);
    }

    return;
  }
}
