import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// import { environment } from '@env/environment';
// import { Logger } from '../logger.service';
import { AuthenticationService } from '@app/auth/authentication.service';
import { EmailService } from '@app/@services/email.service';
import { CredentialsService } from '@app/auth/credentials.service';
import { AlertController } from '@ionic/angular';
import { VersionCheckService } from '@app/@services/version-check.service';

// const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private versionCheckService: VersionCheckService
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if ([401, 403].includes(err.status) && this.credentialsService.isAuthenticated()) {
          // auto logout if 401 or 403 response returned from api
          this.authenticationService.logout();
          if (err.error.expiredToken) {
            this.authenticationService.alertSessionExpired();
          }
        }
        // if ([400].includes(err.status) && err.error.message === 'VersionMismatch') {
        //   this.versionCheckService.openNewVersionBanner();
        // }
        if (err.status === 500) {
          // this.emailService.backendErrorEmail(err.error.subject, err.error).subscribe();
        }

        const error = (err && err.error && err.error.message) || err.statusText;
        // console.log(error);
        return throwError(() => {
          const e: any = new Error(error);
          // custom errors to pass to the subscribed call
          e.errorCode = err?.error?.errorCode || null;

          // console.log(e);
          return e;
        });
      })
    );
  }
}
