import { IonicModule } from '@ionic/angular';
// import { SharedModule } from '@app/@shared';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ShellComponent } from './shell.component';
import { CommonModule } from '@angular/common';

// import { EmployeesModule } from '@app/@components/employees/employees.module';
// import { ExpandableModule } from '@app/@components/expandable/expandable.module';
// import { FormsModule } from '@angular/forms';

// import { TranslateModule } from '@ngx-translate/core';

// import { I18nModule } from '@app/i18n';
// import { Market } from '@awesome-cordova-plugins/market';
// import { FirebaseAnalytics } from '@awesome-cordova-plugins/firebase-analytics';

@NgModule({
  declarations: [ShellComponent],
  imports: [
    // SharedModule,
    CommonModule,
    IonicModule,
    // TranslateModule,
    // FormsModule,
    // I18nModule,
    RouterModule,
  ],
})
export class ShellModule {}
