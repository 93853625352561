import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { LoaderComponent } from './loader/loader.component';
import { TitlebarComponent } from './titlebar/titlebar.component';
import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { AlertComponent } from './alert/alert.component';
import { PrivacyModeComponent } from '../@components/privacy-mode/privacy-mode.component';
@NgModule({
  declarations: [LoaderComponent, TitlebarComponent, PagetitleComponent, AlertComponent],
  exports: [IonicModule, CommonModule, LoaderComponent, TitlebarComponent, PagetitleComponent],
  imports: [IonicModule, CommonModule, PrivacyModeComponent],
})
export class SharedModule {}
