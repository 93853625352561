<ion-header>
  <ion-toolbar color="primary">
    <ion-item color="primary">
      <ion-icon slot="end" name="close-circle-outline" (click)="dismissModal()"></ion-icon>
      <ion-label>Contact Us</ion-label>
    </ion-item>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="text-center" *ngIf="emailStatus">
    <h4>Email Sent</h4>
    <p>We appreciate you contacting us. We will get back in touch with you soon! Have a great day!</p>
  </div>

  <div *ngIf="!emailStatus" class="contact" style="text-align: justify">
    <div class="ion-padding">
      <form [formGroup]="messageForm" (ngSubmit)="onSubmit()">
        <ion-grid>
          <ion-row>
            <ion-col size="6" size-lg size="12" size-md>
              <ion-select
                label=" What can we help with? *"
                labelPlacement="floating"
                fill="outline"
                (ionChange)="onHelpChange()"
                formControlName="help"
                interface="popover"
                aria-label="Subject"
              >
                <ion-select-option [value]="help" *ngFor="let help of helpWith"> {{ help }} </ion-select-option>
              </ion-select>
              <div *ngFor="let error of errorMessages.help">
                <ng-container *ngIf="help.hasError(error.type) && (help.dirty || help.touched)">
                  <small class="error-message">{{ error.message }}</small>
                </ng-container>
              </div>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-input
                label="Company"
                labelPlacement="floating"
                fill="outline"
                inputmode="text"
                formControlName="company"
                aria-label="Company"
                autocapitalize
                [readonly]="loggedIn"
                [value]="userInfo.companyName"
              ></ion-input>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6" size-lg size="12" size-md>
              <ion-input
                label="First Name *"
                labelPlacement="floating"
                type="text"
                inputmode="text"
                fill="outline"
                autocapitalize="words"
                formControlName="firstName"
                aria-label="First Name"
              ></ion-input>
              <div *ngFor="let error of errorMessages.firstName">
                <ng-container *ngIf="firstName.hasError(error.type) && (firstName.dirty || firstName.touched)">
                  <small class="error-message">{{ error.message }}</small>
                </ng-container>
              </div>
            </ion-col>
            <ion-col size="6" size-lg size="12" size-md>
              <ion-input
                label="Last Name *"
                labelPlacement="floating"
                type="text"
                inputmode="text"
                fill="outline"
                autocapitalize="words"
                formControlName="lastName"
                aria-label="Last Name"
              >
              </ion-input>
              <div *ngFor="let error of errorMessages.lastName">
                <ng-container *ngIf="lastName.hasError(error.type) && (lastName.dirty || lastName.touched)">
                  <small class="error-message">{{ error.message }}</small>
                </ng-container>
              </div>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col size="6" size-lg size="12" size-md>
              <ion-input
                label="Email *"
                labelPlacement="floating"
                fill="outline"
                inputmode="email"
                formControlName="email"
                aria-label="email"
              ></ion-input>
              <div *ngFor="let error of errorMessages.email">
                <ng-container *ngIf="email.hasError(error.type) && (email.dirty || email.touched)">
                  <small class="error-message">{{ error.message }}</small>
                </ng-container>
              </div>
            </ion-col>
            <ion-col size="6" size-lg size="12" size-md>
              <ion-input
                label="Phone *"
                labelPlacement="floating"
                type="text"
                inputmode="numeric"
                fill="outline"
                formControlName="phone"
                mask="(000) 000-0000"
                aria-label="phone"
              >
              </ion-input>
              <div *ngFor="let error of errorMessages.phone">
                <ng-container *ngIf="phone.hasError(error.type) && (phone.dirty || phone.touched)">
                  <small class="error-message">{{ error.message }}</small>
                </ng-container>
              </div>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-textarea
                label="Message *"
                labelPlacement="floating"
                fill="outline"
                autoGrow="true"
                inputmode="textbox"
                formControlName="message"
                aria-label="message"
              >
              </ion-textarea>
              <div class="textarea-assistive-text">
                <span *ngFor="let error of errorMessages.message" class="textarea-assistive-text--left">
                  <ng-container *ngIf="message.hasError(error.type) && (message.dirty || message.touched)">
                    <small class="error-message">{{ error.message }}</small>
                  </ng-container>
                </span>
                <span class="textarea-assistive-text--right">
                  <small>{{ message?.value?.length || 0 }}/2048</small>
                </span>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
    </div>
  </div>
</ion-content>
<ion-footer>
  <ion-button *ngIf="!emailStatus" [disabled]="!messageForm.valid" expand="full" (click)="onSubmit()">
    Send<span *ngIf="isLoading">ing</span>
    <ion-spinner slot="start" *ngIf="isLoading"></ion-spinner>
    <ion-icon slot="start" *ngIf="!isLoading" name="mail"></ion-icon>
    <ion-ripple-effect></ion-ripple-effect>
  </ion-button>
  <ion-button *ngIf="emailStatus" expand="full" (click)="dismissModal()">
    Close
    <ion-ripple-effect></ion-ripple-effect>
  </ion-button>
</ion-footer>
