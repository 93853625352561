import { EmailService } from './../../@services/email.service';
import { UserInfoService } from '@app/@services/user-info.service';
import { CredentialsService } from '@app/auth/credentials.service';
import { ModalController, IonicModule } from '@ionic/angular';
import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormErrorsModule } from '../form-errors/form-errors.module';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    FormErrorsModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [provideNgxMask()],
})
export class ContactUsComponent implements OnInit, OnDestroy {
  isLoading = false;
  error: string;
  messageForm = this.fb.group({
    company: new FormControl(''),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]),
    phone: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$/)]),
    help: new FormControl('', Validators.required),
    message: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(2048)]),
  });
  emailStatus = false;
  userInfo: any;
  loggedIn = this.credentialService.credentials !== null;

  // Changes in area information. Pay, W-2, Time Off, Timkeeping, etc.
  public changes = [
    { area: 'Payroll', count: 2, change: 'New Checks' },
    { area: 'W-2', count: 1, change: 'New W-2' },
    { area: 'Time Off', count: 1, change: 'Time off increase' },
    { area: 'Time Off', count: 1, change: 'Time off decrease' },
    { area: 'Timekeeping', count: 1, change: 'Time sheet approved!' },
  ];

  subject = '';

  public helpWithSignIn = ['Sign In Issue'];
  public helpWith = [
    // { display: 'Gale App Support', value: 'gale' },
    'Technical Support',
    'Payroll Support',
    'HR Support',
    'General',
  ];

  public errorMessages = {
    firstName: [{ type: 'required', message: 'First Name is required' }],
    lastName: [{ type: 'required', message: 'Last Name is required' }],
    email: [
      { type: 'required', message: 'Email is required' },
      { type: 'minlength', message: 'Email is not valid' },
      { type: 'maxlength', message: 'Email cannot exceed 150 characters' },
      { type: 'pattern', message: 'Please enter a valid email address' },
    ],
    phone: [{ type: 'required', message: 'Email is required' }],
    help: [
      {
        type: 'required',
        message: 'Please make a selection for what you need help with',
      },
    ],
    message: [
      { type: 'required', message: 'A message is required' },
      { type: 'minlength', message: 'Please include a message' },
      { type: 'maxlength', message: 'Message cannot exceed 2048 characters' },
    ],
  };
  constructor(
    private modalController: ModalController,
    private userInfoService: UserInfoService,
    private credentialService: CredentialsService,
    private emailService: EmailService,
    private fb: FormBuilder
  ) {
    this.userInfo = this.userInfoService.userInfo;
  }

  ngOnInit(): void {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, null);
    this.setFormValues();

    if (!this.loggedIn) {
      this.helpWith = this.helpWithSignIn;
      this.help.disable();
    }

    this.help.setValue(this.subject);
  }

  ngOnDestroy(): void {
    if (window.history.state.modal) {
      history.back();
    }
  }

  get company() {
    return this.messageForm.get('company');
  }
  get firstName() {
    return this.messageForm.get('firstName');
  }
  get lastName() {
    return this.messageForm.get('lastName');
  }
  get email() {
    return this.messageForm.get('email');
  }
  get phone() {
    return this.messageForm.get('phone');
  }
  get help() {
    return this.messageForm.get('help');
  }
  get message() {
    return this.messageForm.get('message');
  }

  onHelpChange() {
    this.messageForm.patchValue({
      help: this.messageForm.controls.help.value,
    });
    // console.log('help: ' + this.help.value);
  }

  onSubmit() {
    this.isLoading = true;

    // console.warn(this.messageForm.value); // Gets all values defined for the form in an array.
    if (this.messageForm.invalid) {
      return;
    }

    // this.messageForm.patchValue({
    //   firstName: this.messageForm.controls.firstName.value,
    //   lastName: this.messageForm.controls.lastName.value,
    //   email: this.messageForm.controls.email.value,
    //   help: this.messageForm.controls.help.value,
    //   message: this.messageForm.controls.message.value,
    // });

    let employee = this.messageForm.controls.firstName.value + ' ' + this.messageForm.controls.lastName.value;
    this.emailService
      .contactUs(this.email.value, this.message.value, this.help.value, employee, this.phone.value, this.company.value)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (status) => {
          this.emailStatus = true;
        },
        error: (error) => {
          // console.log(error);
          this.error = error.message;
        },
      });
  }

  setFormValues() {
    this.messageForm.patchValue({
      // Update the local variables
      company: this.userInfo.companyName,
      firstName: this.userInfo.firstName,
      lastName: this.userInfo.lastName,
      email: this.userInfo.email,
      help: this.subject,
      message: '',
    });
  }

  @HostListener('window:popstate', ['$event'])
  dismissModal() {
    this.modalController.dismiss();
  }
}
