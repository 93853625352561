import { Component, Input, OnInit } from '@angular/core';
import { HelperService } from '@app/@services/helper.service';

@Component({
  selector: 'app-year-select',
  templateUrl: './year-select.component.html',
  styleUrls: ['./year-select.component.scss'],
})
export class YearSelectComponent implements OnInit {
  @Input() color: string;
  @Input() itemColors?: string = 'contrast';
  @Input() displayLabel?: boolean = false;
  showNumbers: boolean;
  currentYear: number;
  years: any = [];
  selectedYear: string;
  constructor(public helperService: HelperService) {}

  ngOnInit(): void {
    this.currentYear = this.helperService.getCurrentYear();
    this.selectedYear = this.helperService.getDefaultYear().toString();
    this.makeYears();
    this.helperService.castActiveYear.subscribe((y) => (this.selectedYear = y.toString()));
  }

  makeYears() {
    var z = 0;
    for (var i = this.currentYear + 1; i >= this.currentYear - 5; i--, z++) {
      this.years.push(i + ''); // Convert it to string
    }
    this.selectedYear = this.helperService.getDefaultYear().toString();
  }

  yearChange(year: string) {
    this.selectedYear = year;
    this.helperService.changeActiveYear(Number(this.selectedYear));
    // console.warn('selectedYear: ' + this.selectedYear);
  }
}
