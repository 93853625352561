import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { finalize, map, shareReplay, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { PtoRequestModel } from '@app/@models/pto-request.model';
import { UserInfoService } from './user-info.service';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  constructor(
    private http: HttpClient,
    private userInfoService: UserInfoService,
    private helperService: HelperService
  ) {}

  /**
   *
   * @param ptoRequest Reason for time off request
   */
  requestTimeOff(ptoRequest: PtoRequestModel) {
    let empName = `${this.userInfoService.userInfo.firstName} ${this.userInfoService.userInfo.lastName}`;

    let subject = `New Time Off Request From ${empName}`;

    let datesString = 'on ' + this.helperService.getStringToFormatDate(ptoRequest.beginDate, 'PPPP', true);
    if (ptoRequest.beginDate != ptoRequest.endDate) {
      datesString += ' through ' + this.helperService.getStringToFormatDate(ptoRequest.endDate, 'PPPP', true);
    }
    let message = `${empName} has requested time of for ${ptoRequest.hoursRequested} hour(s) ${datesString} with ${ptoRequest.ptoPolicyName}`;

    try {
      this.http
        .post(environment.serverUrl + 'pushNotification/addTimeoffRequest', {
          requestId: ptoRequest.requestId,
          subject,
          message,
        })
        .subscribe(
          (res) => console.log('HTTP response', res),
          (err) => console.log('HTTP Error', err),
          () => console.log('HTTP request completed.')
        );
    } catch (err) {
      console.log('Push Notification requestTimeOff', err);
    }
  }

  /**
   *
   * @param ptoRequest Reason for time off request
   */
  timeoffRequestStatus(ptoRequest: PtoRequestModel) {
    let empName = `${this.userInfoService.userInfo.firstName} ${this.userInfoService.userInfo.lastName}`;
    let subject = '';
    let message = '';

    let datesString = 'on ' + this.helperService.getStringToFormatDate(ptoRequest.beginDate, 'PPPP', true);
    if (ptoRequest.beginDate != ptoRequest.endDate) {
      datesString += ' through ' + this.helperService.getStringToFormatDate(ptoRequest.endDate, 'PPPP', true);
    }
    message = `Your requested time off for ${ptoRequest.hoursRequested} hour(s) ${datesString} with ${ptoRequest.ptoPolicyName} `;
    if (ptoRequest.status == 'A') {
      subject = 'Approved Time Off Request';
      message += `approved.`;
    } else if (ptoRequest.status == 'R') {
      subject = 'Declined Time Off';
      message += `declined.`;
    }

    try {
      this.http
        .post(environment.serverUrl + 'pushNotification/timeoffRequestStatus', {
          requestId: ptoRequest.requestId,
          subject,
          message,
        })
        .subscribe(
          (res) => console.log('HTTP response', res),
          (err) => console.log('HTTP Error', err),
          () => console.log('HTTP request completed.')
        );
    } catch (err) {
      console.log('Push Notification requestTimeOff', err);
    }
  }

  /**
   *
   * @param employeeId the employee Id for the timesheet
   * @param companyId Company Id of the employee
   */
  timeclockAction(employeeId: number, companyId: string, subject: string, message: string) {
    try {
      this.http
        .post(environment.serverUrl + 'pushNotification/timeclockAction', {
          employeeId,
          companyId,
          subject,
          message,
        })
        .subscribe(
          (res) => console.log('HTTP response', res),
          (err) => console.log('HTTP Error', err),
          () => console.log('HTTP request completed.')
        );
    } catch (err) {
      console.log('Push Notification submit timesheet', err);
    }
  }

  /**
   *
   * @param companyId the companyId of the user submitting their timesheet
   * @param jobId jobId of the timesheet being submitted
   */
  submitWeekNotification(companyId: string, jobId: number, subject: string, action: string) {
    let empName = `${this.userInfoService.userInfo.firstName} ${this.userInfoService.userInfo.lastName}`;
    let message = `${empName} has submitted their timesheet(s)`;

    // try {
    return this.http.post(environment.serverUrl + 'pushNotification/submitWeekNotification', {
      companyId,
      jobId,
      subject,
      message,
      //})
      //     .subscribe(
      //       (res) => console.log('HTTP response', res),
      //       (err) => console.log('HTTP Error', err),
      //       () => console.log('HTTP request completed.')
      //     );
      // } catch (err) {
      //   console.log('Push Notification submit timesheet', err);
      // }
    });
  }
}
