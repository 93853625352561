<ion-header *ngIf="isModal">
  <ion-toolbar color="primary">
    <ion-item color="primary">
      <ion-icon class="cursor-pointer" slot="end" name="close-circle-outline" (click)="dismissModal()"></ion-icon>
      <ion-label>{{ title }}</ion-label>
    </ion-item>
  </ion-toolbar>
</ion-header>

@if (!managerView){
<ion-toolbar color="light" slot="start">
  <ion-grid>
    <ion-row class="sticky year-select" style="z-index: 20">
      <ion-col size="12">
        <app-year-select></app-year-select>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-toolbar>
}

<div class="main-section__content full-screen">
  <div class="table-container">
    <div class="table-controls">
      <div class="table-controls__search">
        <mat-form-field rs-size="medium">
          <mat-label>Search</mat-label>
          <mat-icon matPrefix>search</mat-icon>
          <input matInput [(ngModel)]="searchText" (keyup)="applyFilter()" />
          <button
            mat-icon-button
            matSuffix
            type="button"
            class="clear"
            [ngClass]="{ visible: searchText.length > 0 }"
            (click)="clearSearch()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="table-controls__actions">
        <div class="table-controls__actions--left">
          @if (managerView && isManager) { @if (!allowApprove) {
          <div matTooltip="Please select a document(s) to email" class="d-flex g-0_5 cursor-not-allowed">
            <button
              mat-button
              rs-size="medium"
              rs-emphasis="subtle"
              rs-type="leading-icon"
              rs-action="secondary"
              disabled
            >
              <mat-icon>thumb_up</mat-icon>
              Approve
              <span class="badge badge-general">0</span>
            </button>
          </div>
          } @else {
          <button
            mat-button
            rs-size="medium"
            rs-emphasis="subtle"
            rs-type="leading-icon"
            rs-action="secondary"
            (click)="approveRejectAll(true)"
          >
            <mat-icon>thumb_up</mat-icon>
            Approve
            <span class="badge badge-success">{{ this.selection.selected.length }}</span>
          </button>
          } @if (!allowDecline) {
          <div matTooltip="Please select a document(s) to email" class="d-flex g-0_5 cursor-not-allowed">
            <button
              mat-button
              rs-size="medium"
              rs-emphasis="subtle"
              rs-type="leading-icon"
              rs-action="secondary"
              disabled
            >
              <mat-icon>thumb_down</mat-icon>
              Decline
              <span class="badge badge-general">0</span>
            </button>
          </div>
          } @else {
          <button
            mat-button
            rs-size="medium"
            rs-emphasis="subtle"
            rs-type="leading-icon"
            rs-action="secondary"
            (click)="approveRejectAll(false)"
          >
            <mat-icon>thumb_down</mat-icon>
            Decline
            <span class="badge badge-danger">{{ this.selection.selected.length }}</span>
          </button>
          } }
        </div>
        <div class="table-controls__actions--right">
          @if (managerView && isManager) {
          <div class="input-group --has-input-group-button-end">
            <mat-form-field rs-size="medium">
              <mat-label>Time Off Requests Since</mat-label>
              <input type="text" matInput readonly value="{{ ptoMonthPrior | date }}" />
            </mat-form-field>
            <button
              mat-button
              rs-size="medium"
              rs-emphasis="subtle"
              rs-type="leading-icon"
              rs-action="secondary"
              class="input-group-button-end"
              [matMenuTriggerFor]="dateRange"
            >
              Change
            </button>
            <mat-menu #dateRange="matMenu">
              <button mat-menu-item (click)="changeMonthsBack(1)">1 Month</button>
              <button mat-menu-item (click)="changeMonthsBack(3)">3 Months</button>
              <button mat-menu-item (click)="changeMonthsBack(6)">6 Months</button>
              <button mat-menu-item (click)="changeMonthsBack(9)">9 Months</button>
              <button mat-menu-item (click)="changeMonthsBack(12)">1 Year</button>
            </mat-menu>
          </div>
          }
          <button
            mat-button
            rs-size="medium"
            rs-emphasis="subtle"
            rs-type="leading-icon"
            rs-action="secondary"
            [matMenuTriggerFor]="sortActions"
          >
            <mat-icon>sort</mat-icon>
            Sort
          </button>
          <mat-menu #sortActions="matMenu">
            @for (option of sortOptions; track $index) {
            <button
              mat-menu-item
              (click)="sortData(option.name); $event.stopPropagation()"
              [ngClass]="{ active: activeSort === option.name }"
            >
              {{ option.label }}
              @if (activeSortDirection === 'asc') {
              <mat-icon [ngStyle]="{ visibility: activeSort === option.name ? 'visible' : 'hidden' }">
                arrow_upward
              </mat-icon>
              } @else {
              <mat-icon [ngStyle]="{ visibility: activeSort === option.name ? 'visible' : 'hidden' }">
                arrow_downward
              </mat-icon>
              }
            </button>
            }
          </mat-menu>
          <button
            mat-button
            rs-size="medium"
            rs-emphasis="subtle"
            rs-type="leading-icon"
            rs-action="secondary"
            [matMenuTriggerFor]="filterActions"
          >
            <mat-icon>filter_list</mat-icon>
            Filter
          </button>
          <mat-menu #filterActions="matMenu">
            @for (option of ptoStatus; track $index) {
            <button
              mat-menu-item
              (click)="filterStatus(option[0])"
              [ngClass]="{ active: filterStatusString === option[0] }"
            >
              @if (filterStatusString === option[0]) {
              <mat-icon>radio_button_checked</mat-icon>
              } @else {
              <mat-icon>radio_button_unchecked</mat-icon>
              }
              {{ option[1] }}
            </button>
            }
          </mat-menu>
        </div>
      </div>
    </div>

    <!-- desktop -->
    <div class="table-wrapper" [ngClass]="{ 'ion-hide-md-down': !isModal, 'ion-hide': isModal }">
      <table
        #manageEmployeeTable
        mat-table
        matSort
        [matSortDisableClear]="true"
        [dataSource]="dataSource"
        class="table border-row border-column"
      >
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select" sticky>
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              rs-size="medium"
              rs-type="no-label"
              *ngIf="managerView"
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              rs-size="medium"
              rs-type="no-label"
              *ngIf="managerView"
              (click)="$event.stopPropagation()"
              (change)="$event ? handleRowSelection(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let r">
            <app-name-and-id nameValue="{{ r.lastName }}, {{ r.firstName }}" [idValue]="r.employeeId"></app-name-and-id>
            <!-- <ion-icon (click)="emailEmployee(r)" color="secondary" name="mail"></ion-icon> -->
          </td>
        </ng-container>

        <!-- policyName Column -->
        <ng-container matColumnDef="policyName">
          <th mat-header-cell *matHeaderCellDef>Policy</th>
          <td mat-cell *matCellDef="let r">{{ r.ptoPolicyName }}</td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td
            mat-cell
            *matCellDef="let r"
            [ngClass]="{
              'status--pending': r.status.toLowerCase() === 'p',
              'status--approved': r.status.toLowerCase() === 'a',
              'status--declined': r.status.toLowerCase() === 'r'
            }"
          >
            {{ convertStatus(r) }}
            <!-- <span class="pill {{ convertStatus(r, true) }}">{{ convertStatus(r) }}</span> -->
          </td>
        </ng-container>

        <!-- Hours Column -->
        <ng-container matColumnDef="hours">
          <th mat-header-cell *matHeaderCellDef>Hours</th>
          <td mat-cell *matCellDef="let r">{{ r.hoursRequested }}</td>
        </ng-container>

        <!-- Dates Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Date(s)</th>
          <td mat-cell *matCellDef="let r">
            <span *ngIf="r.beginDate == r.endDate">{{ r.beginDate | date : "MM/dd/yyyy" }}</span>
            <span *ngIf="r.beginDate != r.endDate">
              {{ r.beginDate | date : "MM/dd/yyyy" }} - {{ r.endDate | date : "MM/dd/yyyy" }}
            </span>
          </td>
        </ng-container>

        <!-- Reason Column -->
        <ng-container matColumnDef="reason">
          <th mat-header-cell *matHeaderCellDef>Reason</th>
          <td mat-cell *matCellDef="let r" (click)="$event.stopPropagation()">
            @if (r.reasonRequested.length > 0) {
            <span class="text-button" id="{{ r.requestId }}-table-popover-trigger">View</span>
            <ion-popover trigger="{{ r.requestId }}-table-popover-trigger" triggerAction="click">
              <ng-template>
                <ion-content class="ion-padding">
                  {{ r.reasonRequested }}
                </ion-content>
              </ng-template>
            </ion-popover>
            }
          </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let r">
            <div class="button-wrapper">
              <button
                mat-button
                rs-size="small"
                rs-emphasis="minimal"
                rs-type="icon-only"
                rs-action="secondary"
                [matMenuTriggerFor]="menu"
                (click)="$event.stopPropagation()"
                *ngIf="r.empHoursNumber == 0 || r.reasonRequested.length > 0"
              >
                <mat-icon>more_horiz</mat-icon>
              </button>
            </div>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="emailEmployee(r)" *ngIf="checkManagerControls(r.managerId) && managerView">
                <mat-icon>email</mat-icon>
                <span>Email</span>
              </button>
              <button mat-menu-item (click)="editPto(r)" *ngIf="r.empHoursNumber == 0">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <button
                mat-menu-item
                (click)="approveReject(r, true)"
                *ngIf="checkManagerControls(r.managerId) && r.status == 'P' && managerView"
              >
                <mat-icon>thumb_up</mat-icon>
                <span>Approve</span>
              </button>
              <button
                mat-menu-item
                (click)="approveReject(r, false)"
                *ngIf="checkManagerControls(r.managerId) && r.status == 'P' && managerView"
              >
                <mat-icon>thumb_down</mat-icon>
                <span>Decline</span>
              </button>
              <button mat-menu-item (click)="removePTORequest(r)" *ngIf="r.empHoursNumber == 0">
                <mat-icon>delete</mat-icon>
                <span>Remove</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <!-- <tr mat-header-row *matHeaderRowDef="['actionsHeader']; sticky: true"></tr> -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
          mat-row
          class="element-row"
          *matRowDef="let row; columns: displayedColumns"
          (click)="handleRowSelection(row)"
        ></tr>
        <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr> -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length">
            No data matching the filter @if (filterStatusString !== "all") { -
            <span class="text-button" (click)="filterStatus('all')">Show All Request Statuses</span>
            }
          </td>
        </tr>
      </table>
    </div>

    <div class="table-wrapper" [ngClass]="{ 'ion-hide-md-up': !isModal }">
      <!-- <div *ngIf="loading" class="loading-wrapper--mobile">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div> -->
      @if (dataSource.filteredData.length) {
      <ul class="table-list">
        <ng-container *ngFor="let r of dataSource.filteredData">
          <li class="table-list__item" (click)="handleRowSelection(r)">
            <mat-checkbox
              rs-size="medium"
              rs-type="no-label"
              *ngIf="managerView"
              (click)="$event.stopPropagation()"
              (change)="$event ? handleRowSelection(r) : null"
              [checked]="selection.isSelected(r)"
              [aria-label]="checkboxLabel(r)"
            >
            </mat-checkbox>
            <div class="item-content">
              <div class="item-content__top">
                <app-name-and-id
                  nameValue="{{ r.lastName }}, {{ r.firstName }}"
                  [idValue]="r.employeeId"
                ></app-name-and-id>
                <div
                  class="status"
                  [ngClass]="{
                    'status--pending': r.status.toLowerCase() === 'p',
                    'status--approved': r.status.toLowerCase() === 'a',
                    'status--declined': r.status.toLowerCase() === 'r'
                  }"
                >
                  {{ convertStatus(r) }}
                </div>
              </div>
              <div class="item-content__bottom">
                <div class="item-content__bottom--group">
                  <div class="hours">
                    <div class="label">Hours</div>
                    <div class="data">{{ r.hoursRequested }}</div>
                  </div>
                  <div class="dates">
                    <div class="label">Date(s)</div>
                    <div *ngIf="r.beginDate == r.endDate" class="data">
                      {{ r.beginDate | date : "MM/dd/yyyy" }}
                    </div>
                    <div *ngIf="r.beginDate != r.endDate" class="data">
                      {{ r.beginDate | date : "MM/dd/yyyy" }} - {{ r.endDate | date : "MM/dd/yyyy" }}
                    </div>
                  </div>
                </div>
                <div class="item-content__bottom--group">
                  <div class="policy-name">
                    <div class="label">Policy</div>
                    <div class="data">{{ r.ptoPolicyName ?? "-" }}</div>
                  </div>
                </div>
                @if (r.reasonRequested.length > 0) {
                <button
                  mat-button
                  rs-size="small"
                  rs-emphasis="subtle"
                  rs-type="text-only"
                  rs-action="secondary"
                  id="{{ r.requestId }}-list-popover-trigger"
                  (click)="$event.stopPropagation()"
                >
                  View Reason
                </button>
                <ion-popover trigger="{{ r.requestId }}-list-popover-trigger" triggerAction="click">
                  <ng-template>
                    <ion-content class="ion-padding">
                      {{ r.reasonRequested }}
                    </ion-content>
                  </ng-template>
                </ion-popover>
                }
              </div>
            </div>
            <div class="button-wrapper">
              <button
                mat-button
                rs-size="large"
                rs-emphasis="minimal"
                rs-type="icon-only"
                rs-action="secondary"
                [matMenuTriggerFor]="menu"
                (click)="$event.stopPropagation()"
                *ngIf="r.empHoursNumber == 0 || r.reasonRequested.length > 0"
              >
                <mat-icon>more_horiz</mat-icon>
              </button>
            </div>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="emailEmployee(r)" *ngIf="checkManagerControls(r.managerId) && managerView">
                <mat-icon>email</mat-icon>
                <span>Email</span>
              </button>
              <button mat-menu-item (click)="editPto(r)" *ngIf="r.empHoursNumber == 0">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <button
                mat-menu-item
                (click)="approveReject(r, true)"
                *ngIf="checkManagerControls(r.managerId) && r.status == 'P' && managerView"
              >
                <mat-icon>thumb_up</mat-icon>
                <span>Approve</span>
              </button>
              <button
                mat-menu-item
                (click)="approveReject(r, false)"
                *ngIf="checkManagerControls(r.managerId) && r.status == 'P' && managerView"
              >
                <mat-icon>thumb_down</mat-icon>
                <span>Decline</span>
              </button>
              <button mat-menu-item (click)="removePTORequest(r)" *ngIf="r.empHoursNumber == 0">
                <mat-icon>delete</mat-icon>
                <span>Remove</span>
              </button>
            </mat-menu>
          </li>
        </ng-container>
      </ul>
      } @else {
      <div>
        <div class="ion-padding ion-text-center">
          <p style="font-weight: 500">No data matching the filter</p>
          @if (filterStatusString !== "all") {
          <p class="text-button" style="font-weight: 500" (click)="filterStatus('all')">Show All Request Statuses</p>
          }
        </div>
      </div>
      }
    </div>
  </div>
</div>
