import { TimeoffContactInfo } from './../@models/timeoff-request.model';
import { HelperService } from '@app/@services/helper.service';
import { PtoRequestModel } from '@app/@models/pto-request.model';
import { UserInfoService } from '@app/@services/user-info.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { UserInfoModel } from '../@models/userInfo.model';
import { environment } from '@env/environment';
import { map, shareReplay, tap } from 'rxjs/operators';
import { EmployeeJobDetails, JobTimeClockLogModel, JobTimeClockModel } from '@app/@models/jobTimeClock.model';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(
    private http: HttpClient,
    private userInfoService: UserInfoService,
    private helperService: HelperService
  ) {}

  contactUs(from: string, body: string, type: string, employee: string, phone: string = '', company: string) {
    let route = '';
    let subject = 'Web Portal ' + type;
    switch (type) {
      case 'Sign In Issue':
        route = 'email/support/signin';
        break;
      default:
        route = 'email/sendEmail';
    }

    // Support Emails based on departments - RSL-93&121
   
     let to = 'support@realtimeservices.com'
     switch (type) {
       case 'Technical Support':
         to = 'support@realtimeservices.com';
         break;
       case 'HR Support':
         to = 'HR@realtimeservices.com';
         break;
       case 'Payroll Support':
         to = 'payroll@realtimeservices.com';
         break;
       case 'W-2 Issues':
         to = 'w2issues@realtimeservices.com';
         break;
      default:
         to = 'support@realtimeservices.com';
     }

    return this.http.post(environment.serverUrl + route, {
      to: to,
      subject: subject,
      message: body,
      phone,
      from: from,
      token: this.userInfoService.userInfo.token,
      company: company,
      br: this.userInfoService.userInfo.branchId,
      employee,
    });
  }

  sendEmail(
    from: string,
    to: string,
    subject: string,
    message: string,
    u: UserInfoModel,
    attachments?: Array<string>
  ): Observable<any> {
    return this.http.post(environment.serverUrl + 'email/sendEmail', {
      to,
      subject,
      message,
      from,
      companyId: u.companyId,
      employee: u.fullName,
      token: u.token,
      attachments,
    });
  }

  generalEmail(
    from: string,
    to: string,
    subject: string,
    message: string,
    u: UserInfoModel,
    cc: string[] = [],
    attachments: Array<string> = []
  ): Observable<any> {
    // console.log('SENDING EMAIL');

    return this.http.post(environment.serverUrl + 'email/generalEmail', {
      to,
      subject,
      message,
      from,
      contactName: u.fullName,
      token: u.token,
      cc,
      attachments,
    });
  }

  backendErrorEmail(subject: string, err: any) {
    // console.log('SENDING EMAIL');
    try {
      return this.http
        .post(environment.serverUrl + 'email/backendErrorEmail', {
          subject,
          message: err.message,
          query: err.query,
          params: err.params,
        })
        .pipe(
          tap(() => console.log('HTTP request executed')),
          map((res) => res),
          shareReplay()
        );
    } catch (err) {
      console.log(err);
    }
  }

  submitWeekEmail(submittedToSupervisor: boolean, from: string, to: string, u: UserInfoModel) {
    // console.log('Email services: submitWeekEmail');
    return this.http
      .post(environment.serverUrl + 'email/submitWeekEmail', {
        submittedToSupervisor,
        to,
        from,
        employee: u.fullName,
        token: u.token,
      })
      .pipe(
        tap(() => console.log('HTTP request executed')),
        map((res) => res),
        shareReplay()
      ); // shareReplay limits the calls to the server to one.
  }

  ptoRequest(ptoRequest: PtoRequestModel, contactInfo: TimeoffContactInfo) {
    console.log('contactInfo: ', contactInfo);
    console.log('Email services', ptoRequest);

    let contactName = contactInfo.managerName;
    let empName = contactInfo.empName;
    let to = contactInfo.managerEmail;
    let from = contactInfo.empEmail;
    let subject = `New Time Off Request From ${empName}`;

    let datesString = 'on ' + this.helperService.getStringToFormatDate(ptoRequest.beginDate, 'PPPP', true);
    if (ptoRequest.beginDate != ptoRequest.endDate) {
      datesString += ' through ' + this.helperService.getStringToFormatDate(ptoRequest.endDate, 'PPPP', true);
    }
    let message = `${empName} has requested time of for ${ptoRequest.hoursRequested} hour(s) ${datesString} with ${ptoRequest.ptoPolicyName}`;
    // Updated PTO Request to manager.
    if (ptoRequest.requestId > 0 && ptoRequest.employeeId > 0) {
      subject = `Updated Time Off Request From ${empName}`;
      contactName = contactInfo.managerName;
      message = `${empName} has updated their requested time off to ${ptoRequest.hoursRequested} hour(s) ${datesString} with ${ptoRequest.ptoPolicyName}`;
    }
    // Removed PTO Request. Added 09/20/2024. DE1132rea. This email is going to the manager and the employee.
    if (ptoRequest.requestId > 0 && ptoRequest.employeeId < 0) {
      subject = `Removed Time Off Request From ${empName}`;
      contactName = contactInfo.managerName;
      message = `A time off request has been removed for ${empName} for ${ptoRequest.hoursRequested} hour(s) ${datesString} with ${ptoRequest.ptoPolicyName}`;
    }

    let notes = '<strong>Notes: </strong>' + ptoRequest.reasonRequested;
    let displayButtons = 'block'; // Set to none as a default. RSL-1
    let cc = '';

    // PTO Request was changed from pending by a manager. Email to the Employee.
    if (ptoRequest.requestId > 0 && ptoRequest.employeeId > 0 && ptoRequest.status != 'P') {
      to = contactInfo.empEmail;
      from = contactInfo.managerEmail;
      cc = contactInfo.managerEmail;
      notes = '<strong>Reason: </strong>' + ptoRequest.reasonRequested;
      contactName = contactInfo.empName;
      displayButtons = 'none';
      message = `Your requested time off for ${ptoRequest.hoursRequested} hours ${datesString} with ${ptoRequest.ptoPolicyName} has been `;
      if (ptoRequest.status == 'A') {
        subject = 'Approved Time Off Request';
        message += `approved.`;
      } else if (ptoRequest.status == 'R') {
        subject = 'Declined Time Off';
        message += `declined.`;
      }
    }

    // If the employeeId is < 0, it's a removed PTO request.
    if (ptoRequest.employeeId < 0) {
      displayButtons = 'none';
    }

    try {
      this.http
        .post(environment.serverUrl + 'email/ptoEmail', {
          contactName,
          message,
          to,
          from,
          subject,
          notes,
          displayButtons,
          requestId: ptoRequest.requestId,
          token: this.userInfoService.userInfo.token,
          cc,
        })
        .subscribe({
          next: (res) => console.log('HTTP response', res),
          error: (err) => console.log('HTTP Error', err),
          complete: () => console.log('HTTP request completed.'),
        });

      // Pending status email to employee.
      if (ptoRequest.status === 'P') {
        if (ptoRequest.employeeId < 0) {
          // 'Removed' PTO Request. Added 09/20/2024. DE1132rea. Sent to employee.
          subject = `Removed Time Off Request for ${empName}`;
          message = `A time off request for ${ptoRequest.hoursRequested} hour(s) ${datesString} with ${ptoRequest.ptoPolicyName} has been removed. An email has also been sent to ${contactInfo.managerName}`;
        } else {
          subject = 'Time Off Request';
          message = `Your requested time off for ${ptoRequest.hoursRequested} hour(s) ${datesString} with ${ptoRequest.ptoPolicyName} has been sent to ${contactInfo.managerName}`;
        }
        this.http
          .post(environment.serverUrl + 'email/generalEmail', {
            to: contactInfo.empEmail,
            subject: subject,
            message,
            from: 'noreply@realtimeservices.com',
            contactName: contactInfo.empName,
            token: this.userInfoService.userInfo.token,
            attachments: '',
          })
          .subscribe({
            next: (res) => console.log('HTTP response', res),
            error: (err) => console.log('HTTP Error', err),
            complete: () => console.log('HTTP request completed.'),
          });
      }
    } catch (err) {
      console.log(err);
    }

    // console.log('Time Off email sent');
  }

  timeclockAction(
    to: string,
    contact: string,
    subject: string,
    tc: any,
    employeeEmail: string,
    expenses: any,
    sendToemployee = 1
  ): Observable<any> {
    return this.http.post(environment.serverUrl + 'email/timeclockAction', {
      to,
      contact,
      subject,
      tc,
      employeeEmail,
      expenses,
      sendToemployee,
    });
  }

  submitPunch(
    to: string,
    contact: string,
    subject: string,
    employeeEmail: string,
    timeSheet: JobTimeClockModel,
    logs: JobTimeClockLogModel,
    job: EmployeeJobDetails,
    employeeName: string,
    companyName: string,
    sysId: any
  ): Observable<any> {
    // console.log('attempting emaill')
    return this.http.post(environment.serverUrl + 'email/submitPunch', {
      to,
      contact,
      subject,
      employeeEmail,
      timeSheet,
      logs,
      job,
      employeeName,
      companyName,
      sysId,
    });
  }

  forgotPasswordEmail(to: string, contact: string, code: number) {
    try {
      // console.log('attempting emaill')
      this.http
        .post(environment.serverUrl + 'email/forgotPasswordEmail', {
          to,
          contact,
          code,
        })
        .subscribe(
          (res) => console.log('HTTP response', res),
          (err) => console.log('HTTP Error', err),
          () => console.log('HTTP request completed.')
        );
    } catch (err) {
      console.log(err);
    }
  }

  forgotPasswordText(to: string, code: number) {
    // console.log('Forogt password email service');
    try {
      // console.log('attempting emaill')
      this.http
        .post(environment.serverUrl + 'email/forgotPasswordText', {
          to,
          code,
        })
        .subscribe(
          (res) => console.log('HTTP response', res),
          (err) => console.log('HTTP Error', err),
          () => console.log('HTTP request completed.')
        );
    } catch (err) {
      console.log(err);
    }
  }

  forgotUsernameEmail(from: string, to: string, subject: string, message: string, loginname: string) {
    try {
      return this.http
        .post(environment.serverUrl + 'email/forgotUsernameEmail', {
          to,
          subject,
          message,
          from,
          loginname,
        })
        .pipe(
          tap(() => console.log('HTTP request executed')),
          map((res) => res),
          shareReplay()
        );
    } catch (err) {
      console.log(err);
    }
  }

  forgotUsernameText(to: string, loginname: string) {
    try {
      // console.log('sending text');
      this.http
        .post(environment.serverUrl + 'email/forgotUsernameText', {
          to,
          loginname,
        })
        .subscribe(
          (res) => console.log('HTTP response', res),
          (err) => console.log('HTTP Error', err),
          () => console.log('HTTP request completed.')
        );
      // console.log(to);
    } catch (err) {
      console.log(err);
    }
  }
}
