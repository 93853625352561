<form [formGroup]="myForm" (ngSubmit)="onSubmit()">
  <div class="login__form--inputs">
    <p class="info-message">Please provide the email address that is associated with your account.</p>

    <div class="error-message" *ngIf="findUserError">
      <ion-text color="danger">
        <span>Could not find an account with the information provided</span>
      </ion-text>
    </div>

    <div class="input-wrapper">
      <label for="email">Email</label>
      <input
        id="email"
        type="text"
        inputmode="text"
        autocapitalize="off"
        autocomplete="email"
        autocorrect="off"
        aria-label="Email"
        formControlName="email"
      />
      <div class="assistive-text">
        <app-form-errors class="assistive-text--left" [errors]="errorMessages.email" [item]="email"></app-form-errors>
      </div>
    </div>

    <div class="input-wrapper">
      <label for="ssn">Last 4 of SSN</label>
      <input
        id="ssn"
        type="password"
        inputmode="numeric"
        aria-label="Last 4 of SSN"
        name="ssn"
        formControlName="ssn"
        maxlength="4"
      />
      <div class="assistive-text">
        <app-form-errors class="assistive-text--left" [errors]="errorMessages.ssn" [item]="ssn"></app-form-errors>
      </div>
    </div>
  </div>

  <div class="login__form--actions">
    <ion-button [disabled]="!myForm.valid" type="submit" color="primary" size="medium" expand="block">
      Send Username
    </ion-button>
    <ion-button class="back" size="small" fill="clear" color="dark" (click)="goBack()">Back to login</ion-button>
  </div>
</form>
