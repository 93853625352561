import { EmailService } from './../../@services/email.service';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { UserInfoService } from '@app/@services/user-info.service';
import { ModalController } from '@ionic/angular';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent implements OnInit, OnDestroy {
  @Input() to: Array<any>;
  @Input() from: string;
  @Input() subject: string;
  @Input() subjectOptions: string[] = [];
  @Input() cc: string[] = [];

  myForm: FormGroup;
  isSubmitting = false;
  otherSubjecRequired = false;
  contactName = '';

  errorMessages = {
    emailTo: [{ type: 'required', message: 'To is required' }],
    otherSubject: [{ type: 'required', message: 'To is required' }],
    message: [
      { type: 'required', message: 'Message is required' },
      { type: 'maxlength', message: 'Message cannot exceed 560 characters' },
      { type: 'minlength', message: 'Message must be more than 5 characters' },
    ],
  };

  constructor(
    private userInfoService: UserInfoService,
    private emailService: EmailService,
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    history.pushState(
      {
        modal: true,
        desc: 'fake state for our modal',
      },
      null
    );

    // Subscribe to router events
    this.router.events.subscribe((event) => {
      // Check if the route is changing
      if (event instanceof NavigationStart) {
        // Close the modal when the route changes
        this.modalController.dismiss();
      }
    });

    if (this.to.length === 1) {
      this.contactName = this.to[0].display;
      // console.log(this.to);
      this.myForm.patchValue({
        // Update the local variables
        emailTo: this.to[0].value.toString(),
      });
      this.emailTo.markAsTouched();
    }

    if (this.subjectOptions.length === 0) {
      this.subjectOptions.push(this.subject);
    }

    this.myForm.patchValue({
      // Update the local variables
      message: '',
    });
  }

  ngOnDestroy(): void {
    if (window.history.state.modal) {
      this.dismissModal();
      history.back();
    }
  }

  createForm() {
    this.myForm = this.formBuilder.group({
      emailTo: [{ value: '' }, [Validators.required]],
      subjectOptions: [{ value: '' }, [Validators.required]],
      otherSubject: [''],
      message: ['', [Validators.required, Validators.maxLength(560), Validators.minLength(5)]],
    });
  }

  get emailTo() {
    return this.myForm.get('emailTo');
  }

  get message() {
    return this.myForm.get('message');
  }

  get selectedSubject() {
    return this.myForm.get('subjectOptions');
  }

  get otherSubject() {
    return this.myForm.get('otherSubject');
  }

  onOptionChange() {
    const selectedOptionValue = (this.selectedSubject.value || '').toLowerCase();
    this.otherSubject.setValue('');
    if (selectedOptionValue === 'other') {
      this.otherSubject.setValidators([Validators.required, Validators.maxLength(80), Validators.minLength(5)]);
      this.otherSubjecRequired = true;
    } else {
      this.otherSubject.clearValidators();
      this.otherSubjecRequired = false;
    }

    this.otherSubject.updateValueAndValidity();
  }

  updateContact() {
    // console.log(this.myForm.controls['emailTo']);
    // console.log(this.to);
    // console.log(this.myForm.controls['emailTo'].value.join(';'));
    let found = this.to.find((obj) => obj.value == this.myForm.controls['emailTo'].value);
    if (found && found.length == 1) {
      this.contactName = found.display;
    }
  }

  onSubmit() {
    // console.log(this.myForm.valid);
    // console.log(this.myForm);
    if (this.myForm.valid) {
      this.isSubmitting = true;
      // console.log(this.myForm.controls['emailTo'].value);
      let to;
      if (Array.isArray(this.myForm.controls['emailTo'].value)) {
        to = this.myForm.controls['emailTo'].value.join(',');
      } else {
        to = this.myForm.controls['emailTo'].value;
      }

      let subject = this.otherSubject.value.length ? this.otherSubject.value : this.selectedSubject.value;
      this.emailService
        .generalEmail(this.from, to, subject, this.message.value, this.userInfoService.userInfo, this.cc)
        .subscribe({
          next: (data) => {
            this.modalController.dismiss();
          },
          error: (err) => {
            this.isSubmitting = false;
          },
        });
    }
  }

  // @HostListener('window:popstate', ['$event'])
  dismissModal() {
    this.modalController.dismiss();
  }
}
