import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { VersionCheckService } from '@app/@services/version-check.service';

@Injectable()
export class LoadChunkErrorHandler implements ErrorHandler {
  constructor(private versionCheckService: VersionCheckService) {}

  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      console.error(error);

      this.versionCheckService.openNewVersionBanner();
    }
  }
}
