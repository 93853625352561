import { FormErrorsModule } from './../form-errors/form-errors.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/@shared';
import { IonicModule } from '@ionic/angular';
// import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmailComponent } from './email.component';

@NgModule({
  declarations: [EmailComponent],
  imports: [
    CommonModule,
    // TranslateModule,
    IonicModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    FormErrorsModule,
  ],
  exports: [EmailComponent],
})
export class EmailModule {}
