import { HelperService } from './../../@services/helper.service';
import { EmployeeModel } from './../../@models/employee.model';
import { AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TimeoffService } from '@app/@services/timeoff.service';
import { ModalController, IonicModule } from '@ionic/angular';
import { MatTableDataSource } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '@app/@shared';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NameAndIdComponent } from '../name-and-id/name-and-id.component';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatButtonModule,
    MatTableModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSortModule,
    NameAndIdComponent,
  ],
  providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } }],
})
export class EmployeesComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() managerId: number;
  @Input() isModal = true;

  employees: EmployeeModel[];
  searchText: string = '';
  filterValue = '';
  dataSource = new MatTableDataSource<EmployeeModel>();
  displayedColumns: string[] = ['fullName', 'policyName', 'balance', 'approvedBalance', 'pendingBalance'];
  sortOptions: { name: string; label: string }[] = [
    { name: 'lastName', label: 'Last Name' },
    { name: 'policyName', label: 'Policy Name' },
    { name: 'balance', label: 'Balance' },
    { name: 'approvedBalance', label: 'Approved Balance' },
    { name: 'pendingBalance', label: 'Pending Balance' },
  ];
  activeSort: string = 'lastName';
  activeSortDirection: string = 'asc';

  @ViewChild('employeeTable') employeeTable: any;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private timeoffService: TimeoffService,
    private modalController: ModalController,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    if (this.isModal) {
      this.isAModal();
    }

    this.timeoffService.getEmployeesTimeOff(this.managerId).subscribe((data) => {
      this.employees = data;
      this.dataSource = new MatTableDataSource(this.employees);
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.sortData('lastName');
  }

  isAModal() {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, null);
  }

  ngOnDestroy(): void {
    if (window.history.state.modal) {
      history.back();
    }
  }

  applyFilter() {
    this.dataSource.filter = this.searchText.length ? this.searchText.trim().toLowerCase() : '';

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clearSearch() {
    this.searchText = '';
    this.applyFilter();
  }

  sortData(column: string) {
    this.employeeTable.dataSource.sort = this.sort;
    let sort = this.employeeTable.dataSource.sort;
    let direction = sort.direction ? sort.direction : 'asc';

    sort.sort({
      id: column,
      direction: direction === 'asc' ? 'desc' : 'asc',
      disableClear: true,
    });

    this.dataSource.filteredData = this.dataSource.data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      this.activeSortDirection = isAsc === true ? 'asc' : 'desc';
      switch (sort.active) {
        case 'lastName':
          return this.compare(a.lastName, b.lastName, isAsc);
        case 'policyName':
          return this.compare(a.policyName, b.policyName, isAsc);
        case 'balance':
          return this.compare(a.balance, b.balance, isAsc);
        case 'approvedBalance':
          return this.compare(a.approvedBalance, b.approvedBalance, isAsc);
        case 'pendingBalance':
          return this.compare(a.pendingBalance, b.pendingBalance, isAsc);
        default:
          return 0;
      }
    });

    this.activeSort = column;
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    if (a === b) return 0;
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onSelect(employee: EmployeeModel) {
    this.modalController.dismiss({ employee });
  }

  @HostListener('window:popstate', ['$event'])
  dismissModal() {
    if (this.isModal) {
      this.modalController.dismiss();
    }
  }
}
