// import { EmptyLayoutComponent } from './@core/layout/empty-layout/empty-layout.component';
import { PrivacypolicyComponent } from './settings/privacypolicy/privacypolicy.component';
// import { NavigationGuardService } from './@services/navigation-guard.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, NoPreloading, PreloadAllModules } from '@angular/router';
import { ShellService } from '@app/shell/shell.service';
import { NavigationGuardService } from './@services/navigation-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },

  ShellService.childRoutes([
    {
      path: 'dashboard',
      loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    },
    {
      path: 'admin',
      canActivate: [NavigationGuardService],
      loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    },
    {
      path: 'payroll',
      loadChildren: () => import('./payroll/payroll.module').then((m) => m.PayrollModule),
    },
    {
      path: 'payroll/:chkNumber',
      loadChildren: () => import('./payroll/payroll.module').then((m) => m.PayrollModule),
    },
    {
      path: 'profile',
      loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
    },
    {
      path: 'tax-statements',
      loadChildren: () => import('./w2/w2.module').then((m) => m.W2Module),
    },
    {
      path: 'expenses',
      loadChildren: () => import('./timekeeping/expenses-form/expenses-form.module').then((m) => m.ExpensesFormModule),
    },
    {
      path: 'timekeeping',
      loadChildren: () => import('./timekeeping/timekeeping.module').then((m) => m.TimekeepingModule),
    },
    {
      path: 'timekeeping/:jobid',
      loadChildren: () => import('./timekeeping/timekeeping.module').then((m) => m.TimekeepingModule),
    },
    // {
    //   path: 'timekeepingform',
    //   loadChildren: () =>
    //     import('./timekeeping/timekeeping-form-old/timekeeping-form-old.module').then((m) => m.TimekeepingFormModule),
    // },
    {
      path: 'expensesform',
      loadChildren: () => import('./timekeeping/expenses-form/expenses-form.module').then((m) => m.ExpensesFormModule),
    },
    {
      path: 'timeoff',
      loadChildren: () => import('./timeoff/timeoff.module').then((m) => m.TimeoffModule),
      canActivate: [NavigationGuardService],
      canLoad: [NavigationGuardService],
    },
    // {
    //   path: 'timeoff/:page',
    //   loadChildren: () => import('./timeoff/timeoff.module').then((m) => m.TimeoffModule),
    // },
    // {
    //   path: 'timeoff/:page/:requestid/:status',
    //   loadChildren: () => import('./timeoff/timeoff.module').then((m) => m.TimeoffModule),
    // },
    {
      path: 'email',
      loadChildren: () => import('./@components/email/email.module').then((m) => m.EmailModule),
    },
    {
      path: 'accounts',
      loadChildren: () => import('./accounts/accounts.module').then((m) => m.AccountsModule),
    },
    {
      path: 'timekeeping-approvals',
      loadChildren: () => import('./supervisor/supervisor.module').then((m) => m.SupervisorModule),
    },
    // {
    //   path: 'supervisor/:sysids/:status',
    //   loadChildren: () => import('./supervisor/supervisor.module').then((m) => m.SupervisorModule),
    // },
    { path: 'calendar', loadChildren: () => import('./calendar/calendar.module').then((m) => m.CalendarModule) },
    { path: 'w4', loadChildren: () => import('./w4/w4.module').then((m) => m.W4Module) },
    { path: 'settings', loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule) },
    {
      path: 'management',
      loadChildren: () => import('./management/management.module').then((m) => m.ManagementModule),
    },
    {
      path: 'shifts',
      loadChildren: () => import('./shifts-in-progress/shifts-in-progress.module').then((m) => m.ShiftsInProgressModule),
    },
  ]),
  {
    path: 'privacypolicy',
    component: PrivacypolicyComponent,
  },
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // If you want to preload all lazy routes when the app loads, uncomment the following line
      // preloadingStrategy: PreloadAllModules,
      preloadingStrategy: NoPreloading,
      onSameUrlNavigation: 'reload',
      // This value is required for server-side rendering to work.
      initialNavigation: 'enabledNonBlocking',
      // Allows child routes to access parent route params
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
