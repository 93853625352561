import { IonicModule } from '@ionic/angular';
import { FormErrorsComponent } from './form-errors.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [FormErrorsComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [FormErrorsComponent]
})
export class FormErrorsModule { }
