export interface PtoRequestModel {
  requestId: number;
  companyId: string;
  employeeId: number;
  beginDate: string;
  endDate: string;
  ptoPolicyId: number;
  hoursRequested: number;
  reasonRequested: string;
  dateRequest: string;
  timeRequested: string;
  userCreated: string;
  approvedBy: string;
  approvedDate: string;
  approvedTime: string;
  status: string;
  empHoursNumber: number;
  managerNotes: string;
  payrollRepNotes: string;
  //Extra fields from other tables
  ptoPolicyName: string;
  checkNumber: number;
  firstName: string;
  lastName: string;
  eeSysId: number;
  employeeEmail: string;
  managerId: number;
}

export function setPtoRequestModelDefaults() {
  let obj: PtoRequestModel = {
    requestId: 0,
    companyId: '',
    employeeId: 0,
    beginDate: '',
    endDate: '',
    ptoPolicyId: 0,
    hoursRequested: 0,
    reasonRequested: '',
    dateRequest: '',
    timeRequested: '',
    userCreated: '',
    approvedBy: '',
    approvedDate: '',
    approvedTime: '',
    status: 'P',
    empHoursNumber: 0,
    managerNotes: '',
    payrollRepNotes: '',
    //Extra fields from other tables
    ptoPolicyName: '',
    checkNumber: 0,
    firstName: '',
    lastName: '',
    eeSysId: 0,
    employeeEmail: '',
    managerId: 0,
  };
  return obj;
}
