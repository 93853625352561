import { HelperService } from './../../@services/helper.service';
import { Component, OnInit, EventEmitter, Output, HostListener, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, Subject, takeUntil } from 'rxjs';
import { UserInfoModel } from '@app/@models/userInfo.model';
import { UserInfoService } from '@app/@services/user-info.service';

@Component({
  selector: 'app-titlebar',
  templateUrl: './titlebar.component.html',
  styleUrls: ['./titlebar.component.scss'],
})
export class TitlebarComponent implements OnInit, OnDestroy {
  @Output() email = new EventEmitter();
  pageTitle: string;
  pageDescription: string;
  showEmailButton: boolean;
  production: boolean = environment.production;
  userInfo: UserInfoModel;
  displayMenuButton = window.innerWidth < 992;
  prefersDark: boolean;
  private destroy$ = new Subject<void>();
  constructor(public helperService: HelperService, private userInfoService: UserInfoService) {}

  ngOnInit(): void {
    //const data = sessionStorage.getItem('credentials');
    //const creds = JSON.parse(data);
    this.helperService.castPageTitle.subscribe((title) => (this.pageTitle = title));
    this.userInfoService
      .getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => (this.userInfo = user));

    this.helperService.castPageDescription.subscribe((descr) => (this.pageDescription = descr));
    this.helperService.castShowEmailButton.subscribe((show) => (this.showEmailButton = show));
    this.prefersDark = document.body.classList.contains('dark');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  emailBtn() {
    this.email.emit();
  }

  toggleDarkMode = () => {
    this.prefersDark = !this.prefersDark;
    document.body.classList.toggle('dark', this.prefersDark);
    this.userInfoService.setPreferences('prefersDark', this.prefersDark);
  };

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.displayMenuButton = window.innerWidth < 992;
  }
}
