import { Platform } from '@ionic/angular';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import {
  add,
  addMilliseconds,
  addSeconds,
  addWeeks,
  format,
  parseJSON,
  addDays,
  differenceInDays,
  differenceInSeconds,
  differenceInHours,
} from 'date-fns';

import { format as formattz, utcToZonedTime } from 'date-fns-tz';
import { JobTimeClockModel } from '@app/@models/jobTimeClock.model';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  businessName: string;
  businessNameShort: string;
  businessPhone: string;
  businessFax: string;
  businessEmail: string;
  businessAddress: string;
  baseUrl: string;
  screenSize: string;
  logoImage: string;
  error: boolean;
  style = 'styling';
  year: number = new Date().getFullYear(); // Sets the year in the child views
  _activeYear = new BehaviorSubject<number>(this.year);
  castActiveYear = this._activeYear.asObservable();
  // Using BehaviorSubject to create an observable to set the title for each screen.
  pageTitle = new BehaviorSubject<string>('Dashboard');
  castPageTitle = this.pageTitle.asObservable();
  pageDescription = new BehaviorSubject<string>(
    'Welcome to your dashboard. This is the place to be for updates to your information here at your ePortal.'
  );
  castPageDescription = this.pageDescription.asObservable();
  showEmailButton = new BehaviorSubject<boolean>(false);
  castShowEmailButton = this.showEmailButton.asObservable();
  private _screenWidth = new BehaviorSubject<string>('lg');
  screenWidth = this._screenWidth.asObservable();
  // @HostBinding('class') componentCssClass; //Binds css to a class for changing themes
  _showNumbers = new BehaviorSubject<boolean>(false);
  castShowNumbers = this._showNumbers.asObservable();
  ip: string;

  constructor(public http: HttpClient, public router: Router, private platform: Platform) {
    this.baseUrl = environment.baseURL;
    this._activeYear.next(this.getDefaultYear());

    const savedShowNumbers = localStorage.getItem('showNumbers');
    if (savedShowNumbers) {
      this._showNumbers.next(true);
    }

    const savedActiveYear = localStorage.getItem('activeYear');
    if (savedActiveYear) {
      this._activeYear.next(Number(savedActiveYear));
    }
  }

  checkIfVisible(size: string) {
    let screenSize = ['ss', 'xs', 'sm', 'md', 'lg', 'xl'];

    if (screenSize.indexOf(size) > screenSize.indexOf(this.checkScreenSize())) {
      return false;
    }

    return true;
  }

  checkScreenSize() {
    if (this.platform.width() <= 300) {
      return 'ss';
    }
    if (this.platform.width() > 300 && this.platform.width() <= 576) {
      return 'xs';
    }
    if (this.platform.width() > 576 && this.platform.width() <= 767) {
      return 'sm';
    }
    if (this.platform.width() > 768 && this.platform.width() <= 991) {
      return 'md';
    }
    if (this.platform.width() > 991 && this.platform.width() <= 1159) {
      return 'lg';
    }
    if (this.platform.width() >= 1200) {
      return 'xl';
    }
  }

  // TODO Test this!
  getIPAddress() {
    this.http.get('http://api.ipify.org/?format=json').subscribe((res: any) => {
      this.ip = res.ip;
    });
  }

  changeActiveYear(y: number) {
    this._activeYear.next(y);
    localStorage.setItem('activeYear', y.toString());

    // console.log('helper.activeYear: ' + this._activeYear.getValue());
  }

  toggleShowNumbers() {
    if (this._showNumbers.value === true) {
      this._showNumbers.next(false);
      localStorage.removeItem('showNumbers');
    } else {
      this._showNumbers.next(true);
      localStorage.setItem('showNumbers', '1');
    }
    // console.log('helper.showNumbers: ' + this._showNumbers.getValue());
  }

  turnOnDefaultPrivacy() {
    this._showNumbers.next(false);
    localStorage.removeItem('showNumbers');
  }

  // Returns a random number between zero and including the supplied max
  getRandomInt(format: number) {
    const trueMax = format + 1;
    return Math.floor(Math.random() * Math.floor(trueMax));
  }

  // Returns an array of random images from picsum. Caller defines the number of images.
  getRandomImages(format: number) {
    let trueMax = format + 1;
    if (trueMax === 0) {
      trueMax += 1;
    } // trueMax cannot be zero.
    const images = [trueMax].map(() => `https://picsum.photos/900/500?random&t=${Math.random()}`);
    return images;
  }

  setPageTitle(newTitle: string) {
    this.pageTitle.next(newTitle);
  }

  setPageDescription(newDescription: string) {
    this.pageDescription.next(newDescription);
  }

  setShowEmailButton(toggle: boolean) {
    this.showEmailButton.next(toggle);
  }

  // Default Year is used for year selections.
  getDefaultYear() {
    const year = this.getCurrentYear();
    const week = this.getCurrentWeek();
    let rv = 0;
    if (week === 1) {
      rv = year - 1;
    } else {
      rv = year;
    }
    // Leave this here as a backup
    if (rv === 0) {
      rv = this.getCurrentYear();
    }
    // In development, default to prior year.
    if (environment.production === false) {
      rv = this.getCurrentYear() - 1;
    }
    return rv;
  }

  getCurrentYear() {
    const year = new Date().getFullYear();
    return year;
  }

  getCurrentMonth() {
    // Zero based
    const month = new Date().getMonth();
    return month;
  }

  // Gets the current week number (1-53)
  getCurrentWeek() {
    // Code from weeknumber.net
    var date = new Date();
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
  }

  // Names for date elements
  getMonthName(d: Date) {
    const month = d.getMonth(); // Returns numeric month. Zero based.
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const monthName = months[month];
    return monthName;
  }

  addDayToDate(d: Date, days: number) {
    return addDays(d, days);
  }

  getDifferenceInDays(d1: Date, d2: Date) {
    return differenceInDays(d1, d2);
  }

  getDayName(d: Date) {
    const day = d.getDay(); // Returns numeric month. Zero based.
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayName = days[day];
    return dayName;
  }

  getDayOfTheWeek(d: Date) {
    const day = d.getDay();
    if (day === 0) {
      return 'Sunday';
    }
    if (day === 0) {
      return 'Monday';
    }
    if (day === 0) {
      return 'Tuesday';
    }
    if (day === 0) {
      return 'Wednesday';
    }
    if (day === 0) {
      return 'Thursday';
    }
    if (day === 0) {
      return 'Friday';
    }
    if (day === 0) {
      return 'Saturday';
    }
  }

  addToDate(d: Date, duration: {}) {
    return add(d, duration);
  }

  stringToD10(s: string) {
    let d = new Date(s);
    let str = format(d, 'yyyy/MM/dd');
    return str;
  }

  stringToD2(s: string) {
    let d = new Date(s);
    let str = format(d, 'MM/dd/yyyy');
    return str;
  }

  dateToD10(d: Date) {
    let str = format(d, 'yyyy/MM/dd');
    return str;
  }

  dateToD2(d: Date) {
    let str = format(d, 'MM/dd/yyyy');
    return str;
  }

  getStringToFormatDate(s: string, dateformat = 'yyyy-dd-MM', parse = false) {
    let d = parse ? this.parseDate(s) : new Date(s);
    let str = format(d, dateformat);
    return str;
  }

  getFormatDateFromDate(d: Date, dateformat = 'yyyy-dd-MM') {
    return format(d, dateformat);
  }

  getClarionTimeFromDate(dt: Date) {
    return (dt.getHours() * 60 * 60 + dt.getMinutes() * 60 + dt.getSeconds()) * 100 + 1; // add 1 to be consistent with clarion
  }

  getTimeFromClarionTime(str: number, dateformat = 'hh:mm aaa') {
    let time = (str - 1) / 100;
    let hour = Math.floor(time / 60 / 60);
    let min = (time - hour * 60 * 60) / 60;
    let sec = time - (hour * 60 * 60 - min * 60);

    var d = new Date();
    d.setHours(hour);
    d.setMinutes(min);
    return format(d, dateformat);
  }

  dateToMilitary(d: Date) {
    return format(d, 'h:m');
  }

  getDateFromWeek(str: string) {
    let v = str.split('-'); // format is YYYY-Www  - 2021-W01 is the first week of 2021
    let d = new Date();
    d.setFullYear(parseInt(v[0]), 0, 0); // Set the year for the date and default to 1st day of the year
    return addWeeks(d, parseInt(v[1].replace('W', ''))); //Add weeks to the current date.  Need to remove the W from the string and pass a number
  }

  parseDate(d: any) {
    var parts = d.match(/(\d+)/g);
    // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
    let date = new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based

    return date;
  }

  parseDateFormat(d: any, dateformat: string) {
    return format(this.parseDate(d), dateformat);
  }

  dateToTimeStamp(d: Date) {
    return format(d, 'yyyy-MM-dd HH:mm:ss.SSS');
  }

  // Payroll Dates
  getPayrollDateForCurrentWeek() {
    const today = new Date();
    const nextSunday = today.getDate() - (today.getDay() - 1) + 6;
    return new Date(today.setDate(nextSunday));
  }

  getPayrollMonday(dt: string) {
    let d = new Date(dt);
    let day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return this.dateToD10(new Date(d.setDate(diff)));
  }

  getPayrollSunday(dt: string, dateformat: string = 'yyyy/MM/dd') {
    let d = new Date(dt);
    let nextSunday = d.getDate() - (d.getDay() - 1) + 6;
    d = new Date(d.setDate(nextSunday));
    return format(d, dateformat);
    //return this.dateToD10(new Date(d.setDate(nextSunday)));
  }

  getDatesInRange(startDate: string, endDate: string): Date[] {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const dates: Date[] = [];

    // Calculate the time difference between dates
    const timeDiff = end.getTime() - start.getTime();
    const daysDiff = timeDiff / (1000 * 3600 * 24);

    // Iterate through the range and add the dates
    for (let i = 1; i < daysDiff; i++) {
      const date = new Date(start);
      date.setDate(start.getDate() + i);
      dates.push(date);
    }

    return dates;
  }

  getNextDayOfWeek(dt: string, dayOfWeek: number, dateformat: string = 'yyyy/MM/dd') {
    // Sunday = 0
    let d = new Date(dt);
    while (d.getDay() != dayOfWeek) {
      d.setDate(d.getDate() + 1);
    }
    // d.setDate(d.getDate() + ((dayOfWeek + (7 - d.getDay())) % 7));

    return format(d, dateformat);
  }

  getNextDayOfWeekByDate(d: Date, dayOfWeek: number) {
    // Sunday = 0
    while (d.getDay() != dayOfWeek) {
      d.setDate(d.getDate() + 1);
      // console.log('new day:;' + d.getDay());
    }
    return d;
  }

  getPayrollDateForLastWeek() {
    // TODO test this.
    const today = new Date();
    const lastSunday = today.getDate() - (today.getDay() - 8) + 6;
    return new Date(today.setDate(lastSunday));
  }

  getAdjustedDate(days: number) {
    // TODO test this.
    const today = new Date();
    const lastSunday = today.getDate() + (today.getDay() + days);
    return new Date(today.setDate(lastSunday));
  }

  /**
   * Returns today's date string in a @d10 format.
   *
   */
  formatDateToD10(date: Date) {
    const d10 = new Date(); // If no date is given, give a date of today
    const dd = d10.getDay();
    const mm = d10.getMonth() + 1;
    const yyyy = d10.getFullYear();
    let day = '';
    let month = '';
    let r = '';

    if (!date) {
      d10.setDate(date.getDate());
    }

    if (dd < 10) {
      day = '0' + dd.toString();
    }
    if (mm < 10) {
      month = '0' + mm;
    }
    r = yyyy + '/' + mm + '/' + dd;
    return r;
  }

  /**
   * Returns today's date string in a @d10 format.
   *
   */

  formatDateToD10fromISOString(d: string) {
    const dd = d.substr(8, 2);
    const mm = d.substr(5, 2);
    const yyyy = d.substr(0, 4);
    // console.log(d);
    // console.log(dd);
    // console.log(mm);
    // console.log(yyyy);
    let r = '';
    r = yyyy + '/' + mm + '/' + dd;
    // console.log(r);
    return r;
  }

  getTimeFromISO(d: any) {
    let timezoneOffset = new Date().getTimezoneOffset(); //* 60000; //offset in milliseconds
    let startBreakTime = new Date(d - timezoneOffset).toISOString().slice(0, -1);

    let time = new Date(startBreakTime);
    return this.getFormatTimeFromDate(new Date(time));
  }

  isProduction() {
    if (window.location.hostname.toLowerCase() !== 'localhost') {
      return false;
    } else {
      return true;
    }
  }

  // Returns the correct business name depending on the url.
  getBusinessInfo() {
    this.businessAddress = '4111 Metric Drive  Winter Park, FL  32792';
    this.businessPhone = '800-366-3077';
    this.businessFax = '407-673-1248';
    switch (this.baseUrl) {
      case 'http://realtimeservices.com' || 'http://realtimeservices.us' || 'http://realtime.services': {
        this.businessName = 'RealTime Services, Inc.';
        this.businessNameShort = 'RealTime';
        this.businessEmail = 'contactus@realtimeservices.com';
        break;
      }
      case 'http://realtimefunding.com' || 'http://realtimepayrollfunding.com': {
        this.businessName = 'RealTime Funding, Inc.';
        this.businessNameShort = 'RealTime';
        this.businessEmail = 'contactus@realtimefunding.com';
        break;
      }
      case 'http://realtimepeo.com' || 'http://realpeo.com' || 'realtimepeo.co': {
        this.businessName = 'RealTime PEO, Inc.';
        this.businessNameShort = 'RealTime';
        this.businessEmail = 'contactus@realtimepeo.com';
        break;
      }
      default: {
        this.businessName = 'RealTime Services, Inc.';
        this.businessNameShort = 'RealTime';
        this.businessEmail = 'contactus@realtimeservices.com';
        break;
      }
    }
  }

  // Returns a string representing the current time
  // If a date is supplied, it will return a string value for the time
  getTime(t: Date) {
    let today = new Date();
    today.setDate(new Date().getDate());
    // Test to see if the passed object is, in fact, a date.
    if (Object.prototype.toString.call(t) === '[object Date') {
      /// Is a date
      if (isNaN(t.getTime())) {
        // t.valueOf() could also work
        // date is not valid
      } else {
        // date is valid
        today = t; // Reassign value of today to 't'
      }
    }

    return today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
  }

  // Returns a string representing date in desired format
  formatDate(d: Date, format?: string) {
    const date = new Date();

    if (isNaN(d.getDate())) {
      d = date;
    }
    const dd = String(d.getDate()).padStart(2, '0');
    const mm = String(d.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = d.getFullYear();

    switch (format) {
      case 'mm/dd/yyyy':
        return mm + '/' + dd + '/' + yyyy;
      case 'mm-dd-yyyy':
        return mm + '-' + dd + '-' + yyyy;
      case 'mm/dd/yy':
        return mm + '/' + dd + '/' + yyyy.toString().substr(2);
      case 'yyyy-mm-dd':
        return yyyy + '-' + mm + '-' + dd;
      case 'yyyy/mm/dd':
        return yyyy + '/' + mm + '/' + dd;
      default:
        return yyyy + '/' + mm + '/' + dd;
    }
  }

  // Returns a string representing today's date in desired format
  getTodayString(format: string) {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();

    switch (format) {
      case 'mm/dd/yyyy':
        return mm + '/' + dd + '/' + yyyy;
      case 'yyyy/mm/dd':
        return yyyy + '/' + mm + '/' + dd;
      default:
        return yyyy + '/' + mm + '/' + dd;
    }
  }

  //credit: https://stackoverflow.com/questions/8888491/how-do-you-display-javascript-datetime-in-12-hour-am-pm-format
  formatAmPm(date: any) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    let seconds = date.getSeconds();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    var strTime = hours + ':' + minutes + ':' + seconds + ' ' + ampm;
    return strTime;
  }

  toLocalISOString(date: Date) {
    const pad = (n: number) => String(n).padStart(2, '0');
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  toLocalISOStringWithOffset(date: Date) {
    const pad = (n: number) => String(n).padStart(2, '0');
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    const offset = -date.getTimezoneOffset();
    const sign = offset >= 0 ? '+' : '-';
    const offsetHours = pad(Math.floor(Math.abs(offset) / 60));
    const offsetMinutes = pad(Math.abs(offset) % 60);
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${sign}${offsetHours}:${offsetMinutes}`;
  }

  convertTime12to24(time12h: any) {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }

  getFormatTimeFromDate(d: Date, dateformat = 'h:mma') {
    return format(d, dateformat);
  }

  /*for formatting date to be able to patch a form field input type date*/
  formatDateForPatch(date: Date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  /*takes time values of 24 hours clock and formats to standard 12 with AM or PM*/
  amPMFormat(time: any) {
    //console.log(time);
    var dtParts = time.split(':');

    //console.log(dtParts)

    var hours = dtParts[0];
    var minutes = dtParts[1];
    var suffix = 'AM';

    if (hours > 12) {
      hours = hours - 12;
      suffix = 'PM';
    } else if (hours == '00') {
      hours = 12;
      suffix = 'AM';
    } else if (hours == '12') {
      suffix = 'PM';
    }

    return hours + ':' + minutes + ' ' + suffix;
  }

  getDateTimeFromTimeZone = (d: any) => {
    let timezoneOffset = new Date().getTimezoneOffset(); //* 60000; //offset in milliseconds
    let startBreakTime = new Date(d - timezoneOffset).toISOString().slice(0, -1);

    let time = new Date(startBreakTime);
    return this.getFormatTimeFromDate(new Date(time));
  };

  getUserTimeZone() {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return new Date().toLocaleTimeString('en', { timeZoneName: 'short', timeZone: userTimezone }).split(' ')[2];
  }

  /**
   * Returns a full name with proper capitalization
   * regardless of original case
   * @param f first name
   * @param l last name
   * @param m middle name (optional)
   */
  fullName(f: string, l: string, m?: string) {
    let fullName = '';
    if (f.length > 0) {
      fullName = this.capitalizeFirstLetterInSentence(f.trimRight());
    }
    if (m.length > 0) {
      fullName = fullName + ' ' + this.capitalizeFirstLetterInSentence(m.trimRight());
    }
    if (l.length > 0) {
      fullName = fullName + ' ' + this.capitalizeFirstLetterInSentence(l.trimRight());
    }
    return fullName;
  }

  /**
   * Returns a address
   * Only combines address1 and address2
   *
   * @param a1 address line 1
   * @param a2 address line 1
   */
  combineAddressFields(a1: string, a2: string) {
    let address = '';
    if (a1.length > 0) {
      address = this.capitalizeEveryWord(a1.trimRight());
    }
    if (a2.length > 0) {
      address = address + '  ' + a2;
    }
    return address;
  }

  /**
   * Returns city, ST zip code with proper capitalization
   * regardless of original case
   * @param c city
   * @param s state
   * @param z zip code
   */
  combineCityStateZip(c: string, s: string, z: string) {
    let csz = '';
    if (c.length > 0) {
      c = c.toLowerCase();
      csz = this.capitalizeEveryWord(c.trimRight());
    }
    if (s.length > 0) {
      csz = csz + ', ' + s.trimRight().toUpperCase();
    }
    if (z.length > 0) {
      csz = csz + '  ' + z.trimRight();
    }
    return csz;
  }

  /**
   * Returns address1, address2, city, ST zip code with proper capitalization
   * regardless of original case
   * @param a1 address1
   * @param a2 address2
   * @param c city
   * @param s state
   * @param z zip code
   */
  combineEntireAddress(a1: string, a2: string, c: string, s: string, z: string) {
    let bigAddy = '';
    bigAddy = this.combineAddressFields(a1, a2);
    bigAddy = bigAddy + '     ' + this.combineCityStateZip(c, s, z);
    return bigAddy.trimEnd;
  }

  /**
   * Returns a string where all words are capitalized.
   * Great for Address lines.
   * regardless of original case
   * @param str String to capitalize
   */
  capitalizeEveryWord(str: string) {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }

  capitalizeFirstLetterInSentence(word: string) {
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  refreshPage() {
    window.location.reload();
  }

  isOnIE() {
    // checks if the user is on IE.
    const ua = window.navigator.userAgent;
    const is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1 || ua.indexOf('Edge') > -1;

    if (is_ie) {
      return true;
    }

    return false;
  }

  calculateAge(dob: any) {
    const today: any = new Date();
    const bd: any = new Date(dob);
    const age = Math.round(((Math.abs(dob - today) / 24) * 60 * 60 * 1000) / 365);
  }

  goToUrl(url: string) {
    window.open(url, '_blank');
  }

  maskPhone(value: string) {
    let output = [];
    value = value.replace(/\D/g, '');
    for (let i = 0; i < value.length; i++) {
      if (i === 0) {
        output.push('(');
      }
      if (i === 3) {
        output.push(') ');
      }
      if (i === 6) {
        output.push('-');
      }
      output.push(value[i]);
    }
    return output;
  }

  getDifferenceInSeconds(d1: Date, d2: Date) {
    return differenceInSeconds(d1, d2);
  }

  getDifferenceInHours(d1: Date, d2: Date) {
    return differenceInHours(d1, d2);
  }

  getFormattedTimeZoneDateTime(d: string) {
    /* ion timezones documentation */
    // Get the time zone set on the user's device
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Create a date object from a UTC date string
    const date = new Date(d);

    // Use date-fns-tz to convert from UTC to a zoned time
    const zonedTime = utcToZonedTime(date, userTimeZone);

    // Create a formatted string from the zoned time
    return formattz(zonedTime, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: userTimeZone });
  }

  calculateTotalTime(timeClock: JobTimeClockModel): {
    totalTime: string;
    breakTime: string;
    timeOffHours?: string;
    originalTime?: string;
    editedTime?: string;
  } {
    try {
      // Simplify null/undefined checks with nullish coalescing operator
      timeClock.breakMinutes = timeClock.breakMinutes ?? 0;
      timeClock.originalBreakMinutes = timeClock.originalBreakMinutes ?? timeClock.breakMinutes;

      const timeOffHoursString = `${timeClock.ptoHours} hours`;

      // Convert from clarion
      const timeIn = this.getTimeFromClarionTime(timeClock.timeIn);
      const timeOut = this.getTimeFromClarionTime(timeClock.timeOut);

      // Parse time strings to Date objects
      const timeInDate = this.parseTimeStringToDate(timeIn);
      const timeOutDate = this.parseTimeStringToDate(timeOut);

      // If the punch is PM and Punch out is AM then we need to add a day onto punch out
      if (timeOutDate < timeInDate) {
        timeOutDate.setDate(timeOutDate.getDate() + 1);
      }

      // Calculate time difference in seconds
      const timeDifferenceSeconds = this.getDifferenceInSeconds(timeOutDate, timeInDate);
      const breakInSeconds = timeClock.breakMinutes * 60;

      // Subtract break time
      const totalTimeSeconds = timeDifferenceSeconds - breakInSeconds;

      // Format total time and break time for display
      const totalHours = Math.floor(totalTimeSeconds / 3600);
      const totalMinutes = Math.floor((totalTimeSeconds % 3600) / 60);

      const totalTimeString = `${totalHours} hours ${totalMinutes} minutes`;
      const breakTimeString = `${timeClock.breakMinutes} minutes`;

      // Display the results

      // Check if original and edited times are different
      const isDifferent =
        timeClock.timeIn !== timeClock.originalTimeIn ||
        timeClock.timeOut !== timeClock.originalTimeOut ||
        timeClock.breakMinutes !== timeClock.originalBreakMinutes;

      if (isDifferent) {
        // Convert from clarion
        const originalTimeIn = this.getTimeFromClarionTime(timeClock.originalTimeIn);
        const originalTimeOut = this.getTimeFromClarionTime(timeClock.originalTimeOut);

        // TODO: add original and edited dates in? would clarify edits if the times are the same but the dates changed
        // const editedDateIn = timeClock.tsClockIn.slice(5, 10).replace('-', '/');

        const originalTimeString = `${originalTimeIn} to ${originalTimeOut}, ${timeClock.originalBreakMinutes} minute break`;
        const editedTimeString = `${timeIn} to ${timeOut}, ${timeClock.breakMinutes} minute break`;

        return {
          totalTime: totalTimeString,
          breakTime: breakTimeString,
          timeOffHours: timeOffHoursString,
          originalTime: originalTimeString,
          editedTime: editedTimeString,
        };
      } else {
        return { totalTime: totalTimeString, breakTime: breakTimeString, timeOffHours: timeOffHoursString };
      }
    } catch (error) {
      console.error('Error calculating total time:', error);
      // Handle the error gracefully, potentially returning a default or partial response
      // Adjust this return based on your application's needs
      return {
        totalTime: '--',
        breakTime: '--',
        timeOffHours: '--',
        originalTime: '--',
        editedTime: '--',
      };
    }
  }

  parseTimeStringToDate(timeString: string): Date {
    const [time, period] = timeString.split(' ');
    const [hours, minutes] = time.split(':').map(Number);

    let date = new Date();
    date.setHours(period.toLowerCase() === 'pm' && hours !== 12 ? hours + 12 : hours);
    date.setMinutes(minutes);

    return date;
  }
}
