@if (mobileDevice) {
<!-- mobile layout -->
<ion-content>
  <div #container class="container">
    <div class="content mobile">
      <ng-container [ngTemplateOutlet]="loginTemplate"></ng-container>
      <p class="version">Version {{ version }} / {{ buildDateTime | date : "medium" }}</p>
    </div>
  </div>
</ion-content>
} @else {
<!-- desktop layout -->
<ion-content>
  <div #container class="container">
    <div class="content desktop">
      <div class="left">
        <ng-container [ngTemplateOutlet]="loginTemplate"></ng-container>
        <p class="version">Version {{ version }} / {{ buildDateTime | date : "medium" }}</p>
      </div>

      <div class="right ion-hide-lg-down" *ngIf="!mobileApp">
        <div class="feature-image">
          <img src="./assets/images/feature-bg.jpg" alt="background" class="feature-bg" />
          <div class="feature-image--inner">
            <img src="./assets/images/feature-mockups.png" alt="mockups" class="feature-mockups" />
          </div>
        </div>
        <div class="feature-text">
          <p>Enjoy the same great features you love from our website, now optimized for mobile.</p>
          <div class="app-downloads">
            <a
              class="app-store"
              href="https://apps.apple.com/us/app/realtime-services-mobile/id1580620738"
              target="_blank"
              rel="noreferrer"
            >
              <img src="./assets/images/download-on-app-store.png" alt="Download on the App Store" />
            </a>
            <a
              class="google-play"
              href="https://play.google.com/store/apps/details/RealTime_Mobile?id=com.rts.realtime_mobile"
              target="_blank"
              rel="noreferrer"
            >
              <img src="./assets/images/download-on-google-play.png" alt="Get it on Google Play" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
}

<ng-template #loginTemplate>
  <div class="login">
    <div class="login__branding">
      <h1 class="heading">Employee Web Portal</h1>
      <div class="subheading">
        Powered by
        <img class="logo logo-dark" src="./assets/images/logo-realtime.png" alt="logo" />
        <img class="logo logo-light" src="./assets/images/logo-realtime-white_color.png" alt="logo" />
      </div>
    </div>

    <div class="login__form">
      @if (active['login']) {
      <ng-container>
        <h2>Sign into your account</h2>
        <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
          <div class="login__form--inputs">
            <div class="error-message" *ngIf="(error && loginForm.dirty) || error">
              <ion-text color="danger">
                {{ error }}
                <div *ngIf="errorCode === 1">
                  <div>*Hint: Passwords are case sensitive.</div>
                  <div>
                    Having trouble logging in? Click <a (click)="forgotPassword()">Forgot Password</a> or
                    <a (click)="forgotUsername()">Forgot Username</a>
                  </div>
                </div>
                <span *ngIf="errorCode === 2">
                  To unlock your account click <a (click)="forgotPassword()">Forgot Password.</a>
                </span>
              </ion-text>
            </div>

            <div class="input-wrapper">
              <label for="username">Username</label>
              <input
                id="username"
                type="text"
                inputmode="text"
                autocapitalize="off"
                autocomplete="username"
                autocorrect="off"
                aria-label="Username"
                formControlName="username"
              />
              <div class="assistive-text">
                <div class="assistive-text--left">
                  <ng-container *ngFor="let error of errorMessages.username">
                    <span
                      ion-color-danger
                      class="error-message text-danger"
                      *ngIf="
                        (username.hasError(error.type) || username.errors?.minLength) &&
                        (username.dirty || username.touched)
                      "
                    >
                      {{ error.message }}
                    </span>
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="input-wrapper">
              <label for="password">Password</label>
              <input
                id="password"
                [type]="passwordType"
                inputmode="text"
                autocapitalize="off"
                autocomplete="current-password"
                autocorrect="off"
                aria-label="Password"
                formControlName="password"
              />
              <div class="assistive-text">
                <div class="assistive-text--left">
                  <ng-container *ngFor="let error of errorMessages.password">
                    <span
                      ion-color-danger
                      class="error-message text-danger"
                      *ngIf="
                        (password.hasError(error.type) || password.errors?.minLength) &&
                        (password.dirty || password.touched)
                      "
                    >
                      {{ error.message }}
                    </span>
                  </ng-container>
                </div>
                <div class="assistive-text--right">
                  <span (click)="passwordType === 'password' ? togglePassword('text') : togglePassword('password')">
                    {{ passwordType === "password" ? "Show" : "Hide" }} Password
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="login__form--actions">
            <mat-checkbox rs-size="medium" formControlName="remember">Remember me</mat-checkbox>

            <ion-button [disabled]="!loginForm.valid" type="submit" size="medium" color="primary" expand="block">
              Sign in
            </ion-button>

            <!-- /* disabled per US2105, US2106 */
            <div class="bio-login" *ngIf="hasRegisteredBiometrics && linkedBioMetrics">
              <hr />

              <ion-button
                type="submit"
                size="large"
                fill="outline"
                color="primary"
                expand="block"
                (click)="loginWithBiometrics()"
              >
                Sign in with {{ hasFaceId ? "Face ID" : "Touch ID" }}
                <ion-icon *ngIf="!hasFaceId" name="finger-print"></ion-icon>
                <ion-icon *ngIf="hasFaceId" src="./assets/images/faceid.svg"></ion-icon>
              </ion-button>
            </div>
            -->

            <p class="privacy-policy">
              By signing in, you agree to our <a href="#" routerLink="/privacypolicy">Privacy Policy</a>
            </p>

            <div class="app-downloads ion-hide-lg-up" *ngIf="!mobileApp">
              <a
                class="app-store"
                href="https://apps.apple.com/us/app/realtime-services-mobile/id1580620738"
                target="_blank"
                rel="noreferrer"
              >
                <img src="./assets/images/download-on-app-store.png" alt="Download on the App Store" />
              </a>
              <a
                class="google-play"
                href="https://play.google.com/store/apps/details/RealTime_Mobile?id=com.rts.realtime_mobile"
                target="_blank"
                rel="noreferrer"
              >
                <img src="./assets/images/download-on-google-play.png" alt="Get it on Google Play" />
              </a>
            </div>
          </div>
        </form>

        <div class="get-help">
          <h2>Get Help</h2>
          <div class="button-wrapper">
            <ion-button size="small" fill="clear" color="dark" (click)="forgotPassword()">
              Forgot Password?
            </ion-button>
            <ion-button size="small" fill="clear" color="dark" (click)="forgotUsername()">
              Forgot Username?
            </ion-button>
            <ion-button size="small" fill="clear" color="dark" (click)="contactUs()">Contact Support</ion-button>
          </div>
        </div>
      </ng-container>
      } @if (active['forgotpassword']) {
      <ng-container>
        <h2>Forgot Password</h2>
        <app-forgot-password
          (activePage)="changePage($event)"
          (setId)="setId($event.eeSysId, $event.loginname)"
        ></app-forgot-password>
      </ng-container>
      } @if (active['forgotusername']) {
      <ng-container>
        <h2>Forgot Username</h2>
        <app-forgot-username
          (activePage)="changePage($event)"
          (setId)="setId($event.eeSysId, $event.loginname)"
        ></app-forgot-username>
      </ng-container>
      } @if (active['code']) {
      <ng-container>
        <app-password-code
          (activePage)="changePage($event)"
          [eeSysId]="eeSysId"
          [loginname]="loginname"
        ></app-password-code>
      </ng-container>
      } @if (active['change']) {
      <ng-container>
        <h2>Create a new password</h2>
        <app-password-change
          (activePage)="changePage($event)"
          [eeSysId]="eeSysId"
          [loginname]="loginname"
        ></app-password-change>
      </ng-container>
      }
    </div>
  </div>
</ng-template>
