import { Injectable } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { NativeMarket } from '@capacitor-community/native-market';
import { Capacitor } from '@capacitor/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VersionCheckService {
  constructor(private http: HttpClient, private alertController: AlertController, private platform: Platform) {}

  versionCheck(): Observable<any> {
    return this.http.post(environment.serverUrl + 'auth/version/check', {});
  }

  async openNewVersionBanner(): Promise<void> {
    // Show Ionic Alert for a new version
    const alert = await this.alertController.create({
      header: 'New Version Available',
      message: 'A new version of the application is available.',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'secondary',
          handler: () => {
            this.alertController.dismiss();
          },
        },
        {
          text: 'Update',
          cssClass: 'primary',
          handler: () => {
            // Check if the platform is web before triggering a refresh
            if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
              window.location.reload();
            } else {
              const devId = this.platform.is('android') ? 'com.rts.realtime_mobile' : 'com.realtimemobile';
              let appId = '1580620738';
              if (this.platform.is('android')) {
                appId = 'com.rts.realtime_mobile';
              }
              NativeMarket.openStoreListing({
                appId,
              });
            }
          },
        },
      ],
    });

    await alert.present();
  }
}
