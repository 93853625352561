import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AlertController, IonicModule } from '@ionic/angular';
import { CoreModule } from '@core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ShellModule } from './shell/shell.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { LoadChunkErrorHandler } from './@core/loadChunckErrorHandler';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
// import { ServiceWorkerModule } from '@angular/service-worker';

// import ngx-translate and the http loader
// import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
// import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      rippleEffect: false,
      mode: 'md',
      innerHTMLTemplatesEnabled: true,
    }),
    HttpClientModule,
    AppRoutingModule,
    ShellModule,
    NgIdleKeepaliveModule.forRoot(),
    CoreModule,
    // ServiceWorkerModule.register('./ngsw-worker.js', {
    //   enabled: environment.production,
    // }),
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient],
    //   },
    // }),
  ],
  declarations: [AppComponent],
  providers: [
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    { provide: ErrorHandler, useClass: LoadChunkErrorHandler },
    AlertController,
    FileOpener,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
// export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
//   return new TranslateHttpLoader(http);
// }
