<!-- <ion-item lines="none" [color]="color"> -->
  <!-- <ion-label position="inline" *ngIf="displayLabel"> Year: </ion-label> -->
  <!-- <ion-select interface="popover" (ionChange)="yearChange($event.target.value)" [value]="selectedYear"> -->
  <ion-select
    label="Year"
    label-placement="floating"
    fill="outline"
    interface="popover"
    (ionChange)="yearChange($event.target.value)"
    [value]="selectedYear"
  >
    <ion-select-option *ngFor="let y of years">{{ y }}</ion-select-option>
  </ion-select>
<!-- </ion-item> -->
