// loader.service.ts
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  load: HTMLIonLoadingElement = null;
  isDisplayed = false;
  hideAttempt = 0;
  constructor(public loadingController: LoadingController) {}

  // This will show then autohide the loader
  showHideAutoLoader() {
    this.loadingController
      .create({
        message: 'This Loader Will Auto Hide in 2 Seconds',
        duration: 2000,
      })
      .then((res) => {
        res.present();
        res.onDidDismiss().then((dis) => {
          // console.log('Loading dismissed! after 2 Seconds', dis);
        });
      });
  }

  changeMessage(message: string) {
    // console.log('changing message');
    if (this.load) {
      this.load.message = message;
    }
  }

  // Show the loader for infinite time
  showLoader(message: string = 'Please wait...') {
    return new Promise((resolve) => {
      this.loadingController
        .create({
          message,
        })
        .then((res) => {
          this.load = res;
          res.present();
          resolve('done');
        });
    });
  }

  // Hide the loader if already created otherwise return error
  hideLoader() {
    this.loadingController
      .dismiss()
      .then((res) => {
        console.log('Loading dismissed!', res);
      })
      .catch((error) => {
        console.log('error on dismissing', error);
      });
  }
}
