import { CredentialsService } from '@app/auth';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, createUrlTreeFromSnapshot } from '@angular/router';
import { HttpParams } from '@angular/common/http';
// import { Logger } from '@core';
import { map } from 'rxjs';
// import { CredentialsService } from './credentials.service';

// const log = new Logger('AuthenticationGuard');

export const AuthenticationGuard = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(CredentialsService)
    .isLoggedIn()
    .pipe(
      map((isLoggedIn) => {
        // console.log(state.url);
        // User is not authenticated, redirect to the login page with query parameters

        return isLoggedIn
          ? true
          : createUrlTreeFromSnapshot(next, ['/login'], { queryParams: encodeURIComponent(state.url) });
      })
    );
};
