import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { UserInfoService } from './../@services/user-info.service';
import { Router } from '@angular/router';

export interface Credentials {
  // Customize received credentials here
  username: string;
  token: string;
  userInfo: object;
}

const credentialsKey = 'credentials';

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  private credsSubject: BehaviorSubject<Credentials | null> = new BehaviorSubject<Credentials | null>(null);
  public creds$: Observable<Credentials | null> = this.credsSubject.asObservable();
  private _credentials: Credentials | null = null;

  constructor(private userInfoService: UserInfoService, private router: Router) {
    // this.setCredentials();
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
      if (this._credentials.userInfo) {
        this.credsSubject.next(this._credentials);
        this.userInfoService.setUserInfo(this._credentials);
      } else {
        this._credentials = null;
      }
    }
  }

  isLoggedIn() {
    return of(!!this.credsSubject.getValue()).pipe(tap((v) => console.log('isLoggedIn', this.router.url, v)));
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    // console.log('checking if authenticated', !!this._credentials);
    return !!this._credentials;
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: Credentials, remember?: boolean) {
    this._credentials = credentials || null;
    console.log({ 'setCredentials fn': credentials });
    if (credentials) {
      this.credsSubject.next(this._credentials);
      this.userInfoService.setUserInfo(this._credentials);

      sessionStorage.setItem(credentialsKey, JSON.stringify(credentials));
      localStorage.setItem('id_token', credentials.token);
      if (remember) {
        localStorage.setItem('remember', JSON.stringify(remember));
        localStorage.setItem(credentialsKey, JSON.stringify(credentials));
      }
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
      localStorage.removeItem('remember');
      localStorage.removeItem('id_token');
    }
  }

  clear() {
    // console.log('clearing session');
    this._credentials = null;
    sessionStorage.removeItem(credentialsKey);
    localStorage.removeItem(credentialsKey);
    localStorage.removeItem('remember');
    localStorage.removeItem('id_token');
  }
}
