import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { EmailService } from '@app/@services/email.service';
import { UserInfoService } from '@app/@services/user-info.service';

@Component({
  selector: 'app-password-code',
  templateUrl: './password-code.component.html',
  styleUrls: ['./password-code.component.scss'],
})
export class PasswordCodeComponent implements OnInit {
  @Input() eeSysId: number;
  @Input() loginname: string;
  @Output() activePage = new EventEmitter<string>();
  myForm: UntypedFormGroup;
  showNewCodeMsg: boolean = false;
  triesLeft: number = 3;

  errorMessages = {
    code: [
      { type: 'required', message: 'Code is required' },
      { type: 'minlength', message: 'Minimum of 6 digits is required' },
      { type: 'maxlength', message: 'Max of 6 digits is required' },
      { type: 'invalid', message: '' },
    ],
  };

  constructor(private fb: UntypedFormBuilder, private userInfoService: UserInfoService, private emailService: EmailService) {
    this.myForm = fb.group({
      code: this.fb.control('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
    });
  }

  get code() {
    return this.myForm.get('code');
  }

  ngOnInit(): void {}

  onSubmit() {
    if (this.myForm.valid) {
      this.userInfoService.verifyCode(this.eeSysId, this.code.value, this.loginname).subscribe((data: any) => {
        if (data[0].verified !== 0) {
          this.activePage.emit('change');
        } else if (this.triesLeft > 1) {
          this.triesLeft -= 1;
          this.myForm.markAsPristine;
          this.errorMessages.code[3].message = `Code is invalid or expired. You have ${this.triesLeft} ${
            this.triesLeft > 1 ? 'tries' : 'try'
          } left.`;
          this.code.setErrors({ invalid: true });
        } else {
          this.myForm.controls['code'].disable();
          this.showNewCodeMsg = true;
        }
      });
    }
  }

  newCode() {
    this.activePage.emit('forgotpassword');
  }

  goBack() {
    this.activePage.emit('login');
  }
}
