import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-name-and-id',
  templateUrl: './name-and-id.component.html',
  styleUrls: ['./name-and-id.component.scss'],
  standalone: true,
})
export class NameAndIdComponent {
  @Input() nameValue: string;
  @Input() idValue: string | number;
}
