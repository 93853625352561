import { UntypedFormGroup, UntypedFormControl, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

interface PasswordStrengthErrors {
  [key: string]: boolean;
}

export class FormValidation {
  static validateAllFormFields(formGroup: UntypedFormGroup) {
    //{1}
    Object.keys(formGroup.controls).forEach((field) => {
      //{2}
      const control = formGroup.get(field); //{3}
      if (control instanceof UntypedFormControl) {
        //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        //{5}
        this.validateAllFormFields(control); //{6}
      }
    });

    return formGroup;
  }
}

export function forbiddenCharacterValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const forbidden = /[^a-zA-Z0-9!@#$%^&*()_+\-=]/.test(control.value);
    return forbidden ? { forbiddenCharacters: { value: control.value } } : null;
  };
}

export function noSpaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isSpace = (control.value || '').trim().indexOf(' ') !== -1;
    return isSpace ? { noSpace: true } : null;
  };
}

// custom validator to check that two fields match
export class ValidatePassword {
  static mustMatch(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password');
    const cpassword = control.get('cpassword');

    if (cpassword.errors && !cpassword.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return null;
    }

    // set error on matchingControl if validation fails
    if (password.value !== cpassword.value) {
      control.get('cpassword').setErrors({ mustMatch: true });
    } else {
      return null;
    }
  }

  static passwordStrengthValidator(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password');
    const errors: PasswordStrengthErrors = {};
    const hasNumber = /\d/.test(password.value);
    if (!hasNumber) {
      errors['hasNumber'] = true;
    }
    const hasUpper = /[A-Z]/.test(password.value);
    if (!hasUpper) {
      errors['hasUpper'] = true;
    }
    const hasLower = /[a-z]/.test(password.value);
    if (!hasLower) {
      errors.hasLower = true;
    }
    // const hasSpecial = /[^A-Za-z0-9]/.test(password.value);
    // if (!hasSpecial) {
    //   errors['hasSpecial'] = true;
    // }
    const hasWhiteSpace = /\s/g.test(password.value);
    if (hasWhiteSpace && (password.touched || password.dirty)) {
      errors['hasWhiteSpace'] = true;
    }
    const minLength = password.value.length >= 8 ? true : false;
    if (!minLength) {
      errors['minLength'] = true;
    }
    const maxLength = password.value.length <= 20 ? true : false;
    if (!maxLength) {
      errors['maxLength'] = true;
    }

    const forbidden = /[^a-zA-Z0-9!@#$%^&*()_+\-=]/.test(password.value);
    errors['forBiddenCharacter'] = forbidden ? true : null;

    // const valid = hasNumber && hasUpper && hasLower && hasSpecial && !hasWhiteSpace && minLength;
    const valid = hasNumber && hasUpper && hasLower && !hasWhiteSpace && minLength && maxLength && !forbidden;
    if (valid) {
      return null;
    } else {
      control.get('password').setErrors(errors);
    }
  }
}
