import { EmptyLayoutComponent } from './layout/empty-layout/empty-layout.component';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';

import { RouteReusableStrategy } from './route-reusable-strategy';
import { ApiPrefixInterceptor } from './http/api-prefix.interceptor';
import { ErrorHandlerInterceptor } from './http/error-handler.interceptor';
import { AppInitializer } from '@app/auth/app.initializer';
import { ColorPalettesService } from './color-palettes.service';
import { CredentialsService } from '@app/auth';

@NgModule({
  imports: [CommonModule, HttpClientModule, RouterModule, EmptyLayoutComponent],
  providers: [
    { provide: APP_INITIALIZER, useFactory: AppInitializer, multi: true, deps: [CredentialsService] },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (colorPalettesService: ColorPalettesService) => {
    //     return () => colorPalettesService.init();
    //   },
    //   deps: [ColorPalettesService],
    //   multi: true,
    // },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
