import { CredentialsService } from '@app/auth';

export function AppInitializer(credentialsService: CredentialsService) {
  return () =>
    new Promise((resolve) => {
      // attempt to refresh token on app start up to auto authenticate

      if (credentialsService.isAuthenticated()) {
        // console.log('is logged in refreshing token check');
        // authenticationService.refreshToken().subscribe((res) => resolve('complete'));
        resolve('complete');
      } else {
        // console.log('not logged in');
        resolve('complete');
      }
    });
}
