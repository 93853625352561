import { Subject } from 'rxjs';
import { DeliveryOptionComponent } from './../delivery-option/delivery-option.component';
import { ModalController, AlertController } from '@ionic/angular';
import { EmailService } from '@app/@services/email.service';
import { UserInfoService } from '@app/@services/user-info.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-forgot-username',
  templateUrl: './forgot-username.component.html',
  styleUrls: ['./forgot-username.component.scss'],
})
export class ForgotUsernameComponent implements OnInit {
  @Output() activePage = new EventEmitter<string>();
  @Output() setId = new EventEmitter<any>();
  myForm: UntypedFormGroup;
  eeSysId: number;
  loginname: string;
  phone: string;
  username: string;

  findUserError = false;

  errorMessages = {
    email: [{ type: 'required', message: 'Email is required' }],
    ssn: [
      { type: 'required', message: 'Last 4 of social is required' },
      { type: 'minlength', message: 'Minimum of 4 digits is required' },
      { type: 'maxlength', message: 'Maximum of 4 digits is required' },
    ],
  };

  constructor(
    private fb: UntypedFormBuilder,
    private userInfoService: UserInfoService,
    private emailService: EmailService,
    private modalController: ModalController,
    private alertController: AlertController
  ) {
    this.myForm = fb.group({
      email: this.fb.control('', [Validators.required, Validators.minLength(4)]),
      ssn: this.fb.control('', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]),
    });
  }

  get email() {
    return this.myForm.get('email');
  }

  get ssn() {
    return this.myForm.get('ssn');
  }

  ngOnInit(): void {}

  async deliveryMethod(info: any) {
    const modal = await this.modalController.create({
      component: DeliveryOptionComponent,
      componentProps: {
        info,
      },
    });

    modal.onDidDismiss().then((res) => {
      if (res.data.eeSysId > 0) {
        info.forEach((obj: any) => {
          if (obj.eeSysId === res.data.eeSysId) {
            this.emailforgotuser(obj, res.data.deliveryMethod);
          }
        });
      }
    });
    await modal.present();
  }

  emailforgotuser(info: any, deliveryMethod: string) {
    let from = '',
      to = info.email,
      subject = `Requested Username `,
      message = `The username associated with this email address is ${info.loginname}.`,
      loginname = info.loginname;
    if (deliveryMethod === 'e') {
      this.emailService.forgotUsernameEmail(from, to, subject, message, loginname).subscribe(
        (data) => {
          this.alert('Email Sent');
        },
        (error) => {
          // console.log(error);
        }
      );
    } else {
      // let to = info.phone;
      this.emailService.forgotUsernameText(('' + info.phone).replace(/\D/g, ''), loginname);
      this.alert('SMS Sent');
    }
  }

  onSubmit() {
    // console.log(this.myForm.valid);
    if (this.myForm.valid) {
      this.userInfoService.getWebLoginInfo(this.email.value, this.ssn.value, this.loginname).subscribe((data) => {
        if (!data || !data.length) {
          this.findUserError = true;
        } else {
          this.deliveryMethod(data);
          // console.log(data);
        }
      });
    }
  }

  async alert(msg: string) {
    let alert = await this.alertController.create({ message: msg, buttons: ['OK'] });
    await alert.present();
    this.activePage.emit('login');
  }
  goBack() {
    this.activePage.emit('login');
  }
}
