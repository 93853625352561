<form [formGroup]="myForm" (ngSubmit)="onSubmit()">
  <div class="login__form--inputs">
    <div class="error-message" *ngIf="showNewCodeMsg">
      <ion-text color="danger">
        <span>
          Too many invalid codes have been entered. Please click
          <a style="cursor: pointer; text-decoration: underline" (click)="newCode()">here</a> to request a new code.
        </span>
      </ion-text>
    </div>

    <div class="input-wrapper">
      <label for="code">6 Digit Code</label>
      <input id="code" type="text" inputmode="numeric" formControlName="code" aria-label="6 Digit Code" maxlength="6" />
      <div class="assistive-text">
        <app-form-errors class="assistive-text--left" [errors]="errorMessages.code" [item]="code"></app-form-errors>
      </div>
    </div>
  </div>

  <div class="login__form--actions">
    <!-- <ion-item lines="none">
      <small> Did not recieve an email? </small>
    </ion-item> -->
    <ion-button [disabled]="!myForm.valid" type="submit" color="primary" size="medium" expand="block">
      Submit
    </ion-button>
    <ion-button size="small" fill="clear" color="dark" (click)="goBack()">Back to login</ion-button>
  </div>
</form>
