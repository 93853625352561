import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss'],
})
export class FormErrorsComponent implements OnInit {
  @Input() errors: any;
  @Input() item: UntypedFormGroup;
  @Input() lines: '';
  constructor() {}

  ngOnInit(): void {}
}
