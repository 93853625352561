<ion-header>
  <ion-toolbar color="primary">
    <ion-item color="primary">
      <ion-icon slot="end" name="close-circle-outline" (click)="dismissModal()"></ion-icon>
      <ion-label>One-Time Security Code</ion-label>
    </ion-item>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="m-15">
    <p>Tell us where to reach you</p>
    <p>Don't recognize these options?</p>
    <p>
      You may have entered incorrect info. Close this and check the information you entered. If you still do not
      recognize the contact information or they are no longer accurate, contact us at
      <a href="mailto:webportalfeedback@realtimeservices.com">webportalfeedback&#64;realtimeservices.com </a>
    </p>
  </div>
  <ion-grid>
    <ion-row color="primary" justify-content-center>
      <ion-col align-self-center>
        <ion-card padding>
          <ion-item lines="none" *ngIf="info.length > 1">
            <ion-label>More than one user name exists.</ion-label>
          </ion-item>
          <ion-item *ngIf="info.length > 1">
            <ion-label position="top" class="delivery-option-header">Users</ion-label>
            <ion-radio-group>
              <ion-list>
                <ion-item class="delivery-options" lines="none" *ngFor="let i of info">
                  <ion-radio slot="start" color="" value="{{ i.eeSysId }}" (click)="changeUsers(i.eeSysId)"></ion-radio>
                  <ion-label>{{ i.loginname }}</ion-label>
                </ion-item>
              </ion-list>
            </ion-radio-group>
          </ion-item>
          <div *ngIf="eeSysId > 0">
            <label id="delivery-methid-radio-group-label">Pick your delivery method</label>
            <div>
              <ion-radio-group
                aria-labelledby="delivery-methid-radio-group-label"
                class="delivery-method-radio-group"
                [(ngModel)]="deliveryMethod"
              >
                <ion-radio
                  class="delivery-method-radio-button"
                  labelPlacement="end"
                  *ngIf="email && email.length >= 4"
                  item-left
                  value="e"
                  >Email - {{ email }}</ion-radio
                >
                <ion-radio
                  class="delivery-method-radio-button"
                  labelPlacement="end"
                  *ngIf="phone && phone.length >= 7"
                  item-left
                  value="p"
                  >Phone - {{ phone }}</ion-radio
                >
              </ion-radio-group>
            </div>
          </div>

          <ion-grid>
            <ion-row>
              <ion-col size="6">
                <ion-button color="primary" expand="block" (click)="onSubmit()" [disabled]="!deliveryMethod?.length">
                  Continue
                  <ion-ripple-effect></ion-ripple-effect>
                </ion-button>
              </ion-col>
              <ion-col size="6">
                <ion-button color="light" expand="block" (click)="dismissModal()">
                  Cancel
                  <ion-ripple-effect></ion-ripple-effect>
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
