import { EmpEFTAccountsModel } from './../@models/emp-eftaccounts';
import { EmployeeDeductionModel } from './../@models/employeeDeduction.model';
import { W2Model } from './../@models/w2.model';
import { PayrollCheckModel } from './../@models/payrollcheck.model';
import { PayrollDepositsModel } from './../@models/payrollDeposits.model';
import { PayrollDeductionModel } from './../@models/payrollDeduction.model';
import { Injectable, Output } from '@angular/core';
import { UserInfoService } from './user-info.service';
import { HelperService } from '@app/@services/helper.service';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from '@env/environment';
import { YearlyPayrollModel } from '@app/@models/yearlyPayroll.model';
import { PayrollDeductionHistoryModel } from '@app/@models/payrollDeductionHistory.model';
import { EmployeePayRollModel } from '@app/@models/emp-payRoll';
import { PayrollGarnishmentsModel } from '@app/@models/payroll-garnishments.model';
@Injectable({
  providedIn: 'root',
})
export class PayrollService {
  payrollChecksSub = new Subject<PayrollCheckModel>();
  payrollChecks: PayrollCheckModel;
  result: any;
  deductionData: PayrollDeductionHistoryModel[];

  constructor(
    private http: HttpClient,
    private userInfoService: UserInfoService,
    private helperService: HelperService
  ) {}

  getPayrollCheckYears(): Observable<any[]> {
    return this.http
      .post<any[]>(environment.serverUrl + 'payroll/myPayrollYears', {
        eeSysId: this.userInfoService.userInfo.eeSysId,
        token: this.userInfoService.userInfo.token,
      })
      .pipe(
        map((res) => res),
        shareReplay()
      ); // shareReplay limits the calls to the server to one.
  }

  getPayCheckTotals(y: number): Observable<any[]> {
    return this.http
      .post<any>(environment.serverUrl + 'payroll/getPayCheckTotals', {
        username: this.userInfoService.userInfo.username,
        token: this.userInfoService.userInfo.token,
        eeSysId: this.userInfoService.userInfo.eeSysId,
        year: y,
      })
      .pipe(
        map((res) => res),
        shareReplay()
      ); // shareReplay limits the calls to the server to one.
  }

  getAllPayrollChecks(y: number): Observable<PayrollCheckModel[]> {
    return this.http
      .post<PayrollCheckModel[]>(environment.serverUrl + 'payroll/mych', 
        {
        username: this.userInfoService.userInfo.username,
        token: this.userInfoService.userInfo.token,
        eeSysId: this.userInfoService.userInfo.eeSysId,
        year: y,
        }
      )
      .pipe(
        map((res) => res),
        shareReplay()
      ); // shareReplay limits the calls to the server to one.
  }

  getPayrollCheckREG(chkNumber: number): Observable<any[]> {
    return this.http
      .post<any[]>(environment.serverUrl + 'payroll/payrollREG', {
        companyId: this.userInfoService.userInfo.companyId,
        employeeId: this.userInfoService.userInfo.employeeId,
        chkNumber,
        token: this.userInfoService.userInfo.token,
      })
      .pipe(
        map((res) => res),
        shareReplay()
      ); // shareReplay limits the calls to the server to one.
  }

  getPayrollCheckMP(chkNumber: number): Observable<any[]> {
    return this.http
      .post<any[]>(environment.serverUrl + 'payroll/payrollMP', {
        companyId: this.userInfoService.userInfo.companyId,
        employeeId: this.userInfoService.userInfo.employeeId,
        chkNumber,
        token: this.userInfoService.userInfo.token,
      })
      .pipe(
        map((res) => res),
        shareReplay()
      ); // shareReplay limits the calls to the server to one.
  }

  getPayrollCheckPto(chkNumber: number): Observable<any[]> {
    return this.http
      .post<any[]>(environment.serverUrl + 'payroll/payrollPto', {
        companyId: this.userInfoService.userInfo.companyId,
        employeeId: this.userInfoService.userInfo.employeeId,
        chkNumber,
        token: this.userInfoService.userInfo.token,
      })
      .pipe(
        map((res) => res),
        shareReplay()
      ); // shareReplay limits the calls to the server to one.
  }

  getPayrollCheckDeposits(chkNumber: number): Observable<PayrollDepositsModel[]> {
    return this.http
      .post<PayrollDepositsModel[]>(environment.serverUrl + 'payroll/payrollDeposits', {
        companyId: this.userInfoService.userInfo.companyId,
        employeeId: this.userInfoService.userInfo.employeeId,
        chkNumber,
        token: this.userInfoService.userInfo.token,
      })
      .pipe(
        map((res) => res),
        shareReplay()
      ); // shareReplay limits the calls to the server to one.
  }

  getPayrollCheckDeductionHistory(deduction2Id: number) {
    return this.http
      .post<PayrollDeductionHistoryModel[]>(environment.serverUrl + 'payroll/payrollDeductionHistory', {
        companyId: this.userInfoService.userInfo.companyId,
        employeeId: this.userInfoService.userInfo.employeeId,
        deduction2Id: deduction2Id,
        token: this.userInfoService.userInfo.token,
      })
      .pipe(
        map((res) => res),
        shareReplay()
      ); // shareReplay limits the calls to the server to one.
  }

  getPayrollCheckDeductions(chkNumber: number): Observable<PayrollDeductionModel[]> {
    // console.log('In payrollService.getPayrollCheckDeductions; chkNumber = ' + chkNumber)
    return this.http
      .post<PayrollDeductionModel[]>(environment.serverUrl + 'payroll/payrollDeductions', {
        companyId: this.userInfoService.userInfo.companyId,
        employeeId: this.userInfoService.userInfo.employeeId,
        chkNumber,
        token: this.userInfoService.userInfo.token,
      })
      .pipe(
        map((res) => res),
        shareReplay()
      ); // shareReplay limits the calls to the server to one.
  }

  getPayrollCheckGarnishments(chkNumber: number): Observable<PayrollGarnishmentsModel[]> {
    // console.log('In payrollService.getPayrollCheckDeductions; chkNumber = ' + chkNumber)
    return this.http
      .post<PayrollGarnishmentsModel[]>(environment.serverUrl + 'payroll/payrollGarnishments', {
        companyId: this.userInfoService.userInfo.companyId,
        employeeId: this.userInfoService.userInfo.employeeId,
        chkNumber,
        token: this.userInfoService.userInfo.token,
      })
      .pipe(
        map((res) => res),
        shareReplay()
      ); // shareReplay limits the calls to the server to one.
  }

  getEmployeeDeductions(year: number): Observable<EmployeeDeductionModel[]> {
    return this.http
      .post<EmployeeDeductionModel[]>(environment.serverUrl + 'payroll/employeeDeductions', {
        companyId: this.userInfoService.userInfo.companyId,
        employeeId: this.userInfoService.userInfo.employeeId,
        token: this.userInfoService.userInfo.token,
        year: year,
      })
      .pipe(
        map((res) => res),
        shareReplay()
      ); // shareReplay limits the calls to the server to one.
  }

  getAllW2s(): Observable<W2Model[]> {
    // console.log({'getAllW2s':this.userInfoService.userInfo.eeSysId})
    return this.http
      .post<W2Model[]>(environment.serverUrl + 'payroll/myw2s', {
        eeSysId: this.userInfoService.userInfo.eeSysId,
        token: this.userInfoService.userInfo.token,
      })
      .pipe(
        map((res) => res),
        shareReplay()
      ); // shareReplay limits the calls to the server to one.
  }

  getW2Details(): Observable<W2Model[]> {
    return this.http
      .get<W2Model[]>(environment.serverUrl + 'payroll/myw2details', {
        // token: this.userInfoService.userInfo.token,
      })
      .pipe(
        map((res) => res),
        shareReplay()
      ); // shareReplay limits the calls to the server to one.
  }

  getPayrollChecks() {
    this.http
      .post<any>(environment.serverUrl + 'payroll/mych', {
        username: this.userInfoService.userInfo.username,
        token: this.userInfoService.userInfo.token,
        eeSysId: this.userInfoService.userInfo.eeSysId,
      })
      .pipe(
        map((resData) => {
          return resData.map((checks: PayrollCheckModel) => this.payrollChecks);
        })
      )
      .subscribe((transformedData) => {
        this.payrollChecksSub.next(transformedData);
      });
    // console.log('prsrv payrollChecks: ' + this.payrollChecks);
  }

  getPayType(s: string) {
    if (s === 'DD') {
      return 'Direct Deposit';
    } else if (s === 'CH') {
      return 'Check';
    } else if (s === 'PC') {
      return 'PayCard';
    } else {
      return '';
    }
  }

  getYearlyPayroll(y: number) {
    return this.http
      .post<YearlyPayrollModel[]>(environment.serverUrl + 'payroll/myYearlyPayroll', {
        eeSysId: this.userInfoService.userInfo.eeSysId,
        token: this.userInfoService.userInfo.token,
        year: y,
      })
      .pipe(
        map((res) => res),
        shareReplay()
      ); // shareReplay limits the calls to the server to one.
  }

  getYearlyPto(y: number) {
    return this.http
      .post<YearlyPayrollModel[]>(environment.serverUrl + 'payroll/myYearlyPto', {
        // eeSysId: this.userInfoService.userInfo.eeSysId,
        companyId: this.userInfoService.userInfo.companyId,
        employeeId: this.userInfoService.userInfo.employeeId,
        token: this.userInfoService.userInfo.token,
        year: y,
      })
      .pipe(
        map((res) => res),
        shareReplay()
      ); // shareReplay limits the calls to the server to one.
  }

  getYearlyDeductions(y: number) {
    return this.http
      .post<YearlyPayrollModel[]>(environment.serverUrl + 'payroll/myYearlyDeductions', {
        eeSysId: this.userInfoService.userInfo.eeSysId,
        token: this.userInfoService.userInfo.token,
        year: y,
      })
      .pipe(
        map((res) => res),
        shareReplay()
      ); // shareReplay limits the calls to the server to one.
  }

  getPayrollInfo(): Observable<EmployeePayRollModel> {
    return this.http.post<EmployeePayRollModel>(environment.serverUrl + 'payroll/getPayrollInfo', {
      companyId: this.userInfoService.userInfo.companyId,
      employeeId: this.userInfoService.userInfo.employeeId,
      token: this.userInfoService.userInfo.token,
    });
  }

  getDirectDepositInfo(): Observable<EmpEFTAccountsModel[]> {
    return this.http
      .post<EmpEFTAccountsModel[]>(environment.serverUrl + 'payroll/getDirectDepositInfo', {
        companyId: this.userInfoService.userInfo.companyId,
        employeeId: this.userInfoService.userInfo.employeeId,
        token: this.userInfoService.userInfo.token,
      })
      .pipe(
        map((res) => res),
        shareReplay()
      ); // shareReplay limits the calls to the server to one.
  }

  getPayrollDates(year: number, employeeId: number, companyId: string) {
    return this.http
      .post<any>(environment.serverUrl + 'payroll/getPayrollDates', {
        year,
        employeeId,
        companyId,
      })
      .pipe(
        map((res) => res),
        shareReplay()
      ); // shareReplay limits the calls to the server to one.
  }

  getTimeOffPayrollDates(endDate: string, employeeId: number, companyId: string) {
    return this.http
      .post<any>(environment.serverUrl + 'timeoff/timeoffPayRollDates', {
        endDate,
        employeeId,
        companyId,
      })
      .pipe(
        map((res) => res),
        shareReplay()
      ); // shareReplay limits the calls to the server to one.
  }
}
