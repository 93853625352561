<ion-header>
  <ion-toolbar color="primary">
    <ion-item color="primary">
      <ion-icon slot="end" name="close-circle-outline" (click)="dismissModal()"></ion-icon>
      <ion-label>Change Password</ion-label>
    </ion-item>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="ion-padding">
    <form [formGroup]="myForm">
      <ion-row>
        <ion-col>
          <ion-input
            label="Old Password"
            aria-label="Old Password"
            labelPlacement="floating"
            fill="outline"
            [clearInput]="true"
            formControlName="opassword"
            [type]="hide[0] ? 'password' : 'text'"
          >
          </ion-input>
        </ion-col>
        <ion-col size="auto">
          <ion-button size="medium" color="primary" fill="clear" aria-label="Show/hide" (click)="hide[0] = !hide[0]">
            <ion-icon slot="icon-only" [name]="hide[0] ? 'eye' : 'eye-off'" aria-hidden="true"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <ion-input
            label="New Password"
            aria-label="New Password"
            labelPlacement="floating"
            fill="outline"
            [clearInput]="true"
            formControlName="password"
            [type]="hide[1] ? 'password' : 'text'"
          ></ion-input>
        </ion-col>
        <ion-col size="auto">
          <ion-button size="medium" color="primary" fill="clear" aria-label="Show/hide" (click)="hide[1] = !hide[1]">
            <ion-icon slot="icon-only" [name]="hide[1] ? 'eye' : 'eye-off'" aria-hidden="true"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>

      <div class="validation-list ion-padding">
        <ion-row
          class="validation-list {{ !password.value ? 'pending' : password.hasError(p.type) ? 'error' : 'success' }}"
          *ngFor="let p of errorMessages.password"
        >
          <ion-icon
            name="{{ !password.value ? 'radio-button-off' : password.hasError(p.type) ? 'close' : 'checkmark' }}"
          ></ion-icon>
          <ion-label>{{ p.message }}</ion-label>
        </ion-row>
      </div>

      <ion-row>
        <ion-col>
          <ion-input
            label="Confirm New Password"
            aria-label="Confirm New Password"
            labelPlacement="floating"
            fill="outline"
            [clearInput]="true"
            formControlName="cpassword"
            [type]="hide[2] ? 'password' : 'text'"
          ></ion-input>
        </ion-col>
        <ion-col size="auto">
          <ion-button size="medium" color="primary" fill="clear" aria-label="Show/hide" (click)="hide[2] = !hide[2]">
            <ion-icon slot="icon-only" [name]="hide[2] ? 'eye' : 'eye-off'" aria-hidden="true"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>

      <app-form-errors [errors]="errorMessages.cpassword" [item]="cpassword"></app-form-errors>
    </form>
  </div>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button type="button" color="dark" fill="outline" (click)="dismissModal()">Cancel</ion-button>
      <ion-button type="button" color="primary" [disabled]="!myForm.valid" fill="solid" (click)="onSubmit()">
        Submit
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
