<ion-split-pane contentId="main-content">
  <ion-menu contentId="main-content" type="overlay">
    <ion-content>
      <!-- <div ion-fixed> -->
      <div class="profile">
        <ion-row>
          <ion-item color="primary" lines="none">
            <ion-label>
              <p>Employee Web Portal</p>
              <h3>{{ userInfo$.companyName }}</h3>
            </ion-label>
          </ion-item>
        </ion-row>

        <!-- <ion-row>
          <ion-button
            *ngIf="userAccounts && userAccounts.length > 1"
            class="profile-button w-100"
            color="light"
            fill="outline"
            size="small"
            expand="block"
            [routerDirection]="'root'"
            routerLink="/accounts"
          >
            <ion-icon name="person"></ion-icon> Switch Accounts ({{ userInfo$.eeSysId }})
          </ion-button>
        </ion-row> -->
      </div>

      <!-- Menu Items -->
      <div class="side-menu">
        <ion-list class="side-menu__list">
          <!-- Update Available -->
          <div class="side-menu__section--update" *ngIf="updateAvailable">
            <ion-menu-toggle auto-hide="false">
              <ion-item class="side-menu__item cursor-pointer" detail="false" (click)="update()">
                <ion-icon name="alert-outline" slot="start"></ion-icon>
                <ion-label>Update Available</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </div>

          <!-- Home Section -->
          <ng-container *ngIf="this.showMenu.home || this.showMenu.supervisor">
            <div class="side-menu__section">
              <ng-container *ngFor="let page of homeSection">
                <ion-menu-toggle *ngIf="page.display && page.url" auto-hide="false">
                  <ion-item
                    routerDirection="root"
                    [routerLink]="page.url"
                    routerLinkActive="active"
                    routerLinkActiveOptions="{exact: true}"
                    detail="false"
                    class="side-menu__item"
                  >
                    <ion-icon [name]="page.icon" slot="start"></ion-icon>
                    <ion-label>
                      <span>{{ page.title }}</span>
                      <!-- <ion-badge *ngIf="p.numberOfPtoRequest? > 0">{{ p.numberOfPtoRequest }}</ion-badge> -->
                    </ion-label>
                  </ion-item>
                </ion-menu-toggle>
              </ng-container>
            </div>
          </ng-container>

          <!-- Payroll Section -->
          <ng-container *ngIf="this.showMenu.payroll || this.showMenu.taxStatements">
            <div class="side-menu__section">
              <ng-container *ngFor="let page of payrollSection">
                <ion-menu-toggle *ngIf="page.display && page.url" auto-hide="false">
                  <ion-item
                    routerDirection="root"
                    [routerLink]="page.url"
                    routerLinkActive="active"
                    routerLinkActiveOptions="{exact: true}"
                    detail="false"
                    class="side-menu__item"
                  >
                    <ion-icon [name]="page.icon" slot="start"></ion-icon>
                    <ion-label>
                      <span>{{ page.title }}</span>
                      <!-- <ion-badge *ngIf="p.numberOfPtoRequest? > 0">{{ p.numberOfPtoRequest }}</ion-badge> -->
                    </ion-label>
                  </ion-item>
                </ion-menu-toggle>
              </ng-container>
            </div>
          </ng-container>

   <!-- Payroll Expenses -->
  <!-- US3663 on hold for expenses phase 2 -->
   <!-- <ng-container *ngIf="this.showMenu.expenses">
    <div class="side-menu__section">
      <ng-container *ngFor="let page of expensesSection">
        <ion-menu-toggle *ngIf="page.display && page.url" auto-hide="false">
          <ion-item
            routerDirection="root"
            [routerLink]="page.url"
            routerLinkActive="active"
            routerLinkActiveOptions="{exact: true}"
            detail="false"
            class="side-menu__item"
          >
            <ion-icon [name]="page.icon" slot="start"></ion-icon>
            <ion-label>
              <span>{{ page.title }}</span>
              
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ng-container>
    </div>
  </ng-container> -->



          <!-- Time Section -->
          <ng-container *ngIf="this.showMenu.timeoff || this.showMenu.timekeeping || this.showMenu.calendar">
            <div class="side-menu__section">
              <ng-container *ngFor="let page of timeSection">
                <ion-menu-toggle *ngIf="page.display && page.url" auto-hide="false">
                  <ion-item
                    routerDirection="root"
                    [routerLink]="page.url"
                    routerLinkActive="active"
                    routerLinkActiveOptions="{exact: true}"
                    detail="false"
                    class="side-menu__item"
                  >
                    <ion-icon [name]="page.icon" slot="start"></ion-icon>
                    <ion-label>
                      <span>{{ page.title }}</span>
                      <!-- <ion-badge *ngIf="p.numberOfPtoRequest? > 0">{{ p.numberOfPtoRequest }}</ion-badge> -->
                    </ion-label>
                  </ion-item>
                </ion-menu-toggle>
              </ng-container>
            </div>
          </ng-container>

          <!-- Manage Employees Section -->
          <ng-container *ngIf="this.showMenu.management && this.showMenu.timeoff">
            <div class="side-menu__section">
              <ng-container *ngFor="let page of manageEmployeesSection">
                <ion-menu-toggle *ngIf="page.display && page.url" auto-hide="false">
                  <ion-item
                    routerDirection="root"
                    [routerLink]="page.url"
                    routerLinkActive="active"
                    routerLinkActiveOptions="{exact: true}"
                    detail="false"
                    class="side-menu__item"
                  >
                    <ion-icon [name]="page.icon" slot="start"></ion-icon>
                    <ion-label>
                      <span>{{ page.title }}</span>
                      <!-- <ion-badge *ngIf="p.numberOfPtoRequest? > 0">{{ p.numberOfPtoRequest }}</ion-badge> -->
                    </ion-label>
                  </ion-item>
                </ion-menu-toggle>
              </ng-container>
            </div>
          </ng-container>

          <!-- Admin Section -->
          <ng-container *ngIf="this.showMenu.admin">
            <div class="side-menu__section">
              <ng-container *ngFor="let page of adminSection">
                <ion-menu-toggle *ngIf="page.display && page.url" auto-hide="false">
                  <ion-item
                    routerDirection="root"
                    [routerLink]="page.url"
                    routerLinkActive="active"
                    routerLinkActiveOptions="{exact: true}"
                    detail="false"
                    class="side-menu__item"
                  >
                    <ion-icon [name]="page.icon" slot="start"></ion-icon>
                    <ion-label>
                      <span>{{ page.title }}</span>
                      <!-- <ion-badge *ngIf="p.numberOfPtoRequest? > 0">{{ p.numberOfPtoRequest }}</ion-badge> -->
                    </ion-label>
                  </ion-item>
                </ion-menu-toggle>
              </ng-container>
            </div>
          </ng-container>

          <!-- Shifts in Progress -->
          <ng-container *ngIf="this.showMenu.supervisor">
            <div class="side-menu__section">
              <ng-container *ngFor="let page of shiftsInProgress">
                <ion-menu-toggle *ngIf="page.display && page.url" auto-hide="false">
                  <ion-item
                    routerDirection="root"
                    [routerLink]="page.url"
                    routerLinkActive="active"
                    routerLinkActiveOptions="{exact: true}"
                    detail="false"
                    class="side-menu__item"
                  >
                    <ion-icon [name]="page.icon" slot="start"></ion-icon>
                    <ion-label>
                      <span>{{ page.title }}</span>
                      <!-- <ion-badge *ngIf="p.numberOfPtoRequest? > 0">{{ p.numberOfPtoRequest }}</ion-badge> -->
                    </ion-label>
                  </ion-item>
                </ion-menu-toggle>
              </ng-container>
            </div>
          </ng-container>

          <!-- Profile and Settings Section -->
          <div class="side-menu__section">
            <!-- Settings -->
            <ion-menu-toggle auto-hide="false">
              <ion-item
                [routerDirection]="'root'"
                routerLink="/settings"
                routerLinkActive="active"
                class="side-menu__item"
                detail="false"
              >
                <ion-icon name="settings-outline" slot="start"></ion-icon>
                <ion-label>Settings</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <!-- Support -->
            <ion-menu-toggle auto-hide="false">
              <ion-item class="side-menu__item cursor-pointer" detail="false" (click)="contactUs()">
                <ion-icon name="help-circle-outline" slot="start"></ion-icon>
                <ion-label>Support</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <!-- User -->
            <ion-accordion-group>
              <ion-accordion>
                <ion-item slot="header" class="side-menu__item">
                  <ion-icon name="person-circle-outline" slot="start"></ion-icon>
                  <ion-label>
                    {{ supervisor ? supervisor.supervisorName : userInfo$.fullName }}
                  </ion-label>
                </ion-item>
                <ion-list slot="content" class="sub-menu">
                  <ng-container *ngFor="let page of profileSection">
                    <ion-menu-toggle *ngIf="page.display" auto-hide="false">
                      <ion-item
                        routerDirection="root"
                        [routerLink]="page.url"
                        routerLinkActive="active"
                        detail="false"
                        class="side-menu__sub-item"
                      >
                        <!-- <ion-icon [name]="page.icon" slot="start"></ion-icon> -->
                        <ion-label>{{ page.title }}</ion-label>
                      </ion-item>
                    </ion-menu-toggle>
                  </ng-container>

                  <!-- Change Password -->
                  <ion-menu-toggle auto-hide="false">
                    <ion-item detail="false" class="side-menu__sub-item cursor-pointer" (click)="changePassword()">
                      <ion-label>Change Password</ion-label>
                    </ion-item>
                  </ion-menu-toggle>

                  <!-- Logout -->
                  <ion-item class="side-menu__item cursor-pointer" detail="false" (click)="logout()">
                    <ion-label>Logout</ion-label>
                  </ion-item>
                </ion-list>
              </ion-accordion>
            </ion-accordion-group>
          </div>
        </ion-list>
      </div>

      <!-- </div> -->
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="main-content"></ion-router-outlet>
</ion-split-pane>
