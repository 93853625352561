import { AlertController, ModalController } from '@ionic/angular';
import { UserInfoService } from './user-info.service';
import { Injectable } from '@angular/core';
import { Route, UrlSegment, UrlTree, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { EmailComponent } from '@app/@components/email/email.component';

@Injectable({
  providedIn: 'root',
})
export class NavigationGuardService  {
  constructor(
    private userInfoService: UserInfoService,
    private alertController: AlertController,
    private modalController: ModalController
  ) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return true;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (state.url === '/timeoff') {
      if (!this.userInfoService.userInfo.managerId || this.userInfoService.userInfo.managerId === 0) {
        this.alertNoManager();

        return false;
      }
    } else if (state.url === '/admin') {
      if (!this.userInfoService.userInfo.siteAdmin) {
        return false;
      }
    }
    return true;
  }

  async alertNoManager() {
    let buttons = [];

    buttons.push({
      text: 'Ok',
    });

    if (this.userInfoService.userInfo.emailPayroll) {
      buttons.push({
        text: 'Contact Payroll',
        handler: () => {
          this.emailPR();
        },
      });
    }
    const alert = await this.alertController.create({
      header: 'No Manager Assigned',
      message: `You currently do not have a manager assigned to you to approve your time off.  Please contact payroll.`,
      buttons,
    });

    await alert.present();
  }

  async alertStop(page: string) {
    const alert = await this.alertController.create({
      header: 'Access Denied',
      message: `You currently are not set up to access ${page}`,
      buttons: [
        {
          text: 'Ok',
        },
      ],
    });

    await alert.present();
  }

  async emailPR() {
    let to = Array(),
      from = this.userInfoService.userInfo.email,
      subject = 'Timeoff Help',
      contactName = 'Test';

    if (this.userInfoService.userInfo.emailPayroll) {
      to.push({ value: this.userInfoService.userInfo.emailPayroll, display: 'Payroll' });
    }

    const emailModal = await this.modalController.create({
      component: EmailComponent,
      componentProps: {
        to,
        from,
        subject,
      },
    });

    emailModal.onDidDismiss().then((data) => {
      // console.log('email closed')
    });
    await emailModal.present();
  }
}
