<ion-header>
  <ion-toolbar color="{{ !production ? 'danger' : 'primary' }}">
    <ion-buttons slot="start" *ngIf="displayMenuButton">
      <ion-menu-button autoHide="false"></ion-menu-button>
    </ion-buttons>
    <!-- <ion-title size="large" *ngIf="this.pageTitle.length > 0">{{ this.pageTitle }} <span *ngIf="!production">( {{ this.userInfo.fullName }} ) - <strong>Using the TEST DATABASE!</strong></span></ion-title> -->
    <ion-title *ngIf="this.pageTitle.length > 0">
      {{ this.pageTitle }}
      <!-- <div *ngIf="!production"><strong>Using the TEST DATABASE!</strong></div> -->
    </ion-title>
    <ion-title size="small" *ngIf="this.pageDescription.length > 0">{{ this.pageDescription }}</ion-title>

    <ion-buttons slot="end">
      <app-privacy-mode></app-privacy-mode>
      <ion-button size="small" fill="clear" (click)="toggleDarkMode()" aria-label="toggle dark mode">
        <ion-icon *ngIf="prefersDark" name="sunny"></ion-icon>
        <ion-icon *ngIf="!prefersDark" name="moon"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
