// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { buildDateTime } from 'src/buildDate';

export const environment = {
  production: false,
  version: buildDateTime.version + '-dev',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US'],
  baseURL: '', // Ionic local url
  rtsApiServer: 'https://api.realtime.services/',
  // serverUrl: 'https://realpay.us/rts_ewp_dev788/', // fixme, port may be wrong
  serverUrl: 'https://dev1.staffing-help.com/',
  // serverUrl: 'http://10.5.0.46:47289/', // api backend
  // apikey: env.x_api_key, // X-API-KEY
  apikey: '35d7b98b-025c-41cd-a2f7-8644abbae7c5',
  buildDate: new Date().toISOString(),
};
//https://staffing-help.com/irtswp2/#/home
/*
export const environment = {
  production: false,
  version: env.npm_package_version + '-dev',
  //serverUrl: '/api',
  serverUrl: 'http://localhost:47289/', // api backend
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US'],
  rtsApiServer: 'https://api.realtime.services/',
  baseURL: 'http://localhost:8100', // Ionic local url
};*/
