import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { HelperService } from '@app/@services/helper.service';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-privacy-mode',
  templateUrl: './privacy-mode.component.html',
  styleUrls: ['./privacy-mode.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule],
})
export class PrivacyModeComponent implements OnInit {
  @Input() color: string;
  @Input() itemColors: string;
  showNumbers: boolean;
  constructor(public helperService: HelperService) {}

  ngOnInit(): void {
    this.helperService.castShowNumbers.subscribe((numbers) => {
      this.showNumbers = numbers;
    });
  }

  toggleShowNumbers() {
    this.helperService.toggleShowNumbers();
    // console.log('local.showNumbers: ' + this.showNumbers);
  }
}
